import styled from "styled-components";
import { Container as C } from "react-bootstrap";

export const Container = styled(C)`
  padding: 4.5em 2em;
  min-height: 87vh;

  .loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 4.6em;
  }

  & > .row > div:first-child {
    flex: 0 0 auto;
    padding: 0 1.3em;
    position: sticky;
    top: 0;
  }

  & > .row > div:nth-child(2) {
    flex: 0 0 auto;
  }

  @media (max-width: 768px) {
    & > .row {
      flex-direction: column;
      overflow: auto;
    }
    & > .row > div:first-child {
      padding: 0;
    }
    & > .row > div:nth-child(2) {
      flex: 0 0 auto;
      padding: 0;
    }
  }

  .product-container {
    background: #ffffff50;
    padding: 1.5em;
    padding-top: 0;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;

  .dropdown {
    display: none;
  }

  button {
    width: 100%;
  }

  &.typeButtonsContainer {
    margin-bottom: 1em;

    button {
      margin-right: 5px;
      margin-top: 0;
      width: auto;
    }
  }

  .desktopButtons-flex {
    display: flex;
    align-items: center;
  }

  @media (max-width: 768px) {
    .dropdown {
      display: block;
    }

    .desktopButtons {
      display: none;
    }

    &.typeButtonsContainer {
      flex-direction: column !important;

      button {
        margin-right: 0;
        margin-top: 10px;
        width: 100%;
      }
    }
  }

  &.gamehubButtons {
    button {
      background: rgb(47, 0, 54);
    }
  }
`;

export const Button = styled.button`
  position: relative;
  margin-top: 12px;
  background: #00e1cd;
  color: white;
  font-weight: 600;
  font-size: 23px;
  border: none;
  outline: none;
  height: 52px;

  &.active {
    color: #003847;
    background: white !important;
    font-weight: bold;
  }

  span {
    position: absolute;
    right: 10px;
    top: 16px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const SelectWrapper = styled.div`
  position: relative;
  margin-right: 20px;

  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 20px;
    right: 15px;
    width: 10px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    padding: 3px;
    transform: rotate(45deg);
    z-index: 2;
  }
`;

export const Select = styled.select`
  background: #00e1cd;
  color: white;
  font-weight: 600;
  font-size: 23px;
  border: none;
  outline: none;
  height: 52px;
  min-width: 220px;
  max-width: 320px;
  padding: 0 40px 0 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  appearance: none;
  cursor: pointer;
`;

export const ProfileCard = styled.div`
  background: white;
  padding: 1em 0;
  padding-bottom: 0;

  h3 {
    text-align: center;
    color: #002d3c;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  img:nth-child(2) {
    height: 233.7px;
    width: 100%;
  }

  div:nth-child(3) {
    display: flex;

    div {
      position: relative;

      width: 60%;
      background: #00e1cd;
      display: flex;
      align-items: center;
      height: 58px;
      padding: 1em 2em;

      img {
        width: 63px;
        height: 58px;
        position: absolute;
        right: -27px;
      }

      p {
        font-size: 20px;
        margin-bottom: 0;
        font-weight: bold;
        color: white;
      }
    }

    p {
      color: #003847;
      text-align: center;
      flex: 1;
      font-weight: 300;
      line-height: 10px;
      font-size: 20px;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  div:nth-child(4) {
    padding: 1em 1.5em;
    p:first-child {
      font-size: 18px;
    }

    p:nth-child(2) {
      font-size: 12px;
    }

    p:nth-child(3) {
      font-size: 19px;
    }

    p {
      color: #003847;
      font-weight: 300;
    }
  }
`;

export const CollectionName = styled.div`
  display: flex;
  align-items: center;
  font-size: 26px;
  margin-top: 20px;
`;

export const CollectionDescription = styled.p`
  font-size: 18px;
  margin-bottom: 10px;
`;

export const AddNftButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 445px;
  border: 5px dashed rgb(47,0,54);
  color: rgb(47,0,54);
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
`;

export const MigrationHint = styled.div`
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-size: 20px;
`;
