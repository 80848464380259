import CardsMedia from "components/mediaBox";
import {NFTObjectData} from "hooks/useApi";
import {Image} from "react-bootstrap";
import {useHistory} from "react-router";
import {useProfileForWallet} from "store/hooks";
import {getCreatorPrefixByContract, getNftChainByContract} from "utils";

import {Card} from "./styled";

interface IProductCard {
    className?: string;
    disabled?: boolean;
    productObj: NFTObjectData;
    isRecent?: boolean;
    dontShowSold?: boolean;
    isMarketplace?: boolean;
}

const ProductCard = ({
                         className = "",
                         productObj,
                         disabled,
                         isRecent = false,
                         dontShowSold,
                         isMarketplace,
                     }: IProductCard) => {
    let history = useHistory();
    const creator = useProfileForWallet(productObj?.initialCreatorAddress);

    return (
        <Card
            className={className}
            onClick={() => {
                const detailUrl = `${getCreatorPrefixByContract(productObj?.contractAddress)}/product/${getNftChainByContract(productObj?.contractAddress)}/${productObj?.tokenID}`;
                !disabled && history.push(detailUrl);
            }}
        >

            {
                productObj?.listed && (
                    <div className="soldWord">
                        <div className="tag">For Sale</div>
                        {" "}
                    </div>)
            }

            <div style={{height: "500px"}}>
                <CardsMedia
                    controls
                    productId={productObj?._id}
                    assetType={productObj?.assetType}
                    assetUrl={productObj?.cdnAssetUrl}
                    bannerImage={productObj?.cdnBannerImage}
                />
            </div>
            {/* <Card.Img variant="top" src={productObj?.image} /> */}
            <Card.Body>
                <div className="product-creator__avatar-wrapper">
                    {isRecent ? (
                        <div></div>
                    ) : (
                        <Image
                            className="product-creator__avatar"
                            src={
                                creator?.profile?.userAvatarUrl || "/images/default-profile.png"
                            }
                            roundedCircle
                        />
                    )}
                </div>
                <Card.Title>{productObj?.name}</Card.Title>
                <Card.Text>
                    {productObj?.price ? `${productObj?.price} DXG` : ""}
                </Card.Text>
            </Card.Body>
        </Card>
    );
};

export default ProductCard;
