import styled from "styled-components";

export const Section = styled("div")`
  padding: 75px 0px 260px;

  .container {
    position: relative;
  }
  .alert {
    background-image: linear-gradient(268deg, #00323d, #002546);
    color: ${({ theme }) => theme.color[0]};
  }
  .card {
    background: ${({ theme }) => theme.background[1]};
    label {
      color: ${({ theme }) => theme.color[0]};
    }
    button {
      color: ${({ theme }) => theme.color[0]};
      background-color: #59dabc;
      border-radius: 20px;
      opacity: 1;
      border-color: #59dabc;
    }
    select,
    textarea {
      background: ${({ theme }) => theme.background[0]};
      border-color: ${({ theme }) => theme.background[0]};
      color: ${({ theme }) => theme.color[0]};
    }
  }

`;
