import SwiperCore, { EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useHistory, useLocation } from "react-router-dom";
import "./slider.css";
import { getCreatorPrefixByContract, getNftChainByContract } from "utils";
import {Audio, Media, NOMedia, Video} from "../mediaBox/styled";

SwiperCore.use([EffectCoverflow, Pagination]);

const Slider = ({ nfts }) => {
  const history = useHistory();
  const location = useLocation();

  if (!nfts.length) return null;

  return (
    <Swiper
      slidesPerView={1}
      initialSlide={4}
      effect={"coverflow"}
      centeredSlides={true}
      className="swiper-container two"
      coverflowEffect={{
        rotate: 0,
        stretch: 100,
        depth: 200,
        modifier: 1.5,
        slideShadows: false,
      }}
      breakpoints={{
        1024: {
          slidesPerView: 3,
        },
      }}
      navigation={{
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }}
    >
      {nfts?.map((item) => (
        <SwiperSlide
          key={item.tokenID}
          onClick={() =>
            history.push(`${getCreatorPrefixByContract(item?.contractAddress)}/product/${getNftChainByContract(item?.contractAddress)}/${item?.tokenID}`)
          }
        >
            {item.assetType === "Image" ? (
                <img
                    alt=""
                    src={item.cdnAssetUrl}
                    style={{ cursor: "pointer", objectFit: 'cover' }}
                />
            ) : item.assetType === "Video" ? (
                <Video autoPlay={true} muted loop={true}>
                    <source src={item.assetUrl} type="video/webm" />
                </Video>
            )  : (
                <NOMedia>No preview</NOMedia>
            )}
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default Slider;
