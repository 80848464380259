import { useEffect, useState } from "react";
import { baseApiUrl, getContractAddressByChainName } from "utils";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import apiRequest from "utils/apiRequest";
import { returnCDNImage } from "../utils/cdnHelper";

/* eslint-disable camelcase */
export enum ENFTType {
  FIXED_PRICE = 0,
  AUCTION = 1,
  UNLIMITED_AUCTION = 2,
  GAME_NFT = 3,
}

export interface NFTList {
  nftList: NFTObjectData[];
  totalCount: number;
}

export interface Faction {
  basePrice: number;
  _id: string;
  description: string;
}

export interface NFTObjectData {
  _id: string;
  tokenID?: number;
  contractAddressAndTokenId?: string;

  name?: string;
  description?: string;
  assetUrl?: string;
  assetType?: string;
  bannerImage?: string;
  cdnAssetUrl?: string;
  cdnBannerImage?: string;
  royalty?: number;
  nftType?: ENFTType;
  price?: string;
  minBidPrice?: string;
  startTime?: string;
  endTime?: string;

  mintTransactionHash?: string;
  updatedAt?: number;
  createdAt?: number;
  initialCreatorAddress?: string;
  ownerAddress?: string;

  voteCount?: number;
  listed?: boolean;
  attributes?: any;
  category?: string[];
  contractAddress?: string;
  approved?: boolean;
  verified?: boolean;
  featured?: boolean;
  visible?: boolean;
  collectionName?: string;
  likes?: any;
  bids: NFTBid[];

  creator?: any;
  owner?: any;
}

export interface NFTUser {
  walletAddress?: string;
  userId?: string;

  email: string;
  password: string;

  displayName?: string;
  customUrl?: string;
  userBio?: string;
  userAvatarUrl?: string;
  userBackgroupUrl?: string;

  socialPortfolioUrl?: string;
  socialTwitterUrl?: string;
  // socialVoyrUrl?: string;
  socialInstagramUrl?: string;
  socialYoutubeUrl?: string;
  region?: string;

  banned?: boolean;
  verified?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface NFTEvent {
  doneOn?: number;
  eventType?: number;
  nftIDSold?: number;
  transactionHash?: string;

  minter?: string;
  nftType?: string;
  royalty?: string;
  price?: string;
  minBidPrice?: string;
  startTime?: string;
  endTime?: string;

  seller?: string;
  buyer?: string;
  nftSoldAtPrice?: number;

  priceUpdater?: string;
  newNftPrice?: number;
  oldNftPrice?: number;

  auctionStarter?: string;

  statusUpdater?: string;
  isListed?: boolean;

  bidder?: string;
  bidPrice?: number;
}

export interface NFTBid {
  doneOn?: number;
  tokenID?: number;
  transactionHash?: string;
  x;
  bidder?: string;
  price?: number;
  user?: NFTUser;
}

export interface NFTCollection {
  name?: string;
  description?: string;
  user?: NFTUser;
  _id?: string;
}

export interface NFTDetail {
  nft?: NFTObjectData;
  creator?: NFTUser;
  owner?: NFTUser;
  historyEvents?: NFTEvent[];
  resolvedTradingHistory?: any[];
  bids?: NFTBid[];
}

export interface NFTUserFullDetail {
  userProfile?: NFTUser;
  userNfts?: {
    createdNfts?: NFTObjectData[];
    currentNfts?: NFTObjectData[];
    boughtNfts?: NFTObjectData[];
    soldNfts?: NFTObjectData[];
  };
}

export interface NFTTopArtist {
  user?: NFTUser;
  soldAmount?: number;
  createdNFTs?: NFTObjectData[];
}

export interface Game {
  _id: string;
  name: string;
  version: string;
}

export interface GameAttribute {
  user?: NFTUser;
  game: string;
  attributes?: any;
}

export const useFetch = (query, page) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [list, setList] = useState([]);

  return { loading, error, list };
};

export default useFetch;


export const setWalletAddress = async (data, token) => {
  const strUrl = `${baseApiUrl}/v2/users/wallet`;
  await axios.post(strUrl, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const useGetNFTObjectList = ({
  start = 0,
  count = 16,
  category = "",
  sortField = "",
  sortOrder = "",
  nftType = "",
  SearchIs = "",
  showCollections = false,
}) => {
  const [data, setData] = useState<any>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;

        if (showCollections) {
          response = await apiRequest({
            url: `/v2/get-collection-list?sortField=${sortField}&sortOrder=${sortOrder}&search=${SearchIs}`,
            method: "GET",
            data,
          });
        } else {
          response = await apiRequest({
            url: `/v2/search?start=${start}&count=${count}&category=${category}&sortField=${sortField}&sortOrder=${sortOrder}&nftType=${nftType}&search=${SearchIs}`,
            method: "GET",
            data,
          });
        }

        const responseData = response?.data?.data;
        if (responseData) {
          const nftObjectList: any = responseData;
          setData(nftObjectList);
        }
      } catch (error) {
        console.error("Unable to fetch data:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setData,
    start,
    count,
    category,
    sortField,
    sortOrder,
    nftType,
    SearchIs,
    showCollections,
  ]);

  return data;
};

export const GetLeaderboardData = (category) => {
  const [data, setData] = useState<NFTObjectData[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const strUrl = `${baseApiUrl}/leaderboard/${category}`;
        const response = await fetch(strUrl);
        const responseData = await response.json();

        if (responseData.status === "success") {
          let nftDetail: NFTObjectData[] = responseData.nftObjectsList;

          nftDetail = nftDetail.filter(
            (item) =>
              item.attributes.constructor.name === "Object" &&
              item.attributes.EnemiesKilled >= 0
          );
          nftDetail.sort((a, b) => {
            if (a.attributes.EnemiesKilled > b.attributes.EnemiesKilled)
              return -1;
            if (a.attributes.EnemiesKilled < b.attributes.EnemiesKilled)
              return 1;
            return 0;
          });
          setData(nftDetail);
        }
      } catch (error) {
        console.error("Unable to fetch data:", error);
      }
    };

    fetchData();
  }, [setData, category]);

  return data;
};

export const useGetCollections = (user, profile) => {
  const [data, setData] = useState<NFTCollection[]>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await apiRequest({
          url: `/v2/collections/${
            user ? user["https://dexioprotocol.com/userId"] : ""
          }`,
          method: "GET",
        }).then((res) => {
          return res.data.data;
        });

        if (response) {
          const nftDetail: NFTCollection[] = response;
          setData(nftDetail);
        }
      } catch (error) {
        console.error("Unable to fetch data:", error);
      }
    };

    fetchData();
  }, [setData, user, profile]);

  return data;
};

export const createCollection = async (data) => {
  try {
    let response = await apiRequest({
      url: "/v2/collections",
      method: "POST",
      data,
    });

    return response;
  } catch (e) {
    return false;
  }
};

export const likeDislikeNft = async ({ nftId, userId }) => {
  try {
    let response = await apiRequest({
      url: `/v2/like-nft/${userId}/${nftId}`,
      method: "GET",
    });

    const responseData = response.data.data;
    if (responseData) {
      return responseData;
    }
  } catch (error) {
    console.error("Unable to create a collection:", error);

    return false;
  }
};

export const searchByWord = async ({ query, collectionSearch = true }) => {
  try {
    const response = await axios({
      url: `${baseApiUrl}/v2/searchv2`,
      params: {
        isearchprofiles: `{"searchString": "${query}"}`,
        isearchobjects: `{"searchString": "${query}"}`,
        isearchcollections: `{"${collectionSearch ? 'searchString' : 'collectionName'}" : "${query}"}`
      },
      method: "GET",
    });

    const responseData = response.data.data;
    if (responseData) {
      return responseData.search;
    }
  } catch (error) {
    console.error("Unable to create a collection:", error);

    return false;
  }
};

export const searchNfts = async ({
  category,
  minPrice,
  maxPrice,
  level,
  subCategory,
  sortBy,
  availability,
},pageNum) => {
  try {
    let baseString = `/v2/filter?`;
    if (category && category !== "0")
      baseString =
        baseString +
        `isearchcategory={"category": "${category}" ${
          subCategory ? `,"type":"${subCategory}"` : ""
        }}&`;
    else baseString += `isearchcategory={}&`;

    if (minPrice || maxPrice) {
      baseString =
        baseString +
        `isearchprice={"minPrice": ${minPrice ? minPrice : 0},"maxPrice":${
          maxPrice ? maxPrice : 1000000
        }}&`;
    } else baseString = baseString + `isearchprice={}&`;

    if (level) {
      baseString = baseString + `isearchlevel={"level": ${level}}&`;
    } else baseString = baseString + `isearchlevel={}&`;
    if (availability !== "-1") {
      baseString =
        baseString + `isearchavailability={"listed": ${availability}}&`;
    } else baseString = baseString + `isearchavailability={}&`;

    if (sortBy) {
      baseString = baseString + `sortBy=${sortBy.split("|")[0]}&`;
      if (sortBy.includes("desc")) baseString = baseString + `&desc=true&`;
    }

    baseString = baseString + `&pageNum=${pageNum > 0 ? pageNum / 16 : 0}&pageSize=16`;

    let response = await apiRequest({
      url: baseString,
      method: "GET",
    });

    const responseData = response.data.data;
    if (responseData) {
      return {
        results: responseData.objects,
        searchCount: responseData.searchCount,
      };
    }
  } catch (error) {
    console.error("Unable to create a collection:", error);

    return false;
  }
};

export const getLikedNfts = async (userId: string) => {
  try {
    let response = await apiRequest({
      url: `/v2/likes/${userId}`,
      method: "GET",
    });

    const responseData = response.data.data;
    if (responseData) {
      return responseData;
    }
  } catch (error) {
    console.error("Unable to create a collection:", error);

    return false;
  }
};

export const getFactions = async () => {
  try {
    const strUrl = `${baseApiUrl}/image-generator/factions`;
    const response = await fetch(strUrl, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });
    const responseData = await response.json();
    if (responseData.status === "success") {
      return responseData;
    }
  } catch (error) {
    console.error("Unable to generate NFT:", error);

    return false;
  }
};

export const generateNft = async (factionId) => {
  try {
    const strUrl = `${baseApiUrl}/image-generator/image-layers/${factionId}`;
    const response = await fetch(strUrl, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });
    const responseData = await response.json();
    if (responseData.status === "success") {
      return responseData.faction;
    }
  } catch (error) {
    console.error("Unable to create a collection:", error);

    return false;
  }
};

export const useGetNFTObjectDetail = (tokenID, chainName) => {
  const [data, setData] = useState<NFTDetail>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const strUrl = `${baseApiUrl}/nfts/${getContractAddressByChainName(chainName, 'GAMEVAULTNFT')}/${tokenID}`;
        const response = await fetch(strUrl);
        const responseData = await response.json();
        console.log(responseData)
        if (responseData.status === "success") {
          const nftDetail: NFTDetail = responseData.nftDetail;
          nftDetail.nft.cdnAssetUrl = returnCDNImage(
            nftDetail.nft.assetType,
            nftDetail.nft.assetUrl
          );
          nftDetail.nft.cdnBannerImage = returnCDNImage(
            nftDetail.nft.assetType,
            nftDetail.nft.bannerImage
          );
          nftDetail.nft.minBidPrice = nftDetail.nft.minBidPrice
            ? parseInt(nftDetail.nft.minBidPrice).toString()
            : "0";
          setData(nftDetail);
        }
      } catch (error) {
        console.error("Unable to fetch data:", error);
      }
    };

    fetchData();
  }, [setData, tokenID]);

  return data;
};

export const useGetNFTUserList = () => {
  const [data, setData] = useState<NFTUser[]>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const strUrl = `${baseApiUrl}/getNFTUserList`;
        const response = await fetch(strUrl);
        const responseData = await response.json();
        if (responseData.status === "success") {
          const userList: NFTUser[] = responseData.nftUserList;
          setData(userList);
        }
      } catch (error) {
        console.error("Unable to fetch data:", error);
      }
    };

    fetchData();
  }, [setData]);

  return data;
};

export const useGetNFTTopUserList = () => {
  const [data, setData] = useState<NFTTopArtist[]>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const strUrl = `${baseApiUrl}/getNFTTopUserList`;
        const response = await fetch(strUrl);
        const responseData = await response.json();
        if (responseData.status === "success") {
          const nftTopArtists: NFTTopArtist[] = responseData.data;
          setData(nftTopArtists);
        }
      } catch (error) {
        console.error("Unable to fetch data:", error);
      }
    };

    fetchData();
  }, [setData]);

  return data;
};

export const getNftUserDetails = async (user) => {
  const strUrl = `/getNFTUserFullDetail/${user}`;
  let response = await apiRequest({
    method: "GET",
    url: strUrl,
  });

  if (response) {
    return response.data.data;
  }
};

export const getNftUserDetailsByCustomUrl = async (customUrl) => {

  const strUrl = `${baseApiUrl}/v2/custom_url?isearchusers={ "custom_url": "${customUrl}"}`;
  let response = await apiRequest({
    method: "GET",
    url: strUrl,
  });

  if (response) {
    return response.data.data.search;
  }
};

export const useGetNFTUserProfile = () => {
  const [data, setData] = useState<NFTUser>(null);
  const { getAccessTokenSilently, isAuthenticated, isLoading, user } =
    useAuth0();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isAuthenticated) {
          const token = await getAccessTokenSilently();
          const strUrl = `${baseApiUrl}/v2/users/profile/${user["https://dexioprotocol.com/userId"]}`;

          const response = await axios({
            method: "get",
            url: strUrl,
            headers: { Authorization: `Bearer ${token}` },
          });

          if (response.status === 200) {
            const nftUser = response.data.data;
            setData(nftUser);
          }
        }
      } catch (error) {
        console.error("Unable to fetch data:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setData, isLoading]);

  return data;
};

export const GetMMRLeaderboardData = (game) => {
  const [data, setData] = useState<GameAttribute[]>([]);
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (isAuthenticated) {
          const token = await getAccessTokenSilently();
          const strUrl = `${baseApiUrl}/v2/game-attributes-game/6238849ffffcdebf2f62e1f6`; //hard coded until we make games dynamic
          const response = await axios({
            method: "get",
            url: strUrl,
            headers: { Authorization: `Bearer ${token}` },
          });

          if (response.status === 200) {
            let gameAttribute: GameAttribute[] = response.data.data;
            gameAttribute.sort((a, b) => {
              if (a.attributes.mmr > b.attributes.mmr) return -1;
              if (a.attributes.mmr < b.attributes.mmr) return 1;
              return 0;
            });
            setData(gameAttribute);
          }
        }
      } catch (error) {
        console.error("Unable to fetch data:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setData, game]);

  return data;
};
