import {useState, useEffect} from "react";
import Layout from "components/layout";
import {Container, Button, ButtonsContainer} from "./index.styled";
import {Row, Col} from "react-bootstrap";
import NewProductCard from "components/card/newProductCard";
import {useHistory, useLocation} from "react-router-dom";
import {getMPNftUserDetailsByCustomUrl} from "marketplace/hooks/useApi";
import {getNftUserDetailsByCustomUrl} from "hooks/useApi";
import {Dropdown, Menu} from "antd";
import {DownOutlined} from "@ant-design/icons";
import {returnCDNImage} from "utils/cdnHelper";
import {CONTRACTS_BY_NETWORK, currentNetwork, Networks} from "../../utils";
import { H4, H5 } from "components/typography";
import APIFactory from "utils/api";
import config from "environment";
import { Collection } from "store/types";

const isProduction = Number(currentNetwork) === 137;

let STATUS_TYPES = ["Created", "Sold", "In-Market"];
const CONTRACTS = [
    {
        name: 'CreatorHub Polygon',
        contract: CONTRACTS_BY_NETWORK[Number(currentNetwork)].CREATORHUBNFT.address,

    },
    /*  {
        name: 'CreatorHub BSC',
        contract: isProduction ? CONTRACTS_BY_NETWORK[Networks.MainNet].DEXINFT.address : CONTRACTS_BY_NETWORK[Networks.Testnet].DEXINFT.address,
      }*/
    {
        name: 'GameVault Polygon',
        contract: CONTRACTS_BY_NETWORK[Number(currentNetwork)].GAMEVAULTNFT.address,
    },
    /*  {
        name: 'GameVault BSC',
        contract: isProduction ? CONTRACTS_BY_NETWORK[Networks.MainNet].DXGNFT.address : CONTRACTS_BY_NETWORK[Networks.Testnet].DXGNFT.address,
      },
      {
        name: 'GameVault BSC Old',
        contract: isProduction ? CONTRACTS_BY_NETWORK[Networks.MainNet].DXGOLD.address : CONTRACTS_BY_NETWORK[Networks.Testnet].DXGOLD.address,
      },*/
];

export interface NFTDetails {
    contractAddress: string;
    contractName: string;
    boughtNfts: any[];
    createdNfts: any[];
    currentNfts: any[];
    soldNfts: any[];
    visible: boolean;
}

export default function Index() {
    const location = useLocation();
    const history = useHistory();
    const [selectedCard, setSelectedCard] = useState("Created");
    const [user, setUser] = useState<any>();
    const [collections, setCollections] = useState<Collection[]>([]);
    const [selectedContractNFTs, setSelectedContractNFTs] = useState(CONTRACTS[0]);
    const userName = location.pathname.split("/").pop();
    const isMarket = location.pathname.includes("creator") ? true : false;
    const [NFTsByContract, setNFTsByContract] = useState<NFTDetails[]>([]);

    useEffect(() => {
        if (NFTsByContract.length > 0) {
            const selectedContract = NFTsByContract.find(x => x.contractAddress.toLowerCase() === selectedContractNFTs.contract.toLowerCase())

            if (selectedContract['createdNfts'].length > 0) {
                setSelectedCard("Created")
                return;
            }
            if (selectedContract['currentNfts'].length > 0) {
                setSelectedCard("In-Market")
                return;
            }
            if (selectedContract['soldNfts'].length > 0) {
                setSelectedCard("Sold")
                return;
            }
        }
    }, [selectedContractNFTs, NFTsByContract])

    useEffect(() => {
        const fetchData = async () => {
            let NFTDetailsBuckets: NFTDetails[] = [];

            for (let contract of CONTRACTS) {
                NFTDetailsBuckets.push({
                    boughtNfts: [],
                    createdNfts: [],
                    currentNfts: [],
                    soldNfts: [],
                    contractAddress: contract.contract,
                    contractName: contract.name,
                    visible: false
                });
            }

            const user = await getNftUserDetailsByCustomUrl(userName);
            const userProfile = user.profile[0];

            if (userProfile) {
                setUser(userProfile);

                const profileCollectionsResponse: any = await APIFactory(
                    config.REACT_APP_API_MARKETPLACE
                ).get(`/collections/${userProfile.walletAddress}`);
                setCollections(profileCollectionsResponse.data);
            }

            for (let event of Object.keys(user.userNfts)) {
                for (let nft of user.userNfts[event]) {
                    if (nft !== null) {
                        try {
                            NFTDetailsBuckets.find(
                                (x) =>
                                    x.contractAddress.toLowerCase() ===
                                    nft.contractAddress.toLowerCase()
                            )[event].push(nft);
                        } catch (e) {}
                    }
                }
            }
            let mpProfile = await getMPNftUserDetailsByCustomUrl(userName);

            for (let event of Object.keys(mpProfile.userNfts)) {
                for (let nft of mpProfile.userNfts[event]) {
                    if (nft !== null) {
                        try {
                            NFTDetailsBuckets.find(
                                (x) =>
                                    x.contractAddress.toLowerCase() ===
                                    nft.contractAddress.toLowerCase()
                            )[event].push(nft);
                        } catch (e) {}
                    }
                }
            }
            for (let bucket of NFTDetailsBuckets) {
                bucket.visible = bucket['createdNfts'].length > 0 || bucket['currentNfts'].length > 0 || bucket['soldNfts'].length > 0
            }
            setNFTsByContract(NFTDetailsBuckets);
            let firstContract = NFTDetailsBuckets.filter(x => x.visible)[0]

            if (firstContract) {
                setSelectedContractNFTs({
                    contract: firstContract.contractAddress.toLowerCase(),
                    name: firstContract.contractName
                });
            }
        };
        try {
            fetchData().then(() => {
            });
        } catch (e) {
            console.log(e);
        }
    }, [userName]);

    const [filteredNFTs, setFilteredNFTs] = useState<NFTDetails[]>([]);
    const [filteredNFTsCount, setFilteredNFTsCount] = useState<number[]>([]);

    useEffect(() => {
        let key = "";
        switch (selectedCard) {
            case "Created":
                key = "createdNfts";
                break;
            case "Sold":
                key = "soldNfts";
                break;
            case "In-Market":
                key = "currentNfts";
                break;
        }
        try {
            if (NFTsByContract.length > 0) {
                let filteredBySelectedContract = NFTsByContract.find((nft) => {
                    return (
                        nft.contractAddress.toLowerCase() ===
                        selectedContractNFTs.contract.toLowerCase()
                    );
                });
                setFilteredNFTsCount([
                    filteredBySelectedContract?.createdNfts?.length || 0,
                    filteredBySelectedContract?.soldNfts?.length || 0,
                    filteredBySelectedContract?.currentNfts?.length || 0,
                ]);
                filteredBySelectedContract[key] &&
                filteredBySelectedContract[key].length > 0 &&
                filteredBySelectedContract[key].map((nft) => {
                    nft.cdnAssetUrl = returnCDNImage(nft.assetType, nft.assetUrl);
                    nft.cdnBannerImage = returnCDNImage(nft.assetType, nft.bannerImage);
                    return nft;
                });
                setFilteredNFTs(filteredBySelectedContract[key]);
            }
        } catch (e) {
            console.log(e);
        }
    }, [NFTsByContract, selectedCard, selectedContractNFTs]);

    const menu = (
        <Menu
            items={STATUS_TYPES.map((item) => {
                return {
                    label: (
                        <Button
                            onClick={() => setSelectedCard(item)}
                            className={selectedCard === item ? "active" : ""}
                        >
                            {item}
                        </Button>
                    ),
                    key: "0",
                };
            })}
        />
    );

    const menuSecond = (
        <Menu
            items={NFTsByContract.filter(x => x.visible).map((item: NFTDetails) => {
                return {
                    label: (
                        <Button
                            onClick={() => {
                                setSelectedContractNFTs({
                                    contract: item.contractAddress.toLowerCase(),
                                    name: item.contractName
                                });
                            }}
                            className={
                                selectedContractNFTs.contract === item.contractAddress ? "active" : ""
                            }
                        >
                            {item.contractName}
                        </Button>
                    ),
                    key: "0",
                };
            })}
        />
    );

    return (
        <Layout>
            <Container fluid>
                <Row>
                    <Col md={2}>
                        <ButtonsContainer
                            className={`${isMarket ? "" : "gamehubButtons"}`}
                        >
                            <div className="dropdown">
                                <Dropdown overlay={menu} trigger={["click"]}>
                                    <Button className={"active"}>
                                        {selectedCard}
                                        <DownOutlined/>
                                    </Button>
                                </Dropdown>
                            </div>

                            <div className="desktopButtons">
                                {STATUS_TYPES.map((item, ix) => (
                                    <Button
                                        onClick={() => setSelectedCard(item)}
                                        className={selectedCard === item ? "active" : ""}
                                    >
                                        {item} ({filteredNFTsCount[ix]})
                                    </Button>
                                ))}
                            </div>
                        </ButtonsContainer>
                    </Col>
                    <Col md={collections?.length ? 8 : 10}>
                        {user && (
                            <>
                                <H4>{user.displayName}</H4>
                                <H5>{user.userBio}</H5>
                            </>
                        )}
                        <ButtonsContainer
                            className={`${
                                isMarket ? "" : "gamehubButtons"
                            } typeButtonsContainer`}
                        >
                            <div className="dropdown">
                                <Dropdown overlay={menuSecond} trigger={["click"]}>
                                    <Button className={"active"}>
                                        {selectedContractNFTs.name}
                                        <DownOutlined/>
                                    </Button>
                                </Dropdown>
                            </div>

                            <div className="desktopButtons">
                                {NFTsByContract.filter((x) => x.visible).map(
                                    (item: NFTDetails) => {
                                        return (
                                            <Button
                                                onClick={() => {
                                                    setSelectedContractNFTs({
                                                        contract: item.contractAddress.toLowerCase(),
                                                        name: item.contractName,
                                                    });
                                                }}
                                                className={
                                                    selectedContractNFTs.contract.toLowerCase() ===
                                                    item.contractAddress.toLowerCase()
                                                        ? "active"
                                                        : ""
                                                }
                                            >
                                                {item.contractName}
                                            </Button>
                                        );
                                    }
                                )}
                                {/*{CONTRACTS.map((item) => (
                                    <Button
                                        onClick={() => {
                                            setSelectedContractNFTs(item);
                                        }}
                                        className={
                                            selectedContractNFTs.contract == item.contract
                                                ? "active"
                                                : ""
                                        }
                                    >
                                        {item.name}
                                    </Button>
                                ))}*/}
                            </div>
                        </ButtonsContainer>
                        <Row>
                            {filteredNFTs.map((product: any, index: number) => (
                                <Col md={12} lg={6} xl={6} xxl={3} key={index}>
                                    <NewProductCard
                                        productObj={product}
                                        isProfile={true}
                                        key={index}
                                    />
                                </Col>
                            ))}
                        </Row>
                    </Col>
                    {collections?.length && (
                        <Col md={2}>
                            <h4>Collections</h4>
                            {collections.map(collection => (
                                <Button
                                    key={collection._id}
                                    onClick={() => history.push(`${isMarket && '/creator'}/collection/${collection.collectionSlug}`)}
                                    style={{ display: "block", width: "100%" }}
                                >
                                    {collection.collectionName}
                                </Button>
                            ))}
                        </Col>
                    )}
                </Row>
            </Container>
        </Layout>
    );
}
