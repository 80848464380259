import styled from "styled-components";

export const CreatorHub = styled.div`
  input {
    width: 482px;
    height: 49px;
    margin: 2rem 0rem 2rem 252px;
  }

  @media (max-width: 700px) {
    input {
      width: 80%;
      margin: 2em auto;
      display: block;
    }
  }
`;

export const Carousel = styled.div`
  height: 400px;
  height: 430px;
  margin-top: 50px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    display: block;
    top: 30px;
    left: 0;
    right: 0;
    height: 3px;
    width: 100%;
    margin: auto;
    transition: 300ms;
    background-image: linear-gradient(
      45deg,
      transparent,
      #ffffff,
      #ffffff,
      transparent
    );
  }

  &::after {
    content: "";
    position: absolute;
    display: block;
    bottom: 90px;
    left: 0;
    right: 0;
    height: 3px;
    width: 100%;
    margin: auto;
    transition: 300ms;
    background-image: linear-gradient(
      45deg,
      transparent,
      #ffffff,
      #ffffff,
      transparent
    );
  }
`;

export const Pera = styled.p`
  text-align: center;
  font-size: 18px;
`;

export const Heading = styled.h4`
  text-align: center;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    display: block;
    bottom: -15px;
    left: 0;
    right: 0;
    height: 3px;
    width: 100%;
    margin: auto;
    transition: 300ms;
    background-image: linear-gradient(
      45deg,
      transparent,
      #ffffff,
      #ffffff,
      transparent
    );
  }
`;
