import { ProfileCard} from "./index.styled";
import { truncateWalletString } from "utils";
import moment from "moment";
import { EditOutlined } from "@ant-design/icons";
export default function ProfileDetails({ profile, setShowEdit }) {
  return (
    <ProfileCard>
      <h3>PROFILE PAGE</h3>
      <img
        src={profile?.userAvatarUrl || "/images/default-profile.png"}
        alt=""
        style={{
          objectFit: profile?.userAvatarUrl ? "cover" : "scale-down",
        }}
      />
      <div>
        <div>
          <p>
            User : {profile?.displayName ? profile?.displayName : "Not set"}
          </p>
          <img src="/images/roundNftIcon.png" alt="" />
        </div>
        <p>Rank: 10</p>
      </div>
      <div>
        <p className="mb-0">Wallet address</p>
        <p className="mb-3">{truncateWalletString(profile?.walletAddress)}</p>

        <p className="mb-0">
          On Marketplace from : {moment(profile?.createdAt).format("MM.YYYY")}
        </p>

        <p
          className="mb-0"
          style={{ cursor: "pointer", fontWeight: "bold", fontSize: "1.3em" }}
          onClick={() => setShowEdit(true)}
        >
          Edit Profile <EditOutlined />
        </p>
      </div>
    </ProfileCard>
  );
}
