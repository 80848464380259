import { Form } from 'react-bootstrap';
import { InputWrapper, TextAreaStyled, InputGroupStyled } from './index.styled';
import { useState } from 'react';
import { DeepMap, FieldError, FieldValues, UseFormRegisterReturn } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

interface PropsType {
  className?: string;
  label?: string;
  helpText?: any;
  placeholder?: string;
  value?: string;
  register?: UseFormRegisterReturn;
  errors?: DeepMap<FieldValues, FieldError>;
  onChange?: (data: any) => void;
  resize?: boolean;
}

const TextArea = ({
  className = '',
  label,
  helpText,
  placeholder = '',
  value = '',
  resize = true,
  register,
  errors,
  onChange,
}: PropsType) => {
  const [inputValue, setInputValue] = useState(value);

  const onChangeHandler = event => {
    const value = event.target.value;
    setInputValue(value);
    if (onChange) onChange(value);
  };

  const name = register.name;

  return (
    <InputGroupStyled className={className}>
      {label && (
        <Form.Label>
          {label}
          {/* {required && <FontAwesomeIcon icon={faStar} />} */}
        </Form.Label>
      )}

      <InputWrapper className={errors && errors[name] && 'is-invalid'}>
        <TextAreaStyled
          className={errors && errors[name] && 'is-invalid'}
          placeholder={placeholder}
          resize={resize}
          defaultValue={inputValue}
          onChange={onChangeHandler}
          {...register}
          rows={5}
        />
        {register && errors && (
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <Form.Control.Feedback className="feedback" type="invalid">
                {message}
              </Form.Control.Feedback>
            )}
          />
        )}
      </InputWrapper>
      {helpText && <Form.Text muted>{helpText}</Form.Text>}
    </InputGroupStyled>
  );
};

export default TextArea;
