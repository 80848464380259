import styled from "styled-components";

export const TopHeader = styled.div`
  background-color: #00000060;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;

  @media (max-width: 860px) {
    height: 150px;
    background-color: transparent;
  }

  img {
    max-width: 100%;
    height: auto;
  }
`;

export const Background = styled.img`
  position: absolute;
  z-index: -1;
  min-height: 100%;
  width: 100%;
  object-fit: cover;
`;

export const HomeContent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: calc(100vh - 80px);
  overflow: hidden;
  font-size: 38px;
  font-family: "Oxanium";
`;

export const NftSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 110px;
  position: relative;
  margin: 3rem auto;

  @media (max-width: 860px) {
    margin: 1rem auto 3rem;
  }

  @media (max-width: 860px) {
    flex-direction: column;
  }

  h1 {
    color: #ffffff;
    font-size: 38px;
    margin: 0 0.5rem;

    @media (max-width: 860px) {
      font-size: 28px;
    }
  }
`;

export const CreatorHub = styled.div`
  position: relative;
  margin-left: 50px;
  z-index: 2;
  
  ::after {
    content: '';
    position: absolute;
    height: 80%;
    top: 10%;
    left: 100px;
    width: 100%;
    background: linear-gradient(90deg, #004B26 0%, transparent 100%);
    z-index: -1;
  }

  ::before {
    content: '';
    position: absolute;
    height: 100%;
    top: 0;
    left: 100px;
    width: 100%;
    background: linear-gradient(90deg, #016D45 0%, transparent 100%);
    z-index: -1;
  }

  a {
    display: flex;
    align-items: center;
  }

  h1 {
    margin-left: 25px;
  }

  @media (max-width: 860px) {
    align-self: flex-start;

    &::after {
      left: 70px;
    }
  }

  img {
    cursor: pointer;
    border-radius: 50%;
    max-width: 215px;

    @media (max-width: 860px) {
      max-width: 170px;
    }
  }
`;

export const NftItem = styled.div`
  background-color: #ffffff14;
  box-shadow: 0 0px 30px 15px #ffffff14;

  img {
    height: 300px;
  }

  @media (max-width: 1130px) {
    display: none;
  }
`;

export const GameVault = styled.div`
  position: relative;
  text-align: right;
  margin-right: 50px;
  z-index: 2;

  &::after {
    content: '';
    position: absolute;
    height: 80%;
    top: 10%;
    right: 100px;
    width: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(204, 239, 252, 0.2) 50%, rgba(124, 18, 177, 1) 100%);
    z-index: -1;
  }

  &::before {
    content: '';
    position: absolute;
    height: 100%;
    top: 0;
    right: 100px;
    width: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(204, 239, 252, 0.2) 50%, rgba(124, 18, 177, 1) 100%);
    z-index: -1;
  }

  a {
    display: flex;
    align-items: center;
  }

  h1 {
    margin-right: 25px;
  }

  @media (max-width: 860px) {
    align-self: flex-end;
    margin-top: 30px;

    &::after {
      right: 70px;
    }
  }

  img {
    cursor: pointer;
    border-radius: 50%;
    max-width: 215px;

    @media (max-width: 860px) {
      max-width: 170px;
    }
  }
`;

export const CardSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem 3rem;
  margin: 1rem 0 3.95rem 0;
  background: linear-gradient(
    to left,
    #ffffff99,
    #ffffff99,
    #ffffff99,
    transparent,
    #ffffff99,
    #ffffff99,
    #ffffff99
  );

  @media (max-width: 860px) {
    flex-direction: column;
    margin: 1rem 0 0 0;
    background: none;
    background: linear-gradient(180deg, rgba(2,0,36,0) 0%, rgba(0,0,0,0.7) 30%);
  }

  @media (min-width: 1700px) {
    padding: 1rem 10rem;
  }

  h5 {
    margin-top: 10px;
    font-size: 24px;
    font-weight: 700;
    color: #205373;

    @media (max-width: 860px) {
      color: #FFFFFF;
    }
  }

  p {
    margin: 25px 0;
    font-size: 20px;
    color: #205373;

    @media (max-width: 860px) {
      color: #FFFFFF;
    }
  }
`;

export const CreatorHubCard = styled.div`
  max-width: 600px;
  padding-right: 100px;

  @media (max-width: 860px) {
    max-width: 100%;
    padding-right: 0;
  }
`;

export const GameVaultCard = styled.div`
  max-width: 600px;
  padding-left: 40px;
  text-align: right;

  @media (max-width: 860px) {
    text-align: left;
    max-width: 100%;
    padding-left: 0;
  }
`;
