import styled from "styled-components";

interface Props {
  varient?: "primary";
  disabled?: boolean;
}
interface BtnPropsType extends Props {
  className?: string;
  children: any;
  onClick?: () => void;
  varient?: "primary";
  disabled?: boolean;
}

export const Btn = styled.button<Props>`
  display: inline-block;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  user-select: none;
  transition: 0.25s ease-in-out;
  height: fit-content;
  width: fit-content;
  font-size: 20px;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  border-radius: 20px;
  padding: 2px 30px 5px;
  border: none;
  color: ${({ theme }) => theme.color[0]};
  background-color: ${({ theme, varient = "primary" }) =>
    varient === "primary"
      ? theme.primary[0]
      : varient === "outline"
      ? "transparent"
      : theme.background[1]};
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px,
    rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;

  position: relative;
  cursor: ${({ disabled = false }) => (disabled ? "default" : "pointer")};
  &:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    border-radius: 100%;
    background: #183b56;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 0.3;
  }

  &:hover {
    color: ${({ theme }) => theme.color[2]};
    background-color: ${({ theme }) => theme.primary[1]};
    text-decoration: none;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
      rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  }

  &:focus {
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
      rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  }

  &:active {
    box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px,
      rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
  }
`;

const Button = ({
  className = "",
  children,
  onClick,
  ...props
}: BtnPropsType) => {
  return (
    <Btn
      className={`btn ${className}`}
      onClick={onClick && onClick}
      type="submit"
      {...props}
    >
      {children}
    </Btn>
  );
};

export default Button;
