import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useAuth0 } from "@auth0/auth0-react";
import toast from "react-hot-toast";
import { useWeb3React } from "@web3-react/core";
import {useDispatch} from "react-redux";

import Button from "components/button";
import LinkButton from "components/button/link";
import Input from "components/form/input";

import { useState } from "react";
import TextArea from "components/form/textArea";
import { fetchProfileCollections } from "store/profile";
import config from "../../../environment";
import APIFactory from "utils/api";
import Select from "components/form/select";
import { useMinter } from "hooks";

type CreateCollectionModalProps = {
  onClose: () => void;
  show: boolean;
};

export const CreateCollectionModal = ({ onClose, show }: CreateCollectionModalProps) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    reValidateMode: "onSubmit"
  });
  const { user, getAccessTokenSilently } = useAuth0();
  const { account } = useWeb3React();
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("CreatorHub");
  const dispatch = useDispatch();
  const isMinter = useMinter();

  const postPath="/collections"

  const onSubmit = async (data) => {
    if (data.name) {
      setLoading(true);
      const token = await getAccessTokenSilently();
      const userId = user["https://dexioprotocol.com/userId"];
      APIFactory(type === 'CreatorHub' ? config.REACT_APP_API_MARKETPLACE : config.REACT_APP_API_URL).post(postPath, {
        userId,
        walletAddress: account,
        collectionName: data.name,
        description: data.description
      }, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then(() => {
          toast.success("Collection created!");
          reset();
          onClose();
          dispatch(fetchProfileCollections(userId, token));
        })
        .catch(() => {
          toast.error("Error was occured. Please, try again later!");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <Modal show={show} onHide={onClose} centered backdrop="static">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Modal.Title className="mb-4">Create new collection</Modal.Title>
          <Select
            defaultValue="CreatorHub"
            placeholder="Collection type"
            onChange={value => setType(value)}
          >
            <option value="CreatorHub">
              CreatorHub
            </option>
            {isMinter && (
              <option value="GameVault">
                GameVault
              </option>
            )}
          </Select>
          <Input
            placeholder="Collection name"
            register={{
              ...register("name", {
                required: { message: "Collection name is required ", value: true },
              }),
            }}
            errors={errors}
          />
          <TextArea
            placeholder="Collection description"
            resize={false}
            register={{
              ...register("description", {
                required: { message: "Collection description is required ", value: true },
              }),
            }}
            errors={errors}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={loading}>Create</Button>
          <LinkButton onClick={onClose}>Close</LinkButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
