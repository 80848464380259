import React, { useState } from "react";
import { DetailsForm, Heading, ImageUpload } from "./index.styled";
import { Button, Form, Input, Select, message,Checkbox } from "antd";
import { useProfile } from "store/hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { profileUpdate } from "store/profile";
import { useDispatch } from "react-redux";
import { getIpfsHash, readFileAsync } from "utils/ipfs";
import APIFactory from "utils/api";
import config from "../../../environment";
const { TextArea } = Input;


const API_URL = config.REACT_APP_API_URL;
export const REGIONS_DATA = [
  "Asia",
  "Australia",
  "Canada, East",
  "Chinese Mainland",
  "Europe",
  "India",
  "Japan",
  "Russia",
  "Russia, East",
  "South Africa",
  "South America",
  "Turkey",
  "USA, East",
  "USA, West",
];


const ProfileDetails = ({ setShowEdit }) => {
  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
  const [loading, setLoading] = useState<boolean>(false);
  const [avatarImage, setAvatar] = useState(null);
  const dispatch = useDispatch();
  const onFinish = (values) => {
    onSubmit(values);
  };

  const { profile } = useProfile();

  const onSubmit = async (updatedData) => {
    if (!profile) {
      message.error("Profile Update Failed!");
      return;
    }

    if (!isAuthenticated) {
      message.error("Please Login!");
      return;
    }

    try {
      setLoading(true);
      message.info("Please wait...");

      let user_avatar_url = profile.userAvatarUrl;

      if (avatarImage) {
        const buffer = await readFileAsync(avatarImage);
        const hash = await getIpfsHash(buffer);
        user_avatar_url = `https://ipfs.io/ipfs/${hash}`;
      }

      updatedData.userId = user["https://dexioprotocol.com/userId"];
      updatedData.walletAddress = profile.walletAddress;
      updatedData.userAvatarUrl = user_avatar_url;

      const token = await getAccessTokenSilently();

      APIFactory(API_URL).put("/v2/users/profile", updatedData, {
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((response) => {
          message.success("Your Profile Updated!");
          dispatch(profileUpdate((response as any).data));
          setShowEdit(false);
        })
        .catch(() => {
          message.error("Profile Update Failed!");
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      message.error("Profile Update Failed!");
    }
  };  
  return (
    <DetailsForm>
      <Heading>PROFILE DETAILS</Heading>
      <Form
        layout="vertical"
        name="nest-messages"
        onFinish={onFinish}
        validateMessages={validateMessages}
        initialValues={{
          displayName: profile?.displayName,
          email: profile?.email,
          customUrl: profile?.customUrl,
          region: profile?.region,
          userBio: profile?.userBio,
          socialPortfolioUrl: profile?.socialPortfolioUrl,
          socialTwitterUrl: profile?.socialTwitterUrl,
          socialInstagramUrl: profile?.socialInstagramUrl,
          socialYoutubeUrl: profile?.socialYoutubeUrl,
          over18: profile?.over18,
        }}
      >
        <ImageUpload
          dispalyImage
          accept="Image"
          defaultImage={{
            assetType: "Image",
            assetUrl: avatarImage
              ? URL.createObjectURL(avatarImage)
              : profile?.userAvatarUrl
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.files && e.target.files[0]) {
              setAvatar(e.target.files[0]);
            }
          }}
        />
        <Form.Item
          name={"displayName"}
          label="Display Name"
          className="mt-3"
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>
        <Form.Item name={"email"} label="Email" rules={[{ type: "email" }]}>
          <Input />
        </Form.Item>
        <Form.Item name={"region"} label="Region">
          <Select defaultValue={profile.region} >
            {REGIONS_DATA.map((region, index) => (
              <option key={index} value={region}>
                {region}
              </option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name={"customUrl"} label="Custom URL">
          <Input />
        </Form.Item>

        <Form.Item name={"userBio"} label="Bio" rules={[{ required: true }]}>
          <TextArea />
        </Form.Item>

        <Form.Item style={{ marginTop: "20px" }}>
          <h5>Social</h5>
        </Form.Item>
        <Form.Item name={"socialPortfolioUrl"} label="Portfolio or website">
          <Input />
        </Form.Item>
        <Form.Item name={"socialTwitterUrl"} label="Twitter">
          <Input />
        </Form.Item>
        <Form.Item name={"socialInstagramUrl"} label="Instagram">
          <Input />
        </Form.Item>
        <Form.Item name={"socialYoutubeUrl"} label="Youtube">
          <Input />
        </Form.Item>
        <Form.Item name={"over18"} valuePropName="checked">
          <div className="d-flex">
            <Checkbox defaultChecked={profile?.over18} />{" "}
            <p style={{ color: "white", marginLeft: "15px" }}>
              I'm over 18 years old
            </p>
          </div>
        </Form.Item>
        <Form.Item className="d-flex justify-content-center">
          <div className="d-flex justify-content-center">
            <Button className="saveBtn" htmlType="submit" loading={loading}>
              Save
            </Button>
            <Button className="cancelBtn" onClick={() => setShowEdit(false)}>
              Cancel
            </Button>
          </div>
        </Form.Item>
      </Form>
    </DetailsForm>
  );
};

export default ProfileDetails;
