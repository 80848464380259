import { ENFTType } from "hooks";
import { NftName } from "pages/creatorhub/subpages/detail/index.styled";

export default function Index({
  nftDetails,
  isOwnsProduct,
  loading,
  setShowFixedPrice,
  setShowAuction
}) {
  const isCreatorHub = window.location.pathname.includes("creator");

  return (
    <NftName className={isCreatorHub ? "" : "gamehub"}>
      <h1>{nftDetails?.nft?.name}</h1>
      <p>{nftDetails?.nft?.description}</p>

      {isOwnsProduct && (
        <div className="flex-box actions">
          <button
            className="buyBtn"
            style={{ marginLeft: "0.7em" }}
            onClick={() => setShowAuction(true)}
            disabled={loading}
          >
            Create auction
          </button>
          <button
            className="buyBtn"
            style={{ marginLeft: "0.7em" }}
            onClick={() => setShowFixedPrice(true)}
            disabled={loading}
          >
            Sell
          </button>
        </div>
      )}
      <>
        <div className="purchase">
          <h3>Purchase Price:</h3>
          <button className="dexiBtn">
            {`${
              nftDetails?.nft?.nftType === ENFTType.FIXED_PRICE
                ? nftDetails?.nft?.price
                : nftDetails?.nft?.bids?.length > 0
                ? parseInt(
                    nftDetails?.nft?.bids[
                      nftDetails?.nft?.bids.length - 1
                    ].price.toString()
                  )
                : parseInt(nftDetails?.nft?.minBidPrice)
            } `}{" "}
            DEXI
          </button>
        </div>
      </>
    </NftName>
  );
}
