import { useState } from "react";
import { Image, Modal } from "react-bootstrap";
import { WalletElements } from "./index.styled";

export default function Connect({ onClose, onWalletSelect }) {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  const Wallets = [
    { icon: "/images/metamask.png", name: "Metamask" },
    { icon: "/images/wallet-connect.png", name: "WalletConnect" },
  ];

  const selectWallet = (walletName) => {
    setShow(false);
    onWalletSelect(walletName);
    onClose();
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body style={{ backgroundColor: "#14141f" }}>
        <Modal.Title style={{ color: "white" }}>
          Connect Wallet
        </Modal.Title>
        <WalletElements className="wallets">
          {Wallets.map((wallet) => (
            <span
              key={wallet.name}
              onClick={() => selectWallet(wallet.name)}
              style={{ color: "white" }}
            >
              <Image src={wallet.icon} />
              {wallet.name}
            </span>
          ))}
        </WalletElements>
      </Modal.Body>
    </Modal>
  );
}
