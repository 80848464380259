import styled from "styled-components";

export const Actions = styled.div`  
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;

  button {
    margin: 0 10px;
  }
`;
