import styled from "styled-components";

export const SideBarMenu = styled.div`
  background-color: transparent;
  color: #4e4e4e !important;
  width: 100%;
  height: 80px;
  padding: 0rem 1rem;
  position: absolute;
  top: 70px;
  z-index: 9999;
  display: none;
  &::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  @media (max-width: 700px) {
    & {
      display: block;
    }
  }
`;

export const Heading = styled.h5`
  color: #4e4e4e;
  margin: 0.5rem 0;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
`;

export const Button = styled.button`
  background-color: #171731 !important;
  border: 1px solid #3c3777 !important;
  border-radius: 30px !important;
  margin-top: 2.3rem;
  margin-left: 0.2rem;
  padding: 0 1rem !important;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 9px;
  letter-spacing: 1px;
  height: 35px;
  color: #ffffff !important;
  border-radius: 30px !important;
  span {
    font-size: 20px;
    margin-left: 0.4rem;
    margin-bottom: 0.2rem;
  }
`;

export const SortCard = styled.div`
  border-radius: 23px !important;
  margin: 0 0.2rem;
  padding: 0 !important;
  border: 1px solid transparent !important;

  .accordionBtn {
    button {
      border: none;
      position: relative;
      font-size: 9px;
      letter-spacing: 1px;
      height: 30px;
      color: #ffffff !important;
      border-radius: 30px !important;
      p {
        margin-bottom: 0;
        margin-left: -0.7rem;
      }
      span {
        position: absolute;
        right: 10px;
        transform: rotate(0deg);
        transition: 200ms;
        font-size: 14px;
      }
      &::after {
        display: none !important;
      }
      &:focus {
        background-color: #0086a4 !important;
        color: #ffffff;
        border: none !important;
        box-shadow: none !important;
      }
      &.collapsed {
        span {
          transform: rotate(-90deg) !important;
        }
      }
    }
  }

  .accordionBody {
    border-radius: 23px;
    background-color: #0397d515 !important;
    text-align: left !important;
    padding: 0.4rem 0.5rem 1rem 0.5rem !important;
    a {
      display: block;
      text-decoration: none;
      letter-spacing: 1px;
      font-size: 8px;
      margin-bottom: 0.1rem;
    }
  }
`;
