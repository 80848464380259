import { Container as C, Card } from "react-bootstrap";
import styled from "styled-components";

export const Container = styled(C)`
  margin: 0;

  input {
    width: 482px;
    height: 49px;
    margin: 2rem 0rem 2rem 4px;
  }

  @media (max-width: 700px) {
    input {
      width: 96%;
      margin: 1em auto;
      display: block;
    }
  }

  @media (max-width: 700px) {
    margin-top: 80px;
  }

  .product-container {
    margin: 0;
    margin-bottom: 20px;
    margin-top: 10px;
  }
  
  .priceRangeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 340px;
    margin-top: -1.7em;
    div {
      display: flex;
      input {
        margin: 5px;
        background-color: rgb(255, 255, 255);
        border-radius: 10px;
        border: none;
        padding: 5px 10px;
        outline: none;
        width: 150px;
        font-size: 12px;
        height: 40px;
        text-align: center;
        background: #5142fc;
        color: white;
      }
      input::placeholder {
        color: white;
      }
    }
  }

  .filter-container {
    margin-top: 20px;
  }

  .dropdown {
    width: auto;
    height: 40px;
  }

  @media (max-width: 700px) {
    .row {
      margin-top: 15px;
      gap: 10px;
      justify-content: center;
    }
  }
`;

export const CollectionWrapper = styled(Card)`
  border-radius: 0px;
  cursor: pointer;
  margin-bottom: 8px;
  overflow: hidden;
`;
