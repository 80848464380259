import { Accordion } from "react-bootstrap";
import { FaChevronDown } from "react-icons/fa";
import { Heading, Option, Sidebar, SortCard } from "./Sort.styled";

const SortDropdown = ({ filters, isMarketPlace }) => {
  const { sortBy, setSortBy } = filters;
  const values = [
    {
      label: "Price High to Low",
      value: "price|desc",
    },
    {
      label: "Price Low to High",
      value: "price|asc",
    },
    {
      label: "Newest First",
      value: "date|desc",
    },
    {
      label: "Oldest First",
      value: "date|asc",
    },
    {
      label: "Name:A-Z",
      value: "name|asc",
    },
    {
      label: " Name:Z-A",
      value: "name|desc",
    },
  ];

  return (
    <Sidebar>
      <div>
        <Heading>Sort by</Heading>

        <Accordion defaultActiveKey="0" alwaysOpen>
          <SortCard
            className={isMarketPlace ? "creatorhub" : "gamehub"}
          >
            <Accordion.Header className="accordionBtn sortBtn">
              {values.find((item) => item.value === sortBy)?.label}
              <span>
                <FaChevronDown />
              </span>
            </Accordion.Header>
            <Accordion.Body className="accordionBody">
              <div>
                {values.map((item) => (
                  <Option
                    key={item.value}
                    onClick={(e) => setSortBy(item.value)}
                  >
                    {item.label}
                  </Option>
                ))}
              </div>
            </Accordion.Body>
          </SortCard>
        </Accordion>
      </div>
      <div>
        <Heading>Activity</Heading>
        <Accordion defaultActiveKey="0">
          <SortCard
            className={isMarketPlace ? "creatorhub" : "gamehub"}
          >
            <Accordion.Header className="accordionBtn recentBtn">
              Recent Sales
              <span>
                <FaChevronDown />
              </span>
            </Accordion.Header>
            <Accordion.Body className="accordionBody">
              <div >
                <Option onClick={() => setSortBy('recent|asc')}>
                  Recent Re-Listings
                </Option>
              </div>
            </Accordion.Body>
          </SortCard>
        </Accordion>
      </div>
    </Sidebar>
  );
};

export default SortDropdown;
