import Button from "components/button";
import LinkButton from "components/button/link";
import Input from "components/form/input";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Section } from "./index.styled";

const UpdatePrice = ({ onClose, onUpdate }) => {
  const [show, setShow] = useState(true);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  const onSubmit = async (data) => {
    if (data.nftPrice) {
      onUpdate(data.nftPrice);
    }
  };

  return (
    <Section>
      <Modal show={show} onHide={handleClose} centered backdrop="static">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <Modal.Title className="mb-4">Update Price</Modal.Title>
            <Input
              placeholder="New NFT price"
              type="number"
              step="any"
              register={{
                ...register("nftPrice", {
                  required: { message: "NFT Price is required ", value: true },
                  validate: (price: number) =>
                    price > 0 || "NFT Price is not valid !",
                }),
              }}
              errors={errors}
              className="priceInput"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button>Update</Button>
            <LinkButton onClick={handleClose}>Close</LinkButton>
          </Modal.Footer>
        </form>
      </Modal>
    </Section>
  );
};

export default UpdatePrice;
