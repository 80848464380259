import { Section as S } from "components/section";
import styled from "styled-components";

export const Section = styled(S)`
  background-color: rgb(0, 86, 114);
  
  .img-wrapper {
    width: 380px;
    height: 430px;
    overflow: hidden;
    border-radius: 30px;
    margin-bottom: 5px;

    img {
      width: 100%;
    }
  }

  .form-text {
    color: #d3d3d3 !important;
  }

  label {
    font-weight: 600;
    color: ${({ theme }) => theme.color[2]};
    font-size: 1.3em;
  }

  input, textarea {
    color: white;
  }

  @media (max-width: 576px) {
    .filter-container > div {
      padding: 2em;
    }
  }
  .tab-content {
    .rdt {
      input {
        position: relative;
        padding: 0.9em 1rem;
        background: rgba(255, 255, 255, 0.1);
        color: ${({ theme }) => theme.color[0]};
        border: ${({ theme }) => "1px solid transparent"};
        box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px,
          rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
        border-radius: 5px !important;
        font-size: 18px;
        font-weight: 400;
        line-height: 1.7;
        box-sizing: border-box;
        appearance: none;
        transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

        &.is-invalid {
          border: ${({ theme }) => "1px solid #e86557 !important"};
        }

        &:not(.disabled):hover {
          box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
            rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
        }

        &:not(.disabled):focus-within {
          box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
            rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
        }

        &:not(.disabled):active {
          box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px,
            rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
        }
      }
    }
  }

  .nav-tabs {
    border-bottom: none;
    justify-content: space-between;
    margin-bottom: 2em !important;
    .nav-item > button {
      height: 100%;
    }

    li {
      width: calc(33% - 5px);

      button {
        width: 100%;
        height: 175px;
        border: none;
        padding: 2rem 1rem;
        border-radius: 10px;
        margin-bottom: 25px;
        background: rgba(255, 255, 255, 0.1) !important;

        &.active {
          border: ${({ theme }) => `3px solid ${theme.secondary}`};
        }
      }
    }

    .tab-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-weight: 500;
      color: ${({ theme }) => theme.color[0]};

      img {
        margin-top: 10px;
        width: 40px;
        height: 40px;
      }
    }
  }

  .purchase-container {
    margin: 20px 0 40px;
    padding: 20px;
    width: fit-content;
    text-align: center;
    border-radius: 40px;
    box-shadow: rgb(0 0 0 / 10%) 0px 3px 1px -2px,
      rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 3px;

    h3 {
      margin-bottom: 5px !important;
    }

    .flex-box {
      display: flex;
      flex-direction: row;

      p {
        color: #92cac4;
        font-size: 30px;
      }

      img {
        width: 70px;
        height: 50px;
      }

      &.price {
        margin: 25px 0;
        justify-content: center;
      }

      &.actions {
        gap: 25px;
      }
    }
  }

  p {
    font-size: 20px;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }

  .dropdown-menu {
    background: ${({ theme }) => theme.primary[0] + "80"};
    margin: 40px 0;
  }

  .tranding-row {
    margin-top: 50px;

    .purchase-container {
      margin: 0;
      padding: 20px;
      min-height: 200px;
      text-align: left;
      width: auto;

      label {
        padding: 5px 0;
      }
    }
  }

  @media (max-width: 576px) {
    padding: 20px 0 50px;

    .img-wrapper {
      width: 100%;
      height: 380px;
      border-radius: 25px;
      margin-bottom: 5px;
    }

    .purchase-container {
      width: 100%;

      .flex-box {
        &.actions {
          gap: 10px;

          button {
            font-size: 18px;
          }
        }
      }
    }

    .dropdown-menu {
      background: ${({ theme }) => theme.background[1]};
      margin: 0;
    }

    .tranding-row {
      gap: 40px;
    }
  }
`;

export const DatetimeWrapper = styled.div`
  position: relative;

  p {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #e86557;
    font-size: 14px;
    padding: 0 5px;
    text-align: right;
  }
`;
