import { Col, Row, Container } from "react-bootstrap";
import { Section, ProfileDetails, NftMint } from "./index.styled";
import { useParams } from "react-router";
import FlipCountdown from "@rumess/react-flip-countdown";

import { ENFTType, useGetNFTObjectDetail } from "hooks/useApi";
import { useProfile, useProfileList } from "store/hooks";
import moment from "moment";
import { getExplorerUrlByNftId } from "utils";
import { useWeb3React } from "@web3-react/core";
import { useEffect, useState } from "react";
import DetailCard from "components/card/detailCard";
import CreateAuction from "components/CreateAuction";
import {
  bid,
  buy,
  cancelBid,
  claimAuction,
  getBalanceOfDEXI,
  getSellingData,
  startAuction,
  cancelSelling,
  updatePrice,
  sell,
  getMarketOwner,
} from "utils/contracts";
import toast from "react-hot-toast";
import Layout from "components/layout";
import { config } from "../../../../environment";
import { AttributesMigration } from "./AttributesMigration";
import NewDetailsCard from "components/card/newDetailsCard";
import TradingHistory from "components/card/tradingHistory";
import FixedPrice from "components/FixedPrice";
import PlaceBid from "components/PlaceBid";

interface RouteParams {
  tokenID: string;
  chainName: string;
}

const ProductDetailsScreen = () => {
  const { connector, library, chainId, account, active } = useWeb3React();
  const { tokenID, chainName } = useParams<RouteParams>();
  const nftDetails = useGetNFTObjectDetail(tokenID, chainName);
  const { profileList } = useProfileList();
  const [loginStatus, setLoginStatus] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [balanceDEXI, setBalanceDEXI] = useState(0);
  const [sellingData, setSellingData] = useState(null);
  const [marketOwner, setMarketOwner] = useState(null);
  const [showPlaceBid, setShowPlaceBid] = useState(false);
  const [showFixedPrice, setShowFixedPrice] = useState(false);
  const [showAuction, setShowAuction] = useState(false);
  const { profile } = useProfile();

  useEffect(() => {
    const isLoggedin =
      account &&
      active &&
      chainId === parseInt(config.REACT_APP_NETWORK_ID, 10);
    setLoginStatus(isLoggedin);

    if (library && account) {
      getBalanceOfDEXI(chainId, library, account).then((balanceDEXI) => {
        setBalanceDEXI(balanceDEXI);
      });

      getSellingData(chainId, library, tokenID).then((sellingData) => {
        setSellingData(sellingData);
      });

      getMarketOwner(chainId, library).then((owner) => {
        setMarketOwner(owner);
      });
    }
  }, [connector, library, account, active, chainId, tokenID]);

  const isOwnsProduct = account
    ? sellingData?.seller?.toLowerCase() === account?.toLowerCase() ||
      marketOwner?.toLowerCase() === account?.toLowerCase() ||
      nftDetails?.nft?.ownerAddress?.toLowerCase() === account?.toLowerCase()
    : false;

  const modalClose = () => {
    setShowPlaceBid(false);
    setShowFixedPrice(false);
  };

  const sorted_nft_events = nftDetails?.resolvedTradingHistory?.sort((evt1, evt2) => {
    if (evt1.eventDate > evt2.eventDate) return -1;
    if (evt1.eventDate < evt2.eventDate) return 1;
    return 0;
  });


  /////////////////////////// NFT Enable & Disable ////////////////////////////
  const updateNFTListingStatus = async () => {
    if (!loginStatus) {
      toast.error("Please connect correctly!");
      return;
    }

    if (!isOwnsProduct) {
      toast.error("You are not owner of this asset!");
      return;
    }

    setLoading(true);
    const load_toast_id = toast.loading("Please wait...");

    try {
      let txhash;

      if (nftDetails?.nft?.listed) {
        txhash = await cancelSelling(
          chainId,
          library.getSigner(),
          nftDetails?.nft?.tokenID
        );
      } else if (nftDetails?.nft?.nftType === ENFTType.FIXED_PRICE) {
        txhash = await sell(
          chainId,
          library.getSigner(),
          nftDetails?.nft?.price,
          nftDetails?.nft?.tokenID
        );
      } else {
        txhash = await startAuction(
          chainId,
          library.getSigner(),
          nftDetails?.nft?.tokenID,
          nftDetails?.nft?.minBidPrice,
          nftDetails?.nft?.startTime,
          nftDetails?.nft?.endTime
        );
      }

      if (txhash !== false) {
        toast.dismiss(load_toast_id);
        toast.success("NFT Listing Updated Successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.dismiss(load_toast_id);
        toast.error("NFT Listing Failed!");
      }
    } catch (error) {
      toast.dismiss(load_toast_id);
      toast.error("NFT Listing Failed!");
    }
    setLoading(false);
  };

  /////////////////////////// NFT Price Update For Fixed Type ////////////////////////////
  const sellForFixedPrice = async (nftPrice) => {
    modalClose();

    if (!loginStatus) {
      toast.error("Please connect correctly!");
      return;
    }
    if (!isOwnsProduct) {
      toast.error("You are not owner of this asset!");
      return;
    }

    if (
      moment(parseInt(nftDetails?.nft?.endTime) * 1000).isAfter(
        new Date().getTime()
      )
    ) {
      toast.error("Auction is not ended yet!");
      return;
    }

    setLoading(true);
    const load_toast_id = toast.loading("Please wait...");
    try {
      const txhash = await sell(
        chainId,
        library.getSigner(),
        nftPrice,
        nftDetails?.nft?.tokenID
      );

      if (txhash !== false) {
        toast.dismiss(load_toast_id);
        toast.success("NFT Selling started successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.dismiss(load_toast_id);
        toast.error("NFT Selling start failed!");
      }
    } catch (error) {
      toast.dismiss(load_toast_id);
      toast.error("NFT Selling start failed!");
    }
    setLoading(false);
  };

  /////////////////////////// NFT Price Update For Fixed Type ////////////////////////////
  const updateNFTPrice = async (nftPrice) => {
    modalClose();

    if (!loginStatus) {
      toast.error("Please connect correctly!");
      return;
    }
    if (!isOwnsProduct) {
      toast.error("You are not owner of this asset!");
      return;
    }

    setLoading(true);
    const load_toast_id = toast.loading("Please wait...");

    try {
      const txhash = await updatePrice(
        chainId,
        library.getSigner(),
        nftDetails?.nft?.tokenID,
        nftPrice
      );

      if (txhash !== false) {
        toast.dismiss(load_toast_id);
        toast.success("NFT Price is updated successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.dismiss(load_toast_id);
        toast.error("NFT Price Update Failed!");
      }
    } catch (error) {
      toast.dismiss(load_toast_id);
      toast.error("NFT Price Update Failed!");
    }
    setLoading(false);
  };

  /////////////////////////// NFT Purchase For Fixed Type ////////////////////////////
  const buyNowNFT = async () => {
    if (!loginStatus) {
      toast.error("Please connect your wallet correctly!");
      return;
    }

    if (!profile) {
      toast.error("Please login correctly!");
      return;
    }

    if (!profile?.displayName) {
      toast.error(
        "Please add your name and email in the profile before buying!"
      );
      return;
    }

    if (!profile?.over18) {
      toast.error(
        "Must be 18 years of age or older to buy, please update your profile."
      );
      return;
    }

    if (!profile?.region) {
      toast.error("Please select your region in the profile before buying!");
      return;
    }

    if (sellingData?.seller?.toLowerCase() === account?.toLowerCase()) {
      toast.error("Owner can't purchase their NFT.");
      return;
    }

    if (nftDetails?.nft?.listed === false) {
      toast.error("Currently not open for sale 🔒");
      return;
    }

    setLoading(true);
    const load_toast_id = toast.loading("Please wait...");

    try {
      const txhash = await buy(
        chainId,
        library.getSigner(),
        account,
        nftDetails?.nft?.tokenID,
        nftDetails?.nft?.price
      );
      if (txhash !== false) {
        toast.success("Purchased current NFT!");
        setTimeout(() => {
          // TODO: change the state without reloading
          window.location.reload();
        }, 3000);
      }
    } catch (error) {
      toast.error("NFT purchase failed!");
    }
    toast.dismiss(load_toast_id);
    setLoading(false);
  };

  /////////////////////////// Submit Bid ////////////////////////////
  const submitPlaceBid = async (bidPrice) => {
    if (!loginStatus) {
      toast.error("Please connect correctly!");
      return;
    }

    if (!profile) {
      toast.error("Please login correctly!");
      return;
    }

    if (!profile?.displayName) {
      toast.error(
        "Please add your name and email in the profile before make a bid!"
      );
      return;
    }

    if (!profile?.over18) {
      toast.error("Must be 18 years of age or older to make a bid!");
      return;
    }

    if (!profile?.region) {
      toast.error(
        "Please select your region in the profile before make a bid!"
      );
      return;
    }

    if (sellingData?.seller?.toLowerCase() === account?.toLowerCase()) {
      toast.error("Owner can't bid to their NFT.");
      return;
    }

    if (nftDetails?.nft?.listed === false) {
      toast.error("Currently not open for sale 🔒");
      return;
    }

    for (let i = 0; i < nftDetails?.bids?.length; i++) {
      if (account?.toLowerCase() === nftDetails?.bids[i].bidder.toLowerCase()) {
        toast.error("You already bidded for this NFT!");
        return;
      }
    }

    if (nftDetails?.nft?.nftType === ENFTType.AUCTION) {
      if (
        moment(parseInt(nftDetails?.nft?.endTime) * 1000).isBefore(
          new Date().getTime()
        )
      ) {
        toast.error("Auction is already ended!");
        return;
      }
      if (
        moment(parseInt(nftDetails?.nft?.startTime) * 1000).isAfter(
          new Date().getTime()
        )
      ) {
        toast.error("Auction is not started yet!");
        return;
      }
      if (Number(bidPrice) < Number(nftDetails?.nft?.minBidPrice)) {
        toast.error(
          "Bid price should be over than minimum bid price on Auction!"
        );
        return;
      }
      for (let i = 0; i < nftDetails?.bids?.length; i++) {
        if (bidPrice < nftDetails?.bids[i].price) {
          toast.error(
            "Bid price should be over than current highest bid price on Auction!"
          );
          return;
        }
      }
    }

    setLoading(true);
    try {
      const txhash = await bid(
        chainId,
        library.getSigner(),
        account,
        nftDetails?.nft?.tokenID,
        bidPrice
      );
      if (txhash !== false) {
        toast.success("Bid Offered Successfully!");
        modalClose();
      } else toast.error("Bid Failed!");
    } catch (error) {
      toast.error("Bid Failed!");
    }
    setLoading(false);
  };

  /////////////////////////// Claim Auction ////////////////////////////
  const submitClaimAuction = async () => {
    if (!loginStatus) {
      toast.error("Please connect correctly!");
      return;
    }

    if (
      moment(parseInt(nftDetails?.nft?.endTime) * 1000).isAfter(
        new Date().getTime()
      )
    ) {
      toast.error("Auction is not ended yet!");
      return;
    }

    setLoading(true);
    const load_toast_id = toast.loading("Please wait...");
    try {
      const txhash = await claimAuction(
        chainId,
        library.getSigner(),
        nftDetails?.nft?.tokenID,
        account
      );
      if (txhash !== false) {
        toast.success("NFT is claimed successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.dismiss(load_toast_id);
        toast.error("NFT Claim Failed!");
      }
    } catch (error) {
      toast.dismiss(load_toast_id);
      toast.error("NFT Claim Failed!");
    }
    setLoading(false);
  };

  /////////////////////////// Start Auction ////////////////////////////
  const auctionStart = async (minBidPrice, startTime, endTime) => {
    modalClose();

    if (!loginStatus) {
      toast.error("Please connect correctly!");
      return;
    }
    if (!isOwnsProduct) {
      toast.error("You are not owner of this asset!");
      return;
    }

    if (
      moment(parseInt(nftDetails?.nft?.endTime) * 1000).isAfter(
        new Date().getTime()
      )
    ) {
      toast.error("Auction is not ended yet!");
      return;
    }

    setLoading(true);
    const load_toast_id = toast.loading("Please wait...");
    try {
      const txhash = await startAuction(
        chainId,
        library.getSigner(),
        nftDetails?.nft?.tokenID,
        minBidPrice,
        startTime,
        endTime
      );

      if (txhash !== false) {
        toast.dismiss(load_toast_id);
        toast.success("NFT Auction Started successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.dismiss(load_toast_id);
        toast.error("NFT Auction Start Failed!");
      }
    } catch (error) {
      toast.dismiss(load_toast_id);
      toast.error("NFT Auction Start Failed!");
    }
    setLoading(false);
  };

  /////////////////////////// Cancel Bid ////////////////////////////
  const cancelNFTBid = async () => {
    if (!loginStatus) {
      toast.error("Please connect correctly!");
      return;
    }

    setLoading(true);
    const load_toast_id = toast.loading("Please wait...");
    try {
      const txhash = await cancelBid(
        chainId,
        library.getSigner(),
        nftDetails?.nft?.tokenID
      );

      if (txhash !== false) {
        toast.dismiss(load_toast_id);
        toast.success("Bid Cancelled successfully!");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        toast.dismiss(load_toast_id);
        toast.error("Bid Cancel Failed!");
      }
    } catch (error) {
      toast.dismiss(load_toast_id);
      toast.error("Bid Cancel Failed!");
    }
    setLoading(false);
  };

  return (
    <Layout>
      <Section id="product-details">
        <Container>
          <Row className="filter-container" style={{ position: "relative" }}>
            <Col xs={12} md={4}>
              <NewDetailsCard
                productObj={nftDetails?.nft}
                isMarketplace={false}
              />
            </Col>
            <Col xs={12} md={8}>
              <ProfileDetails>
                <span className="borderBox"></span>
                <DetailCard
                  isOwnsProduct={isOwnsProduct}
                  nftDetails={nftDetails}
                  loading={loading}
                  updateNFTPrice={updateNFTPrice}
                  updateNFTListingStatus={updateNFTListingStatus}
                  buyNowNFT={buyNowNFT}
                  submitClaimAuction={submitClaimAuction}
                  auctionStart={auctionStart}
                  cancelNFTBid={cancelNFTBid}
                  setShowFixedPrice={setShowFixedPrice}
                  setShowAuction={setShowAuction}
                  setShowPlaceBid={setShowPlaceBid}
                />
              
                <NftMint>
                  <div className="transaction">Mint Transaction Hash : </div>
                  <span style={{ wordBreak: "break-word", paddingLeft: "1rem" }}>
                    {nftDetails?.nft?.mintTransactionHash}{" "}
                  </span>

                  <div className="mb-5" style={{ height: "180px", paddingLeft: "1rem" }}>
                    {nftDetails?.nft?.attributes?.weaponLevel >= 0 ? (
                      <div>
                        <span>Level: </span>
                        <span className="p-0">
                          <b>{nftDetails?.nft?.attributes?.weaponLevel}</b>{" "}
                        </span>
                      </div>
                    ) : null}
                    {nftDetails?.nft?.attributes?.EnemiesKilled >= 0 ? (
                      <div className="p-0">
                        <span>Enemies Killed: </span>
                        <span>
                          <b>{nftDetails?.nft?.attributes?.EnemiesKilled}</b>{" "}
                        </span>
                      </div>
                    ) : null}
                    {nftDetails?.nft?.attributes?.itemLevel >= 0 ? (
                      <div className="p-0">
                        <span>Level: </span>
                        <span>
                          <b>{nftDetails?.nft?.attributes?.itemLevel}</b>{" "}
                        </span>
                      </div>
                    ) : null}
                    {nftDetails?.nft?.attributes?.weaponDamage >= 0 ? (
                      <div className="p-0">
                        <span>Weapon Damage: </span>
                        <span>
                          <b>{nftDetails?.nft?.attributes?.weaponDamage}</b>{" "}
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <div className="mintBtn">
                    <div className="royality">
                      Royalty fee: {nftDetails?.nft?.royalty}%
                    </div>
                    <button
                      onClick={() => window.open(getExplorerUrlByNftId(chainName, nftDetails?.nft?.tokenID, 'GAMEVAULTNFT'))}
                      className="viewBtn"
                    >
                      View on {chainName === 'polygon' ? 'Polygon' : 'Bsc'}Scan <img src="/images/arrow.svg" alt="" />
                    </button>
                  </div>
                </NftMint>


                {showFixedPrice && (
                  <FixedPrice
                    onClose={modalClose}
                    onSubmit={sellForFixedPrice}
                  />
                )}

                {showAuction && (
                  <CreateAuction
                    onClose={modalClose}
                    onSubmit={auctionStart}
                  />
                )}

                {nftDetails?.nft?.endTime &&
                  moment(nftDetails?.nft?.endTime, "X").isAfter(moment()) && (
                    <FlipCountdown
                      hideYear
                      hideMonth
                      dayTitle="Days"
                      hourTitle="Hours"
                      minuteTitle="Minutes"
                      secondTitle="Seconds"
                      endAt={moment(nftDetails?.nft?.endTime, "X").format()}
                    />
                  )}
              </ProfileDetails>
            </Col>
          </Row>
          <Row className="tranding-row" style={{ position: "relative" }}>
            <TradingHistory
              sorted_nft_events={sorted_nft_events}
            />
          </Row>
        </Container>
        <PlaceBid
          show={showPlaceBid}
          loading={loading}
          onSubmit={submitPlaceBid}
          onClose={() => setShowPlaceBid(false)}
          balanceDXG={balanceDEXI}
        />
        <AttributesMigration
          tokenID={tokenID}
          currentAttributes={nftDetails?.nft?.attributes}
        />
      </Section>
    </Layout>
  );
};

export default ProductDetailsScreen;
