import { CONTRACTS_BY_NETWORK, Networks } from "utils";

export const isMigrationNeeded = (contractAddress: string) => 
  [
    CONTRACTS_BY_NETWORK[Networks.MainNet].DXGOLD.address.toLowerCase(),
    CONTRACTS_BY_NETWORK[Networks.MainNet].DEXINFT.address.toLowerCase(),
    CONTRACTS_BY_NETWORK[Networks.MainNet].DXGNFT.address.toLowerCase(),
    CONTRACTS_BY_NETWORK[Networks.Testnet].DXGOLD.address.toLowerCase(),
    CONTRACTS_BY_NETWORK[Networks.Testnet].DEXINFT.address.toLowerCase(),
    CONTRACTS_BY_NETWORK[Networks.Testnet].DXGNFT.address.toLowerCase(),
  ].includes(contractAddress?.toLowerCase());
