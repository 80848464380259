import {Button, Col, Modal, Row} from "react-bootstrap";
import {Product} from "../../../pages/profile/AddNftsModal/styled";
import LinkButton from "../../button/link";
import CardsMedia from "../../mediaBox";
import {useState} from "react";

type fullscreenNftModalProps = {
    onClose: () => void;
    show: boolean;
    productObj: any;
};
export const FullscreenNftModal = ({ onClose, show, productObj }: fullscreenNftModalProps) => {
    const [loading, setLoading] = useState(false);

    return (
        <Modal size="xl" show={show} onHide={onClose} fullscreen={true} scrollable centered backdrop="static">
            <Modal.Body>
                <Modal.Title className="mb-4">{productObj.name}</Modal.Title>
                <CardsMedia
                    controls
                    productId={productObj?._id}
                    assetType={productObj?.assetType}
                    assetUrl={productObj?.cdnAssetUrl}
                    bannerImage={productObj?.cdnBannerImage}
                    showFullScreen={true}
                />
            </Modal.Body>
            <Modal.Footer>
                <LinkButton onClick={onClose}>Close</LinkButton>
            </Modal.Footer>
        </Modal>
    );
}