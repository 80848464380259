import styled from "styled-components";

export const StyledTradingHistory = styled.div`
  @media (max-width: 700px) {
    .titleHeading {
      height: auto;
    }

    .titleHeading h3 {
      font-size: 20px;
    }

    .purchase-container {
      .row:first-child {
        display: none;
      }

      .row {
        flex-direction: column;
        margin-bottom: 20px;
      }

      .row > div {
        width: 100%;
      }
    }
  }
`;
