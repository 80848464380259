import { useState } from "react";
import { useWeb3React } from "@web3-react/core";
import UpdatePrice from "components/UpdatePrice";
import { NftName } from "pages/creatorhub/subpages/detail/index.styled";

export default function Index({
  nftDetails,
  loading,
  updateNFTPrice,
  updateNFTListingStatus,
  buyNowNFT,
  isOwnsProduct,
}) {
  const { account } = useWeb3React();
  const isCreatorHub = window.location.pathname.includes("creator");

  const [showPrice, setShowPrice] = useState(false);

  return (
    <NftName className={isCreatorHub ? "" : "gamehub"}>
      <h1>{nftDetails?.nft?.name}</h1>
      <p>{nftDetails?.nft?.description}</p>
      {showPrice && (
        <UpdatePrice
          onClose={() => {
            setShowPrice(false);
          }}
          onUpdate={(val) => {
            updateNFTPrice(val);
            setShowPrice(false);
          }}
        />
      )}
      {isOwnsProduct && (
        <div className="flex-box actions">
          <button
            className="buyBtn"
            style={{ marginLeft: "0.7em" }}
            onClick={updateNFTListingStatus}
            disabled={loading}
          >
            Disable for sale
          </button>
          <button
            className="buyBtn"
            style={{ marginLeft: "0.7em" }}
            onClick={() => setShowPrice(true)}
            disabled={loading}
          >
            Update Price
          </button>
        </div>
      )}

      {!isOwnsProduct && account && (
        <div className="flex-box actions mb-3">
          <button
            className="buyBtn"
            style={{ marginLeft: "0.7em" }}
            onClick={buyNowNFT}
            disabled={loading}
          >
            Buy Now
          </button>
        </div>
      )}
      <>
        <div className="purchase">
          <h3>Sale Price:</h3>
          <button className="dexiBtn">
            {`${nftDetails.nft.price} `} DEXI
          </button>
        </div>
      </>
    </NftName>
  );
}
