import styled from "styled-components";

interface FullScreenProps {
    readonly useOriginal: boolean;
}


export const NOMedia = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: calc(100% - 0px);
  height: calc(100% - 0px);
  object-fit: cover;
  background: #f4f4f4;
  background: ${({ theme }) => theme.background[1]};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Media = styled.img<FullScreenProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  object-fit: ${(props)=> props.useOriginal ? "contain" : "cover"};
`;

export const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  object-fit: cover;
`;

export const Audio = styled.audio`
  position: absolute;
  bottom: 20px;
  left: 0;
  width: calc(100% - 10px);
  margin: 0 5px;
  object-fit: cover;
  border-radius: 30px;
  z-index: 1;
  box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px,
    rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
`;

export const CardMediaPreview = styled.div`
  height: 100%;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  .audio-wrapper {
    img {
      position: absolute;
      height: 100% !important;
    }

    &:after {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 30px;
      background: #ffffff87;
      z-index: 0;
    }
  }
`;
