import {Input} from "antd";
import {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import Layout from "components/layout";
import Swiper from "components/swiper";
import {searchNfts, NFTObjectData} from "hooks/useApi";
import {useHistory} from "react-router-dom";
import {Carousel, GameHub, Heading, Pera} from "./index.styled";
import {returnCDNImage} from "utils/cdnHelper";

const PAGE_NUM = 0;

const Game = () => {
    let [nfts, setNfts] = useState([]);
    const history = useHistory();
    const [searchVal, setSearchVal] = useState("");
    useEffect(() => {
        const fetchData = async () => {
            const response = await searchNfts(
                {
                    category: null,
                    level: null,
                    subCategory: null,
                    minPrice: null,
                    maxPrice: null,
                    sortBy: "date|desc",
                    availability: "-1",
                },
                PAGE_NUM
            );

            if (response) {
                let swiperList = response.results.slice(0, 10);

                swiperList.map((nft: NFTObjectData) => {
                    nft.cdnAssetUrl = returnCDNImage(nft.assetType, nft.assetUrl);
                });
                setNfts(swiperList);
            }
        };

        fetchData();
    }, []);
    return (
        <Layout>
            <GameHub>
                <Input
                    placeholder="Search"
                    onChange={(e) => setSearchVal(e.target.value)}
                    value={searchVal}
                    onKeyPress={(e) => {
                        if (e.key === "Enter") {
                            history.push({
                                pathname: `/explore`,
                                search: `q=${searchVal}`,
                            });
                        }
                    }}
                />
                <Container>
                    <Pera>
                        <b>Welcome to the GameVault!</b>
                    </Pera>
                    <Pera>
                        Get all of your DEXI gaming NFTs and level them up through game play! These assets will
                        customize your gameplay, <br/> enhance your character’s abilities and power, or unlock new
                        features in-game. Level up your NFT items and list them later to sell to another player in the
                        ecosystem.
                    </Pera>
                    <Pera>
                        See the latest gaming NFTs below or head to the Explore tab to find more.
                    </Pera>
                    <Heading>
                        <b>Latest Game NFTs</b>
                    </Heading>
                </Container>
                <Carousel>
                    <Swiper nfts={nfts}/>
                </Carousel>
            </GameHub>
        </Layout>
    );
};

export default Game;
