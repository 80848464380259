import { Form } from "react-bootstrap";
import { InputWrapper, InputStyled, InputGroupStyled } from "./index.styled";
import { useState } from "react";
import {
  DeepMap,
  FieldError,
  FieldValues,
  UseFormRegisterReturn,
} from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

interface PropsType {
  className?: string;
  label?: string;
  preFix?: any;
  postFix?: any;
  helpText?: any;
  type?: "text" | "number" | "password";
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  step?: any;
  min?: string;
  register?: UseFormRegisterReturn;
  errors?: DeepMap<FieldValues, FieldError>;
  onChange?: (data: any) => void;
}

const Input = ({
  className = "",
  label,
  preFix,
  postFix,
  helpText,
  type = "text",
  placeholder = "",
  value = "",
  min = "0",
  step,
  register,
  errors,
  disabled,
  onChange,
}: PropsType) => {
  const [inputValue] = useState(value);

  const onChangeHandler = (event) => {
    const value = event.target.value;
    if (onChange) onChange(value);
  };

  const name = register?.name;

  return (
    <InputGroupStyled className={className}>
      {label && (
        <Form.Label>
          {label}
          {/* {required && <FontAwesomeIcon icon={faStar} />} */}
        </Form.Label>
      )}
      <InputWrapper
        className={
          (errors && errors[name] ? "is-invalid " : " ") +
          (disabled ? " disabled" : " ")
        }
      >
        {preFix && <InputGroupStyled.Text>{preFix}</InputGroupStyled.Text>}
        <InputStyled
          className={errors && errors[name] && "is-invalid"}
          type={type}
          min={min}
          placeholder={placeholder}
          step={step}
          defaultValue={inputValue}
          onChange={onChangeHandler}
          {...register}
          disabled={disabled && disabled}
        />
        {postFix && <InputGroupStyled.Text>{postFix}</InputGroupStyled.Text>}
        {register && errors && (
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <Form.Control.Feedback className="feedback" type="invalid">
                {message}
              </Form.Control.Feedback>
            )}
          />
        )}
      </InputWrapper>
      {helpText && <Form.Text muted>{helpText}</Form.Text>}
    </InputGroupStyled>
  );
};

export default Input;
