// const dotenv = require("dotenv")

const {
  REACT_APP_NETWORK_ID,
  REACT_APP_PUBLIC_URL,
  REACT_APP_API_URL,
  REACT_APP_API_MARKETPLACE,
  REACT_APP_GATEWAY_URL,
  REACT_APP_SIGNUP_API_URL,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_AUTH0_REDIRECT_URI,
  REACT_APP_AUTH0_AUDIENCE,
  REACT_APP_INFURA_URL,
  REACT_APP_CDN_URL,
  REACT_APP_S3_TRIGGER_URL,
  REACT_APP_S3_TRIGGER_PATH,
  REACT_APP_GENERATE_IMAGES,
  REACT_APP_INFURA_PROJECTID,
  REACT_APP_INFURA_PROJECT_SECRET,
} = process.env;

const live = {
  REACT_APP_NETWORK_ID: REACT_APP_NETWORK_ID || "56",
  REACT_APP_PUBLIC_URL:
    REACT_APP_PUBLIC_URL || "https://emporium.dexioprotocol.com",
  REACT_APP_API_URL:
    REACT_APP_API_URL || "https://emporium-api.dexioprotocol.com/api",
  REACT_APP_API_MARKETPLACE:
    REACT_APP_API_MARKETPLACE || "https://admin.dexioprotocol.com/api",
  REACT_APP_GATEWAY_URL:
    REACT_APP_GATEWAY_URL || "https://gateway.dexioprotocol.com",
  REACT_APP_SIGNUP_API_URL:
    REACT_APP_SIGNUP_API_URL || "http://18.219.124.152/api/v1/auth",
  // # # 10+ nodes balanced, US/EU
  REACT_APP_NODE_1: "https://polygon-rpc.com",

  // # # 10+ nodes balanced, US/EU
  REACT_APP_NODE_2: "https://polygon-rpc.com",

  // # # 10+ nodes balanced in each region, global
  REACT_APP_NODE_3: "https://polygon-rpc.com",

  REACT_APP_AUTH0_DOMAIN: REACT_APP_AUTH0_DOMAIN || "login.dexioprotocol.com",
  REACT_APP_AUTH0_CLIENT_ID:
    REACT_APP_AUTH0_CLIENT_ID || "M1Jm3gN7PUHYdSdwZJTlKYsCwXeicmgE",
  REACT_APP_AUTH0_REDIRECT_URI:
    REACT_APP_AUTH0_REDIRECT_URI || "https://gamehub.dexioprotocol.com",
  REACT_APP_AUTH0_AUDIENCE:
    REACT_APP_AUTH0_AUDIENCE || "http://uat.dexigas.com:3330/api/v1",

  REACT_APP_SUBGRAPH_URL:
    "https://api.thegraph.com/subgraphs/name/dexioprotocolben/migration-live",

  REACT_APP_INFURA_URL:
    REACT_APP_INFURA_URL || "https://nfthub.infura-ipfs.io/",
  REACT_APP_CDN_URL:
    REACT_APP_CDN_URL || "https://d2tlo1rxuvrdyt.cloudfront.net/",
  REACT_APP_S3_TRIGGER_URL:
    REACT_APP_S3_TRIGGER_URL ||
    "https://7tmoso77qk.execute-api.us-east-1.amazonaws.com",
  REACT_APP_S3_TRIGGER_PATH:
    REACT_APP_S3_TRIGGER_PATH ||
    "/default/image-flex-487111956536-dev-CreateIPFSAsset",

  REACT_APP_GENERATE_IMAGES: REACT_APP_GENERATE_IMAGES || false,
  REACT_APP_INFURA_PROJECTID:
    REACT_APP_INFURA_PROJECTID || "2DBwZKjMxdVVYbu6EcdxpCm3vMt",
  REACT_APP_INFURA_PROJECT_SECRET:
    REACT_APP_INFURA_PROJECT_SECRET || "a919da7e27cda23722df0c287518c30c",
};

const uat = {
  REACT_APP_NETWORK_ID: REACT_APP_NETWORK_ID || "97",
  REACT_APP_PUBLIC_URL: REACT_APP_PUBLIC_URL || "https://uat.dexigas.com",
  REACT_APP_API_URL: REACT_APP_API_URL || "https://uatapi.dexigas.com/api",
  REACT_APP_API_MARKETPLACE:
    REACT_APP_API_MARKETPLACE || "https://uatapi.dexioprotocol.com/api",
  REACT_APP_GATEWAY_URL:
    REACT_APP_GATEWAY_URL || "https://dev-gateway.dexioprotocol.com",
  REACT_APP_SIGNUP_API_URL:
    REACT_APP_SIGNUP_API_URL || "http://18.219.124.152/api/v1/auth",
  // # 10+ nodes balanced, US/EU
  REACT_APP_NODE_1: "https://rpc-mumbai.matic.today",
  // # # 10+ nodes balanced, US/EU
  REACT_APP_NODE_2: "https://rpc-mumbai.matic.today",
  // # # 10+ nodes balanced in each region, global
  REACT_APP_NODE_3: "https://rpc-mumbai.matic.today",

  REACT_APP_AUTH0_DOMAIN:
    REACT_APP_AUTH0_DOMAIN || "https://uat-dexioprotocol.us.auth0.com",
  REACT_APP_AUTH0_CLIENT_ID:
    REACT_APP_AUTH0_CLIENT_ID || "I52j7koZpoloUXCscOJ5OWbob3YAUJBt",
  REACT_APP_AUTH0_REDIRECT_URI:
    REACT_APP_AUTH0_REDIRECT_URI || "https://merge.dexigas.com/home",
  REACT_APP_AUTH0_AUDIENCE:
    REACT_APP_AUTH0_AUDIENCE || "http://uat.dexigas.com:3330/api/v1",

  REACT_APP_SUBGRAPH_URL:
    "https://api.thegraph.com/subgraphs/name/dexioprotocolben/migration",

  REACT_APP_INFURA_URL:
    REACT_APP_INFURA_URL || "https://nfthub.infura-ipfs.io/",
  REACT_APP_CDN_URL:
    REACT_APP_CDN_URL || "https://d2tlo1rxuvrdyt.cloudfront.net/",
  REACT_APP_S3_TRIGGER_URL:
    REACT_APP_S3_TRIGGER_URL ||
    "https://7tmoso77qk.execute-api.us-east-1.amazonaws.com",
  REACT_APP_S3_TRIGGER_PATH:
    REACT_APP_S3_TRIGGER_PATH ||
    "/default/image-flex-487111956536-dev-CreateIPFSAsset",

  REACT_APP_GENERATE_IMAGES: REACT_APP_GENERATE_IMAGES || false,
  REACT_APP_INFURA_PROJECTID:
    REACT_APP_INFURA_PROJECTID || "2DBwZKjMxdVVYbu6EcdxpCm3vMt",
  REACT_APP_INFURA_PROJECT_SECRET:
    REACT_APP_INFURA_PROJECT_SECRET || "a919da7e27cda23722df0c287518c30c",
};

const cronos_uat = {
  REACT_APP_NETWORK_ID: REACT_APP_NETWORK_ID || "338",
  REACT_APP_PUBLIC_URL: REACT_APP_PUBLIC_URL || "https://uat.dexigas.com",
  REACT_APP_API_URL: REACT_APP_API_URL || "https://uatapi.dexigas.com/api",
  REACT_APP_API_MARKETPLACE:
    REACT_APP_API_MARKETPLACE || "https://uatapi.dexioprotocol.com/api",
  REACT_APP_GATEWAY_URL:
    REACT_APP_GATEWAY_URL || "https://dev-gateway.dexioprotocol.com",
  REACT_APP_SIGNUP_API_URL:
    REACT_APP_SIGNUP_API_URL || "http://18.219.124.152/api/v1/auth",
  // # 10+ nodes balanced, US/EU
  REACT_APP_NODE_1: "https://rpc-mumbai.matic.today",
  // # # 10+ nodes balanced, US/EU
  REACT_APP_NODE_2: "https://rpc-mumbai.matic.today",
  // # # 10+ nodes balanced in each region, global
  REACT_APP_NODE_3: "https://rpc-mumbai.matic.today",

  REACT_APP_AUTH0_DOMAIN:
    REACT_APP_AUTH0_DOMAIN || "https://uat-dexioprotocol.us.auth0.com",
  REACT_APP_AUTH0_CLIENT_ID:
    REACT_APP_AUTH0_CLIENT_ID || "I52j7koZpoloUXCscOJ5OWbob3YAUJBt",
  REACT_APP_AUTH0_REDIRECT_URI:
    REACT_APP_AUTH0_REDIRECT_URI || "https://merge.dexigas.com/home",
  REACT_APP_AUTH0_AUDIENCE:
    REACT_APP_AUTH0_AUDIENCE || "http://uat.dexigas.com:3330/api/v1",

  REACT_APP_SUBGRAPH_URL:
    "https://api.thegraph.com/subgraphs/name/dexioprotocolben/migration",

  REACT_APP_INFURA_URL:
    REACT_APP_INFURA_URL || "https://nfthub.infura-ipfs.io/",
  REACT_APP_CDN_URL:
    REACT_APP_CDN_URL || "https://d2tlo1rxuvrdyt.cloudfront.net/",
  REACT_APP_S3_TRIGGER_URL:
    REACT_APP_S3_TRIGGER_URL ||
    "https://7tmoso77qk.execute-api.us-east-1.amazonaws.com",
  REACT_APP_S3_TRIGGER_PATH:
    REACT_APP_S3_TRIGGER_PATH ||
    "/default/image-flex-487111956536-dev-CreateIPFSAsset",

  REACT_APP_GENERATE_IMAGES: REACT_APP_GENERATE_IMAGES || false,
  REACT_APP_INFURA_PROJECTID:
    REACT_APP_INFURA_PROJECTID || "2DBwZKjMxdVVYbu6EcdxpCm3vMt",
  REACT_APP_INFURA_PROJECT_SECRET:
    REACT_APP_INFURA_PROJECT_SECRET || "a919da7e27cda23722df0c287518c30c",
};
const defaultConfig = {
  // # Binance Mainnet
  REACT_APP_NETWORK_ID: REACT_APP_NETWORK_ID || "97",

  REACT_APP_PUBLIC_URL: REACT_APP_PUBLIC_URL || "http://localhost:3000",
  REACT_APP_API_URL: REACT_APP_API_URL || "https://uatapi.dexigas.com/api",
  REACT_APP_API_MARKETPLACE:
    REACT_APP_API_MARKETPLACE || "https://uatapi.dexioprotocol.com/api",
  REACT_APP_GATEWAY_URL:
    REACT_APP_GATEWAY_URL || "https://dev-gateway.dexioprotocol.com",
  REACT_APP_SIGNUP_API_URL:
    REACT_APP_SIGNUP_API_URL || "http://18.219.124.152/api/v1/auth",

  // # 10+ nodes balanced, US/EU
  REACT_APP_NODE_1: "https://polygon-rpc.com",

  // # # 10+ nodes balanced, US/EU
  REACT_APP_NODE_2: "https://polygon-rpc.com",

  // # # 10+ nodes balanced in each region, global
  REACT_APP_NODE_3: "https://polygon-rpc.com",

  REACT_APP_AUTH0_DOMAIN:
    REACT_APP_AUTH0_DOMAIN || "https://uat-dexioprotocol.us.auth0.com",
  REACT_APP_AUTH0_CLIENT_ID:
    REACT_APP_AUTH0_CLIENT_ID || "I52j7koZpoloUXCscOJ5OWbob3YAUJBt",
  REACT_APP_AUTH0_REDIRECT_URI:
    REACT_APP_AUTH0_REDIRECT_URI || "http://localhost:3000/",
  REACT_APP_AUTH0_AUDIENCE:
    REACT_APP_AUTH0_AUDIENCE || "http://uat.dexigas.com:3330/api/v1",

  REACT_APP_SUBGRAPH_URL:
    "https://api.thegraph.com/subgraphs/name/dexioprotocolben/migration",

  REACT_APP_INFURA_URL:
    REACT_APP_INFURA_URL || "https://nfthub.infura-ipfs.io/",
  REACT_APP_CDN_URL:
    REACT_APP_CDN_URL || "https://d2tlo1rxuvrdyt.cloudfront.net/",
  REACT_APP_S3_TRIGGER_URL:
    REACT_APP_S3_TRIGGER_URL ||
    "https://7tmoso77qk.execute-api.us-east-1.amazonaws.com",
  REACT_APP_S3_TRIGGER_PATH:
    REACT_APP_S3_TRIGGER_PATH ||
    "/default/image-flex-487111956536-dev-CreateIPFSAsset",

  REACT_APP_GENERATE_IMAGES: REACT_APP_GENERATE_IMAGES || false,
  REACT_APP_INFURA_PROJECTID:
    REACT_APP_INFURA_PROJECTID || "2DBwZKjMxdVVYbu6EcdxpCm3vMt",
  REACT_APP_INFURA_PROJECT_SECRET:
    REACT_APP_INFURA_PROJECT_SECRET || "a919da7e27cda23722df0c287518c30c",
};

function configReturn() {
  if (process.env.REACT_APP_STAGE === "live") {
    return live;
  }
  if (process.env.REACT_APP_STAGE === "uat") {
    return uat;
  }
  if (process.env.REACT_APP_STAGE === "cronos_uat") {
    return cronos_uat;
  }
  return defaultConfig;
}

export const config = configReturn();

export default config;
