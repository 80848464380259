import React, {useState, useEffect} from "react";
import {EditOutlined} from "@ant-design/icons";
import Layout from "components/layout";
import {
  Container,
  Button,
  ButtonsContainer,
  SelectWrapper,
  Select,
  CollectionName,
  CollectionDescription,
  AddNftButton,
  MigrationHint,
} from "./index.styled";
import {Row, Col} from "react-bootstrap";
import NewProductCard from "components/card/newProductCard";
import {useProfile} from "store/hooks";
import {Redirect, useLocation} from "react-router-dom";
import {Dropdown, Menu} from "antd";
import {DownOutlined} from "@ant-design/icons";
import ProfileDetails from "./ProfileDetails";
import ProfileEdit from "./ProfileEdit";
import {returnCDNImage} from "utils/cdnHelper";
import {LoadingOutlined} from "@ant-design/icons";
import {currentNetwork, CONTRACTS_BY_NETWORK, Networks} from "utils";
import { AddNftsModal } from "./AddNftsModal";
import { EditCollectionModal } from "./EditCollectionModal";
import { CreateCollectionModal } from "./CreateCollectionModal";

const isProduction = Number(currentNetwork) === 137;

const STATUS_TYPES = ["Currently Own", "Created", "Bought", "Sold", "Transferred", "In-Market"];
const CONTRACTS = [
  {
    name: 'CreatorHub Polygon',
    contract: CONTRACTS_BY_NETWORK[Number(currentNetwork)].CREATORHUBNFT.address,

  },
  /*  {
      name: 'CreatorHub BSC',
      contract: isProduction ? CONTRACTS_BY_NETWORK[Networks.MainNet].DEXINFT.address : CONTRACTS_BY_NETWORK[Networks.Testnet].DEXINFT.address,
    }*/
  {
    name: 'GameVault Polygon',
    contract: CONTRACTS_BY_NETWORK[Number(currentNetwork)].GAMEVAULTNFT.address,
  },
/*  {
    name: 'GameVault BSC',
    contract: isProduction ? CONTRACTS_BY_NETWORK[Networks.MainNet].DXGNFT.address : CONTRACTS_BY_NETWORK[Networks.Testnet].DXGNFT.address,
  },
  {
    name: 'GameVault BSC Old',
    contract: isProduction ? CONTRACTS_BY_NETWORK[Networks.MainNet].DXGOLD.address : CONTRACTS_BY_NETWORK[Networks.Testnet].DXGOLD.address,
  },*/
];

export interface NFTDetails {
  contractAddress: string;
  contractName: string;
  ownedNfts: any[];
  boughtNfts: any[];
  createdNfts: any[];
  currentNfts: any[];
  soldNfts: any[];
  transferredNfts: any[];
  visible: boolean;
}

export default function Index() {
  const location = useLocation();
  const [selectedCard, setSelectedCard] = useState("Created");
  const [showEdit, setShowEdit] = useState(false);
  const [showCreateCollection, setShowCreateCollection] = useState(false);
  const [showAddCollectionNfts, setShowAddCollectionNfts] = useState(false);
  const [showEditCollection, setShowEditCollection] = useState(false);
  const [selectedContractNFTs, setSelectedContractNFTs] = useState(CONTRACTS[0]);
  const [showCollections, setShowCollections] = useState(false);
  const { profile, profileNfts, profileCollections } = useProfile();
  const [selectedCollection, setSelectedCollection] = useState(profileCollections ? profileCollections[0] : null);
  const [selectedCollectionNfts, setSelectedCollectionNfts] = useState([]);
  const isMarket = location.pathname.includes("creator") ? true : false;

  const [NFTsByContract, setNFTsByContract] = useState<NFTDetails[]>([]);

  useEffect(() => {
    if (!selectedCollection && profileCollections) {
      setSelectedCollection(profileCollections[0]);
    }

    const profileCollection = profileCollections?.find(collection => collection._id === selectedCollection?._id);
    if (profileCollection?.NFTs?.length !== selectedCollection?.NFTs?.length) {
        setSelectedCollection(profileCollection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileCollections])

  useEffect(() => {
    if (NFTsByContract.length > 0) {
      let selectedContract = NFTsByContract.find(x => x.contractAddress.toLowerCase() === selectedContractNFTs.contract.toLowerCase())
      if (selectedContract['ownedNfts'].length > 0) {
        setSelectedCard("Currently Own")
        return;
      }
      if (selectedContract['createdNfts'].length > 0) {
        setSelectedCard("Created")
        return;
      }
      if (selectedContract['currentNfts'].length > 0) {
        setSelectedCard("In-Market")
        return;
      }
      if (selectedContract['boughtNfts'].length > 0) {
        setSelectedCard("Bought")
        return;
      }
      if (selectedContract['soldNfts'].length > 0) {
        setSelectedCard("Sold")
        return;
      }
      if (selectedContract['transferredNfts'].length > 0) {
        setSelectedCard("Transferred")
        return;
      }
    }
  }, [selectedContractNFTs, NFTsByContract])

  useEffect(() => {
      const NFTDetailsBuckets: NFTDetails[] = []
      setFilteredNFTs([])

      for (let contract of CONTRACTS) {
        NFTDetailsBuckets.push({
          ownedNfts: [],
          boughtNfts: [],
          createdNfts: [],
          currentNfts: [],
          soldNfts: [],
          transferredNfts: [],
          contractAddress: contract.contract,
          contractName: contract.name,
          visible: false
        })
      }

      if(profileNfts){
        for (let event of Object.keys(profileNfts)) {
          for (let nft of profileNfts[event]) {
            try {
              NFTDetailsBuckets.find(
                  (x) =>
                      x.contractAddress.toLowerCase() ===
                      nft.contractAddress.toLowerCase()
              )[event].push(nft);
            } catch (e) {
              console.log('something went wrong with', nft, event)
              console.log(e)
            }
          }
        }
      }

      for (let bucket of NFTDetailsBuckets) {
        bucket.visible = bucket.ownedNfts.length > 0 || bucket.boughtNfts.length > 0 || bucket.createdNfts.length > 0 || bucket.currentNfts.length > 0 || bucket.soldNfts.length > 0 || bucket.transferredNfts.length > 0
      }

      setNFTsByContract(NFTDetailsBuckets)
      const firstContract = NFTDetailsBuckets.find(x => x.visible)

      if (firstContract) {
        setSelectedContractNFTs({
          contract: firstContract.contractAddress.toLowerCase(),
          name: firstContract.contractName
        });
      }
  }, [profileNfts]);


  const [filteredNFTs, setFilteredNFTs] = useState<NFTDetails[]>([]);
  const [filteredNFTsCount, setFilteredNFTsCount] = useState<number[]>([]);

  useEffect(() => {
    let key = ""

    switch (selectedCard) {
      case "Currently Own":
        key = "ownedNfts"
        break;
      case "Created":
        key = "createdNfts"
        break;
      case "Bought":
        key = "boughtNfts"
        break;
      case "Sold":
        key = "soldNfts"
        break;
      case "Transferred":
        key = "transferredNfts"
        break;
      case "In-Market":
        key = "currentNfts"
        break;
    }

    try {
      const filteredBySelectedContract = NFTsByContract.find((nft) =>
        nft.contractAddress.toLowerCase() === selectedContractNFTs.contract.toLowerCase()
      )

      if (Boolean(filteredBySelectedContract)) {
        setFilteredNFTsCount([filteredBySelectedContract.ownedNfts.length, filteredBySelectedContract.createdNfts.length,
          filteredBySelectedContract.boughtNfts.length,
          filteredBySelectedContract.soldNfts.length,
          filteredBySelectedContract.transferredNfts.length,
          filteredBySelectedContract.currentNfts.length]
        )                

        if (filteredBySelectedContract[key] && filteredBySelectedContract[key].length > 0) {
          const nftsWithCdn = filteredBySelectedContract[key].map((nft) => ({
            ...nft,
            cdnAssetUrl: returnCDNImage(nft.assetType, nft.assetUrl),
            cdnBannerImage: returnCDNImage(nft.assetType, nft.bannerImage),
          }));
          setFilteredNFTs(nftsWithCdn)
        }
      } else {
        setFilteredNFTs([])
      }
    } catch (e) {
      console.log(e)
    }
  }, [NFTsByContract, selectedCard, selectedContractNFTs])

  useEffect(() => {
    if (selectedCollection) {
        let collectionNfts = [];
        Object.keys(profileNfts).forEach(key => {
            if (profileNfts[key]) {
                profileNfts[key].forEach(nft => {
                    if (
                        selectedCollection.NFTs?.includes(nft.contractAddressAndTokenId) &&
                        !collectionNfts.some(collectionNft => collectionNft.contractAddressAndTokenId === nft.contractAddressAndTokenId)
                    ) {
                        collectionNfts.push({
                            ...nft,
                            cdnAssetUrl: returnCDNImage(nft.assetType, nft.assetUrl),
                            cdnBannerImage: returnCDNImage(nft.assetType, nft.bannerImage),
                        });
                    }
                })
            }
        })
        setSelectedCollectionNfts(collectionNfts);
    };
  }, [profileNfts, selectedCollection]);

  const menu = (
    <Menu
      items={STATUS_TYPES.map((item) => {
        return {
          label: (
            <Button
              key={item}
              onClick={() => setSelectedCard(item)}
              className={selectedCard === item ? "active" : ""}
            >
              {item}
            </Button>
          ),
          key: "0",
        };
      })}
    />
  );

  const menuSecond = (
    <Menu
      items={NFTsByContract.filter(x => x.visible).map((item: NFTDetails, index: number) => {
        return {
          label: (
            <Button
              key={index}
              onClick={() => setSelectedContractNFTs({
                contract: item.contractAddress.toLowerCase(),
                name: item.contractName
              })}
              className={selectedContractNFTs.contract === item.contractAddress ? "active" : ""}
            >
              {item.contractName}
            </Button>
          ),
          key: "0",
        };
      })}
    />
  );

  if (!profile) return <Redirect to="/creator" />;

  return (
      <Layout>
          <Container fluid>
                <Row>
                    <Col md={6} lg={4} xl={4} xxl={3}>
                        {showEdit ? <ProfileEdit setShowEdit={setShowEdit}/> : (
                            <>
                                <ProfileDetails
                                    profile={profile}
                                    setShowEdit={setShowEdit}
                                />
                                <ButtonsContainer className={`${isMarket ? "" : "gamehubButtons"}`}>
                                    <div className="dropdown">
                                        <Dropdown overlay={menu} trigger={["click"]}>
                                            <Button className={"active"}>
                                                {selectedCard}
                                                <DownOutlined/>
                                            </Button>
                                        </Dropdown>
                                        <Button
                                            onClick={() => setShowCollections(true)}
                                            className={showCollections ? "active" : ""}
                                        >
                                            Collections
                                        </Button>
                                        <Button onClick={() => setShowCreateCollection(true)}>
                                            Create new collection
                                        </Button>
                                    </div>
                                    <div className="desktopButtons">
                                        {STATUS_TYPES.map((item, index) => (
                                            <Button
                                                key={item}
                                                onClick={() => {
                                                    setSelectedCard(item);
                                                    setShowCollections(false)
                                                }}
                                                className={selectedCard === item ? "active" : ""}
                                            >
                                                {item} ({filteredNFTsCount[index]})
                                            </Button>
                                        ))}
                                        <Button
                                            onClick={() => {
                                                setSelectedCard(null);
                                                setShowCollections(true)
                                            }}
                                            className={showCollections ? "active" : ""}
                                        >
                                            Collections
                                        </Button>
                                    </div>
                                </ButtonsContainer>
                            </>
                        )}
                    </Col>
                    <Col md={6} lg={8} xl={8} xxl={9}>
                        {showCollections ? (
                            <>
                                <ButtonsContainer
                                    className={`${isMarket ? "" : "gamehubButtons"} typeButtonsContainer`}>
                                    <div className="dropdown">
                                        <Dropdown overlay={menuSecond} trigger={["click"]}>
                                            <Button className={"active"}>
                                                {selectedContractNFTs.name}
                                                <DownOutlined/>
                                            </Button>
                                        </Dropdown>
                                    </div>
                                    <div className="desktopButtons desktopButtons-flex">
                                        {profileCollections?.length > 0 && (
                                            <SelectWrapper>
                                                <Select
                                                    defaultValue={profileCollections[0]._id}
                                                    onChange={e =>
                                                        setSelectedCollection(
                                                            profileCollections.find(collection => collection._id === e.target.value)
                                                        )
                                                    }
                                                >
                                                    {profileCollections.map(collection => (
                                                        <option value={collection._id} key={collection._id}>
                                                            {collection.collectionName}
                                                        </option>
                                                    ))}
                                                </Select>
                                            </SelectWrapper>
                                        )}

                                        <Button style={{padding: '0 20px', height: 52}}
                                                onClick={() => setShowCreateCollection(true)}>
                                            Create collection
                                        </Button>
                                    </div>
                                </ButtonsContainer>
                                {selectedCollection ? (
                                    <>
                                        <CollectionName>
                                            {selectedCollection.collectionName}
                                            <EditOutlined
                                                style={{marginLeft: 10, cursor: 'pointer'}}
                                                onClick={() => setShowEditCollection(true)}
                                            />
                                        </CollectionName>
                                        <CollectionDescription>{selectedCollection.description}</CollectionDescription>
                                        <Row>
                                            {selectedCollectionNfts.length ? (
                                                <>
                                                    {selectedCollectionNfts.map((product: any, index: any) => (
                                                        <Col md={12} lg={6} xl={6} xxl={3} key={index}>
                                                            <NewProductCard productObj={product} isProfile={true}/>
                                                        </Col>
                                                    ))}
                                                    <Col md={12} lg={6} xl={6} xxl={3}>
                                                        <AddNftButton onClick={() => setShowAddCollectionNfts(true)}>Add NFTs</AddNftButton>
                                                    </Col>
                                                </>
                                            ) : (
                                                <Col md={12} lg={6} xl={6} xxl={3}>
                                                    <CollectionDescription>This collection is empty</CollectionDescription>
                                                    <AddNftButton onClick={() => setShowAddCollectionNfts(true)}>Add NFTs</AddNftButton>
                                                </Col>
                                            )}
                                        </Row>
                                    </>
                                ) : null}
                            </>
                        ) : (
                            <>
                                <ButtonsContainer
                                    className={`${isMarket ? "" : "gamehubButtons"} typeButtonsContainer`}>
                                    <div className="dropdown">
                                        <Dropdown overlay={menuSecond} trigger={["click"]}>
                                            <Button className={"active"}>
                                                {selectedContractNFTs.name}
                                                <DownOutlined/>
                                            </Button>
                                        </Dropdown>
                                    </div>
                                    <div className="desktopButtons">
                                        {NFTsByContract.filter((x) => x.visible).map(
                                            (item: NFTDetails, index: number) => (
                                                <Button
                                                    key={index}
                                                    onClick={() => {
                                                        setSelectedContractNFTs({
                                                            contract: item.contractAddress.toLowerCase(),
                                                            name: item.contractName,
                                                        });
                                                    }}
                                                    style={{padding: '0 20px', height: 52}}
                                                    className={
                                                        selectedContractNFTs.contract.toLowerCase() ===
                                                        item.contractAddress.toLowerCase()
                                                            ? "active"
                                                            : ""
                                                    }
                                                >
                                                    {item.contractName}
                                                </Button>
                                            )
                                        )}
                                    </div>
                                </ButtonsContainer>

                                <MigrationHint>
                                    Missing something? Did you migrate all your NFTs? Check&nbsp;
                                    <a
                                        href={"https://nft-migration.dexioprotocol.com/"}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        nft-migration.dexioprotocol.com
                                    </a>
                                </MigrationHint>
                                <Row>
                                    {filteredNFTs.map((product: any, index: number) => (
                                        <Col md={12} lg={6} xl={6} xxl={3} key={index}>
                                            <NewProductCard productObj={product} isProfile={true}/>
                                        </Col>
                                    ))}
                                </Row>
                            </>
                        )}
                    </Col>
                </Row>
            </Container>
            <AddNftsModal
                show={showAddCollectionNfts}
                selectedCollection={selectedCollection}
                onClose={() => setShowAddCollectionNfts(false)}
            />
            <EditCollectionModal
                show={showEditCollection}
                selectedCollection={selectedCollection}
                onClose={() => setShowEditCollection(false)}
            />
            <CreateCollectionModal
                show={showCreateCollection}
                onClose={() => setShowCreateCollection(false)}
            />
      </Layout>
  );
}
