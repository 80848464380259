import { Dropdown, ToggleBtn } from "./index.styled";
import { useEffect, useState } from "react";

export interface FilterOption {
  label: string;
  value: string;
  showNft?: boolean;
}
interface Props {
  onSelect?: (filterOption: FilterOption) => void;
  defaultValue?: FilterOption;
  filterOptions: Array<FilterOption>;
  className?: string;
}
export const Filter = ({
  filterOptions,
  defaultValue,
  className,
  onSelect,
}: Props) => {
  const [currentOption, setCurrentOption] = useState<FilterOption>();

  useEffect(() => {
    if (defaultValue) {
      setCurrentOption(defaultValue);
    } else {
      setCurrentOption(filterOptions[0]);
    }
  }, [filterOptions, defaultValue]);

  return (
    <Dropdown
      className={className}
      onSelect={(eventKey, event) => {
        let selectedOption = {
          label: (event.currentTarget as HTMLElement).innerHTML,
          value: eventKey,
        };
        setCurrentOption(selectedOption);
        onSelect && onSelect(selectedOption);
      }}
    >
      <ToggleBtn>{currentOption?.label}</ToggleBtn>
      <Dropdown.Menu>
        {filterOptions.map((obj, index) => (
          <Dropdown.Item
            key={index}
            eventKey={obj.value}
            active={currentOption?.value === obj.value}
          >
            {obj.label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
