import FileInput from "components/form/file";
import styled from "styled-components";

export const DetailsForm = styled.div`
  margin: 0 0 1rem 1rem;
  width: 100%;
  padding: 0rem;
  background-color: #ffffff;
  padding-top: 1em;

  .ant-form-item-row{
    align-items: flex-start !important;
  } 
  form {
    // height: 792px;
    padding: 1rem 1.5rem;
    background-color: #002d3c !important;
    .ant-form-item {
      margin-bottom: 0.6rem !important;

      input,
      textArea,
      .ant-select-selector {
        font-size: 17px;
        color: #ffffff;
        background-color: #4a6a75 !important;
        border: none !important;
        // font-family: "Oxanium";
        &:focus {
          border: none !important;
          box-shadow: none !important;
        }
      }
      input,
      .ant-select-selector {
        height: 36px;
      }
      .ant-select-selection-item {
        display: flex;
        align-items: center;
      }
      .ant-form-item-label {
        // margin-bottom: 0.3rem !important;
        padding-bottom:4px;
        label {
          font-size: 17px;
          font-weight: 300;
          color: #ffffff;
          // font-family: "Oxanium";
        }
      }
      h5 {
        color: #ffffff;
        font-weight: 700;
        font-size: 20px;
        margin-bottom: 0rem !important;
      }
      .saveBtn,
      .cancelBtn {
        margin-top: 0.5rem;
        display: inline-block;
        width: 161px;
        height: 43px;
        background-color: #00e1cd;
        color: #ffffff;
        border: none;
        font-weight: 700;
        font-size: 21px;
      }
      .cancelBtn {
        margin-left: 1rem;
        background-color: #4a6a75;
        color: #ffffff;
        border: none;
        font-weight: 400;
        font-size: 21px;
      }
    }
  }
`;
export const Heading = styled.h4`
  text-align: center;
  color: #002d3c;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const ImageUpload = styled(FileInput)`
  border-radius: 0 !important;
  height: 40vh;
  overflow: hidden;

  img {
    border-radius: 0 !important;
  }
`;
