import { useState } from "react";
import { Section } from "./index.styled";
import { Container, Button, Form, Alert } from "react-bootstrap";
import API from "utils/api";
import { useWeb3React } from "@web3-react/core";
import data from "./data";
import { useProfile } from "store/hooks";
import { Link } from "react-router-dom";
import Layout from "components/layout"
import { config } from "../../../../environment";
import { H1, H2, P } from "components/typography";
import APIFactory from "utils/api";

export default function Index() {
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const { account } = useWeb3React();
  const { profile } = useProfile();

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (subject === "" || body === "") {
      alert("Please fill the form");
      return;
    }

    APIFactory(config.REACT_APP_API_URL).post(`/support-tickets`, {
      account: profile.email,
      subject,
      body,
    })
      .then((resp) => {
        setSubject("");
        setBody("");
        alert(
          "We have received your ticket. We will get back to you as soon as possible"
        );
      })
      .catch((e) => {
        alert(e);
      });
  };

  return (
        <Layout>

    <Section style={{minHeight:"86.6vh"}}>
      <Container>
      <H2 style={{color:"rgb(47, 0, 54)"}} className="text-center mb-5">GameVault Support</H2>

        {profile ? (
          !profile?.email ? (
            <Alert>
              Please update your profile with Email in order to contact support.{" "}
              <Link to="/profile/edit">Update Profile</Link>
            </Alert>
          ) : (
            ""
          )
        ) : (
          <Alert>
            To contact support, please connect to or create a profile.{" "}
          </Alert>
        )}

        <Form method="post" onSubmit={handleFormSubmit}>
          <Form.Group className="mt-5 mb-3">
            <Form.Label>Topic</Form.Label>
            <Form.Select
              aria-label="Default select example"
              value={subject}
              onChange={(e) =>
                setSubject((e.target as HTMLSelectElement).value)
              }
            >
              <option value="">Select A Topic</option>
              {data.map((d) => (
                <option value={d.name}>{d.name}</option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Details</Form.Label>
            <Form.Control
              as="textarea"
              placeholder={data.find((d) => d.name === subject)?.explanation}
              rows={5}
              value={body}
              onChange={(e) => setBody((e.target as HTMLTextAreaElement).value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group>
            <Button type="submit" variant="primary" disabled={!profile?.email}>
              Submit
            </Button>
          </Form.Group>
        </Form>
      </Container>
    </Section>
        </Layout>

  );
}
