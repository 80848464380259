import styled from "styled-components";

export const SideBarMenu = styled.div`
  background-color: transparent;
  height: 100vh;
  width: 270px;
  padding: 1rem 0rem;
  padding-top: 10px;
  overflow-y: scroll;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 110px;
  margin-top: 108px;

  &::-webkit-scrollbar {
    width: 10px;
    display: none;
  }

  @media (max-width: 700px) {
    & {
      display: none;
    }
  }

  &.creatorhub > div {
    background-color: #0397d515 !important;
  }

  &.gamehub > div {
    background-color: #8b00b615 !important;
  }
`;

export const Sidebar = styled.div`
  padding: 0.5rem 1em 1.5rem 0;
  text-align: center;

  @media (max-width: 700px) {
    & {
      display: flex;
      background-color: transparent;
    }

    & > div {
      width: 50%;
    }
  }
`;

export const Heading = styled.h5`
  color: #4e4e4e;
  margin: 0.5rem 0;
  font-weight: 700;
  text-align: center;
  font-size: 1.2em;
`;

export const Option = styled.div`
  margin: 5px 0;
  font-size: 16px;
  color: #0397d5;
  cursor: pointer;
`;

export const SortCard = styled.div`
  padding: 0 !important;
  border: 1px solid transparent !important;

  .accordionBtn {
    button {
      background-color: #da8027 !important;
      border: none;
      position: relative;
      font-size: 13px;
      height: 40px;
      color: #ffffff !important;
  
      span {
        position: absolute;
        right: 20px;
        transform: rotate(0deg);
        transition: 200ms;
      }

      &::after {
        display: none !important;
      }

      &:focus {
        color: #ffffff;
        border: none !important;
        box-shadow: none !important;
      }

      &.collapsed {
        span {
          transform: rotate(-90deg) !important;
        }
      }
    }

    @media (max-width: 700px) {
      & > button {
        height: 30px;
      }
    }
  }

  &.creatorhub {
    .accordionBtn {
      button {
        background-color: #0397d5 !important;
      }
    }
  }

  &.gamehub {
    .accordionBtn {
      button {
        background-color: #da8027 !important;
      }
    }
  }

  .accordionBody {
    background-color: #da802715 !important;
    text-align: left !important;
    padding: 1rem !important;
    a {
      display: block;
      text-decoration: none;
      margin-bottom: 0.1rem;
      @media (max-width: 700px) {
        & {
          font-size: 10px;
          margin-bottom: 5px;
        }
      }
    }
  }
`;
