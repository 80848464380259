import React from "react";
import { ThemeProvider as ThemeProviders } from "styled-components";

interface CustomThemeType {
  isDark: boolean;
  background: string[];
  color: string[];
  primary: string[];
  secondary: string[];
}

const themes = {
  dark: {
    isDark: true,
    background: ["#0B1B27", "#183b56", "#2F2C2C", "#1E1D1D"],
    color: ["#fff", "#151515", "#c4c4c4"],
    primary: ["#5142fc", "#31273f"],
    secondary: ["#487b00"],
  },
  light: {
    isDark: false,
    background: ["#ffffff", "#F4F4F4", "#F6F6F6", "#FBFBFF"],
    color: ["#151515", "#8B8B9B", "#fff"],
    primary: ["#59DABC", "#0ca37f"],
    secondary: ["#2B569E"],
  },
};

const initialState = {
  dark: false,
  theme: themes.light,
  toggle: (isEnable: boolean) => {},
};
const ThemeContext = React.createContext(initialState);

const ThemeProvider = ({ children }) => {
  const [dark, setDark] = React.useState(false);

  React.useEffect(() => {
    const isDark = localStorage.getItem("dark")
      ? localStorage.getItem("dark") === "true"
      : false;
    setDark(isDark);
    // document.body.classList.remove("darkTheme", "lightTheme");
    // document.body.classList.add(isDark ? "darkTheme" : "lightTheme");
    document.body.classList.add("darkTheme");
  }, [dark]);

  const toggle = (isEnable) => {
    localStorage.setItem("dark", JSON.stringify(isEnable));
    // document.body.classList.remove("darkTheme", "lightTheme");
    // document.body.classList.add(isEnable ? "darkTheme" : "lightTheme");
    document.body.classList.add("darkTheme");
    setDark(isEnable);
  };

  const theme = themes.dark;
  // const theme = dark ? themes.dark : themes.light;

  return (
    <ThemeProviders theme={theme}>
      <ThemeContext.Provider value={{ theme, dark, toggle }}>
        {children}
      </ThemeContext.Provider>
    </ThemeProviders>
  );
};

export { ThemeProvider, ThemeContext };
export type { CustomThemeType };
