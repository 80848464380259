import { useWeb3React } from "@web3-react/core";
import { useCallback, useEffect, useState } from "react";

import { getContractObj } from "utils";

export const useMinter = () => {
  const [isMinter, setMinter] = useState(false);
  const { account, chainId, library } = useWeb3React();

  const getMintingAvailability = useCallback(async () => {
    const nftContract = getContractObj("GAMEVAULTNFT", chainId, library.getSigner());
    const owner = await nftContract.owner();
    const isMinter = await nftContract.minters(account);

    setMinter(owner === account || isMinter);
  }, [account, chainId, library])

  useEffect(() => {
    if (account) getMintingAvailability();
  }, [account, getMintingAvailability]);

  return isMinter;
}
