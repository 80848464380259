import {useCallback, useEffect} from "react";
import {BrowserRouter, Route, Switch, useHistory} from "react-router-dom";
import { Helmet } from "react-helmet";
import Creator from "./pages/creatorhub/Index";
import Game from "./pages/gamehub/Index";
import Mint from "./pages/gamehub/subpages/mint";
import GameExplore from "./pages/gamehub/subpages/explore";
import GameFaq from "./pages/gamehub/subpages/faq";
import GameSupport from "./pages/gamehub/subpages/support";
import CreatorExplore from "./pages/creatorhub/subpages/explore";
import CreatorMint from "./pages/creatorhub/subpages/mint";
import CreatorFaq from "./pages/creatorhub/subpages/faq";
import CreatorSupport from "./pages/creatorhub/subpages/support";
import CreatorDetail from "./pages/creatorhub/subpages/detail";
import GameDetail from "./pages/gamehub/subpages/detail";
import ProfileView from "./pages/profile";
import StoreFront from "./pages/storefront";
import { Collection } from "pages/collection";
import {fetchProfile} from "store/profile";
import {useFetchProfileList} from "store/hooks";
import axios from "axios";
import {Toaster} from "react-hot-toast";

import {config} from "./environment";
import {Auth0Provider, useAuth0} from "@auth0/auth0-react";
import ScrollToTop from "utils/scrollToTop";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "antd/dist/antd.css";
import {useDispatch} from "react-redux";
import Home from "pages/home";

import TagManager from 'react-gtm-module'
import {setWalletAddress} from "./hooks";
import {useWeb3React} from "@web3-react/core";

const {
    REACT_APP_GOOGLE_TAG: GOOGLE_TAG
} = process.env;
const tagManagerArgs = {
    gtmId: `${GOOGLE_TAG}`
}

function App() {
    const {getAccessTokenSilently, user} = useAuth0();
    const dispatch = useDispatch();
    const {account} = useWeb3React();
    const { location } = useHistory();
    const isCreatorHub = location.pathname.includes("creator") ? true : false;

    useEffect(() => {
        const getData = async () => {
            const token = await getAccessTokenSilently();

            axios.defaults.headers["Authorization"] = `Bearer ${token}`;

            const userId = user["https://dexioprotocol.com/userId"];
            dispatch(fetchProfile(userId));
        };
        const setWallet = async () => {
            const token = await getAccessTokenSilently();

            await setWalletAddress(
                {
                    wallet: account,
                    alias: "",
                    userId: user["https://dexioprotocol.com/userId"],
                },
                token
            );
        }
        const initialise = async () => {
            if (account) await setWallet()
            if (user) await getData();
        };
        initialise()
    }, [user, account]);

    useFetchProfileList();
    return (
        <>
            <Helmet>
                <title>{isCreatorHub ? "DEXI Creator Hub - Web3 reimagined" : "DEXI GameVault - Web3 reimagined"}</title>
                <meta property="og:title" content={isCreatorHub ? "DEXI Creator Hub - Web3 reimagined" : "DEXI GameVault - Web3 reimagined"} />
                <meta property="og:description" content={isCreatorHub
                    ? "Digital art reimagined"
                    : "Buy and Sell GameFi NFTs for the Dexioprotocol Gaming Ecosystem."
                } />
                <meta property="og:image" content={`"%PUBLIC_URL%/logos/${isCreatorHub ? "CreatorHubLogo.png" : "GameHubLogo.png"}"`} />
            </Helmet>

            <ScrollToTop/>

            <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/game" component={Game}/>
                <Route path="/explore" component={GameExplore}/>
                <Route path="/collection/:collectionName" component={Collection}/>
                <Route path="/faq" component={GameFaq}/>
                <Route path="/support" component={GameSupport}/>
                <Route path="/mint" component={Mint}/>
                <Route path="/product/:chainName/:tokenID" component={GameDetail}/>
                <Route path="/profile" component={ProfileView}/>
                <Route path="/storefront/:username" component={StoreFront}/>

                <Route exact path="/creator" component={Creator}/>
                <Route path="/creator/explore" component={CreatorExplore}/>
                <Route path="/creator/collection/:collectionSlug" component={Collection}/>
                <Route path="/creator/mint" component={CreatorMint}/>
                <Route path="/creator/faq" component={CreatorFaq}/>
                <Route path="/creator/support" component={CreatorSupport}/>
                <Route path="/creator/product/:chainName/:tokenID" component={CreatorDetail}/>
                <Route path="/creator/profile" component={ProfileView}/>
                <Route path="/creator/storefront/:username" component={StoreFront}/>
            </Switch>
        </>
    );
}

const isCreatorHub = window.location.pathname.includes("creator");
const Auth0App = () => {
        useEffect(() => {
            TagManager.initialize(tagManagerArgs);
        }, []);
        return (<Auth0Provider
            domain={config.REACT_APP_AUTH0_DOMAIN}
            clientId={config.REACT_APP_AUTH0_CLIENT_ID}
            redirectUri={`${config.REACT_APP_AUTH0_REDIRECT_URI}${
                isCreatorHub ? "creator" : "game"
            }`}
            audience={config.REACT_APP_AUTH0_AUDIENCE}
            useRefreshTokens
            cacheLocation="localstorage"
        >
            <BrowserRouter>
                <Toaster
                    toastOptions={{
                        style: {zIndex: 9999999999}
                    }}
                />
                <App/>
            </BrowserRouter>
        </Auth0Provider>)
    }
;

export default Auth0App;
