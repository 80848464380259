import Button from "components/button";
import LinkButton from "components/button/link";
import Input from "components/form/input";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Modal, Form } from "./index.styled";

const PlaceBid = ({ balanceDXG, onClose, show, onSubmit, loading }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const handleClose = () => {
    onClose();
  };

  const onFormSubmit = async (data) => {
    if (parseFloat(data.bidPrice) > balanceDXG) {
      toast.error("Your bid price is out of your balance");
      return;
    }

    onSubmit(data.bidPrice);
  };

  return (
    <Modal show={show} onHide={handleClose} centered backdrop="static">
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Modal.Body>
          <Modal.Title className="mb-4">Place a Bid</Modal.Title>
          <Row className="justify-content-between">
            <Col>
              <Input
                placeholder="Your Bid Price"
                type="number"
                step="any"
                disabled={loading}
                register={{
                  ...register("bidPrice", {
                    required: {
                      message: "Bid Price is required ",
                      value: true,
                    },
                    validate: (price: number) =>
                      price > 0 || "Bid Price is not valid !",
                  }),
                }}
                errors={errors}
              />
            </Col>
          </Row>

          <div className="infoWrapper">
            <Row>
              <Col>Your Wallet Balance</Col>
              <Col>{parseFloat(balanceDXG).toFixed(0)} DEXI</Col>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={loading}>Place Bid</Button>
          <LinkButton onClick={handleClose}>Close</LinkButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default PlaceBid;
