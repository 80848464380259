import React from "react";
import { FaChevronDown } from "react-icons/fa";
import { Accordion } from "react-bootstrap";
import { Form, Input, Checkbox } from "antd";
import { Filter, FilterCard, Types } from "./Filter.styled";
import categories from "./categories.json";

const Filters = ({ filters, isMarketPlace }) => {
  const {
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,
    category,
    setCategory,
    subCategory,
    setSubCategory,
    level,
    setLevel,
    availability,
    setAvailability,
  } = filters;

  const availOptions = [
    { label: "For Sale", value: true },
    { label: "Sold / Not Available", value: false },
  ];

  return (
    <Filter className={isMarketPlace ? "creatorhub" : "gamehub"}>
      <h5>Filters</h5>
      <div>
        <Accordion>
          {!isMarketPlace && (
            <FilterCard
              className={isMarketPlace ? "creatorhub" : "gamehub"}
            >
              <Accordion.Header className="accordionBtn">
                Type
                <span>
                  <FaChevronDown />
                </span>
              </Accordion.Header>
              <Accordion.Body className="accordionBody">
                {categories.map((item) => (
                  <Types className="armor" key={item.value}>
                    <Checkbox
                      className="checkedBox"
                      checked={category === item.value}
                      onChange={() => {
                        if (category === item.value) {
                          setCategory("");
                          setSubCategory("");
                        } else {
                          setCategory(item.value);
                          if (
                            item.dropdown &&
                            item.dropdown.find(
                              (ite) => ite.value !== subCategory
                            )
                          )
                            setSubCategory("");
                        }
                      }}
                    >
                      {item.dropdown ? (
                        <Accordion>
                          <Accordion.Item
                            eventKey="0"
                            className="typesDropdown"
                          >
                            <Accordion.Header className="typesBtn">
                              {item.label}
                              <span>
                                <FaChevronDown />
                              </span>
                            </Accordion.Header>
                            <Accordion.Body className="typesBody">
                              {item.dropdown.map((ite) => (
                                <div key={ite.value}>
                                  <Checkbox
                                    className="checkedBox"
                                    checked={subCategory === ite.value}
                                    onChange={() => {
                                      if (subCategory === ite.value)
                                        setSubCategory("");
                                      else {
                                        if (category !== item.value)
                                        setCategory(item.value);
                                        setSubCategory(ite.value);
                                      }
                                    }}
                                  >
                                    {ite.label}
                                  </Checkbox>
                                </div>
                              ))}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      ) : (
                        item.label
                      )}
                    </Checkbox>
                  </Types>
                ))}
              </Accordion.Body>
            </FilterCard>
          )}
          {!isMarketPlace && (
            <Accordion>
              <FilterCard
                className={isMarketPlace ? "creatorhub" : "gamehub"}
              >
                <Accordion.Header className="accordionBtn">
                  Level
                  <span>
                    <FaChevronDown />
                  </span>
                </Accordion.Header>
                <Accordion.Body className="accordionBody">
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
                    <div className="armar" key={item}>
                      <Checkbox
                        className="checkedBox"
                        checked={level === item}
                        onChange={() =>
                          level === item ? setLevel("") : setLevel(item)
                        }
                      >
                        Level {item}
                      </Checkbox>
                    </div>
                  ))}
                </Accordion.Body>
              </FilterCard>
            </Accordion>
          )}

          <Accordion>
            <FilterCard
              className={isMarketPlace ? "creatorhub" : "gamehub"}
            >
              <Accordion.Header className="accordionBtn">
                Price
                <span>
                  <FaChevronDown />
                </span>
              </Accordion.Header>
              <Accordion.Body className="accordionBody">
                <Form>
                  <Form.Item label="Min">
                    <Input
                      className="priceInput"
                      value={minPrice}
                      onChange={(e) => setMinPrice(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item label="Max">
                    <Input
                      className="priceInput"
                      value={maxPrice}
                      onChange={(e) => setMaxPrice(e.target.value)}
                    />
                  </Form.Item>
                </Form>
              </Accordion.Body>
            </FilterCard>
          </Accordion>
          <Accordion>
            <FilterCard
              className={isMarketPlace ? "creatorhub" : "gamehub"}
            >
              <Accordion.Header className="accordionBtn">
                Status
                <span>
                  <FaChevronDown />
                </span>
              </Accordion.Header>
              <Accordion.Body className="accordionBody">
                {availOptions.map((item) => (
                  <div className="armar" key={item.label}>
                    <Checkbox
                      checked={availability === item.value}
                      className="checkedBox"
                      onChange={() =>
                        availability === item.value
                          ? setAvailability("-1")
                          : setAvailability(item.value)
                      }
                    >
                      {item.label}
                    </Checkbox>
                  </div>
                ))}
              </Accordion.Body>
            </FilterCard>
          </Accordion>
        </Accordion>
      </div>
    </Filter>
  );
};

export default Filters;
