import { useCallback } from "react";
import debounce from 'lodash/debounce';
import Layout from "components/layout";
import SideBar from "components/marketplace/SideBar";
import { ResponsiveFilter } from "components/ResponsiveFilter";
import NewProductCard from "components/card/newProductCard";
import { Col, Row, Spinner, Image } from "react-bootstrap";
import { CollectionWrapper, Container } from "./styled";
import { useEffect, useState } from "react";
import { NFTObjectData, useQuery } from "hooks";
import { uniqBy } from "lodash";
import { searchNfts, searchByWord } from "hooks/useApi";
import { Input } from "antd";
import {returnCDNImage} from "utils/cdnHelper";
import { useMount, useUpdateEffect } from "react-use";
import { useInView } from "react-intersection-observer";
import { Collection, Profile } from "store/types";
import { H4, H5 } from "components/typography";
import { Link, useLocation } from "react-router-dom";

const LIST_SIZE = 16;

const INITIAL_FILTER_PARAMS = {
  minPrice: "",
  maxPrice: "",
  category: "",
  subCategory: "",
  level: "",
  sortBy: "date|desc",
  availability: "-1",
}

const Explore = () => {
  const query = useQuery();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [start, setStart] = useState(0);
  const [total, setTotal] = useState(0);
  const { ref, inView } = useInView();
  const [NFTListData, setNFTListData] = useState<NFTObjectData[]>([]);
  const [profilesListData, setProfilesListData] = useState<Profile[]>([]);
  const [collectionsListData, setCollectionsListData] = useState<Collection[]>([]);
  const [searchFieldValue, setSearchFieldValue] = useState(query.get("q"));
  const [searchQuery, setSearchQuery] = useState(query.get("q"));
  const [filterParams, setFilterParams] = useState(INITIAL_FILTER_PARAMS);
  const isCreatorHub = location.pathname.includes("creator") ? true : false;

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);

    debounce(() => setSearchFieldValue(e.target.value), 1000)();
  }

  const handleSearch = useCallback(async () => {
    try {
      setLoading(true)
      const response = await searchByWord({ query: searchFieldValue });
      const nfts = response.nfts || [];

      nfts.forEach((nft: NFTObjectData) => {
        if (nft.assetType && nft.assetUrl) {
          nft.cdnAssetUrl = returnCDNImage(nft.assetType, nft.assetUrl);
          nft.cdnBannerImage = returnCDNImage(nft.assetType, nft.bannerImage);
        }
      });

      setNFTListData(nfts);
      setProfilesListData(response.profiles || []);
      setCollectionsListData(response.collections || []);
    } catch (e) {
      // e
    } finally {
      setLoading(false)
    }
  }, [searchFieldValue]);

  const handleFilterResults = useCallback(async (values, offset = 0) => {
    setCollectionsListData([]);
    setProfilesListData([]);
    setCollectionsListData([]);

    try {
      setLoading(true)
      setFilterParams(values);
      const response = await searchNfts(values, offset);
  
      if (response) {
        setTotal(response.searchCount);
        response.results.forEach((nft: NFTObjectData) => {
          nft.cdnAssetUrl = returnCDNImage(nft.assetType, nft.assetUrl);
          nft.cdnBannerImage = returnCDNImage(nft.assetType, nft.bannerImage);
        });
  
        if (offset)
          setNFTListData(uniqBy([...NFTListData, ...response.results], '_id'));
        else {
          setNFTListData(response.results);
        }
      }
    } catch (e) {
      // e
    } finally {
      setLoading(false)
    }
  }, [NFTListData]);

  useEffect(() => {
    if (searchFieldValue) {
      handleSearch();
    } else {
      handleFilterResults(filterParams, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFieldValue]);

  useUpdateEffect(() => {
    if (inView && !loading && total > start + LIST_SIZE && !searchFieldValue) {
      setStart(start + LIST_SIZE);
      handleFilterResults(filterParams, start + LIST_SIZE)
    };
  }, [inView]);

  useMount(() => handleFilterResults(filterParams, 0));

  return (
    <Layout>
      <div className="d-flex justify-content-center py-4 pr-4">
        <ResponsiveFilter searchFilter={handleFilterResults} isMarketPlace={false} />
        <SideBar searchFilter={handleFilterResults} isMarketPlace={false} />

        <Container fluid>
          <Input
            placeholder="Search users and collections..."
            value={searchQuery}
            onChange={handleSearchChange}
            style={{ marginLeft: 15 }}
          />

          {profilesListData.length > 0 && (
            <Row className="product-container">
              <H4>Users</H4>
              {profilesListData.map((profile: Profile, index: number) =>
                <Link to={`storefront/${profile.customUrl || profile.walletAddress || profile._id}`} key={index}>
                  <H5 style={{ cursor: "pointer" }}>
                    <Image
                        rounded
                        width={60}
                        height={60}
                        style={{ marginRight: 8 }}
                        src={
                          profile.userAvatarUrl || isCreatorHub
                                ? "/logos/CreatorHubLogo.png"
                                : "/images/game.svg"
                        }
                    />
                    {profile.displayName}
                  </H5>
                </Link>
              )}
            </Row>
          )}

          {collectionsListData.length > 0 && (
            <Row className="product-container">
              <H4>Collections</H4>
              {collectionsListData.map((collection: Collection, index: number) =>
                <Col md={12} lg={6} xl={4} xxl={3} key={index}>
                  <Link to={`/collection/${collection.collectionSlug}`} key={index}>
                    <CollectionWrapper>
                      <Image
                        draggable
                        width="100%"
                        height="200px"
                        style={{ objectFit: 'cover' }}
                        src={
                          collection.NFTs && collection.NFTs[0] ? returnCDNImage(collection.NFTs[0].assetType, collection.NFTs[0].bannerImage) : isCreatorHub
                                ? "/logos/CreatorHubLogo.png"
                                : "/images/game.svg"
                        }
                      />
                      <H5 style={{ margin: '12px 16px' }}>
                        {collection.collectionName || 'Unknown collection'}
                      </H5>
                    </CollectionWrapper>
                  </Link>
                </Col>
              )}
            </Row>
          )}

          {NFTListData.length > 0 && (
            <Row className="product-container">
              <H4>NFTs</H4>
              {NFTListData.map((product: NFTObjectData, index: number) =>
                <Col md={12} lg={6} xl={4} xxl={3} key={index} ref={index + 1 === NFTListData.length ? ref : null} >
                  <NewProductCard productObj={product} />
                </Col>
              )}
            </Row>
          )}

          <Row className="justify-content-md-center">
            {loading && (
              <Spinner animation="border" style={{color: "rgb(47, 0, 54)"}} />
            )}
          </Row>
        </Container>
      </div>
    </Layout>
  );
}

export default Explore;
