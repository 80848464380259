import Header from "components/header";
import Footer from "components/footer";

import { Background, Content } from "./styled";

type LayoutProps = {
  noHeader?: boolean;
  children: JSX.Element | JSX.Element[];
}

const Layout = ({ noHeader, children }: LayoutProps) => (
  <div >
    {!noHeader && <Header />}
    <Background src="/images/bg.png" />
    <Content noHeader={noHeader}>{children}</Content>
    <Footer />
  </div>
);

export default Layout;
