import { InputGroup } from "react-bootstrap";
import styled from "styled-components";

export const InputGroupStyled = styled(InputGroup)`
  flex-direction: column;
  margin-bottom: 30px;
`;

export const InputStyled = styled.select`
  border: none;
  width: 100%;
  background: transparent;
  padding: 0.9em 1rem;

  &:focus {
    outline: 0;
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  display: inline-flex;
  background: rgba(255, 255, 255, 0.1);
  border: ${({ theme }) => "1px solid transparent"};
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px,
    rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  border-radius: 5px !important;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.7;
  box-sizing: border-box;
  appearance: none;
  transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

  &.is-invalid {
    border: ${({ theme }) => "1px solid #e86557 !important"};
  }

  &:not(.disabled):hover {
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
      rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  }

  &:not(.disabled):focus-within {
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
      rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  }

  &:not(.disabled):active {
    box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px,
      rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
  }

  span {
    border-radius: 0;
    background: transparent;
    border: unset;
    padding: 0;
  }

  .feedback {
    position: absolute;
    right: 8px;
    top: 0;
    z-index: 11;
    margin-top: 2px;
    font-size: 14px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0;
    color: #e86557;
    transition: 0.3s;
    text-align: end;
  }
`;
