import CardsMedia from "marketplace/components/mediaBox";
import { NFTObjectData } from "marketplace/hooks/useApi";
import { Card as C, Image } from "react-bootstrap";
import { useHistory } from "react-router";
import { useProfileForWallet } from "store/hooks";
import styled from "styled-components";
import { getCreatorPrefixByContract, getNftChainByContract } from "utils";

interface PropsType {
  className?: string;
  disabled?: boolean;
  productObj: NFTObjectData;
  isRecent?: boolean;
  dontShowSold?: boolean;
}

const Card = styled(C)`
  border-radius: 40px;
  margin-bottom: 20px;
  overflow: hidden;
  cursor: pointer;
  background-color: rgba(255,255,255,0.1);

  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px,
    rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;

  &:hover {
    text-decoration: none;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
      rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  }

  &:focus {
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
      rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  }

  &:active {
    box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px,
      rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
  }

  .soldWord {
    position: absolute;
    z-index: 999;
    top: 24px;
    left: 29px;
  }
  .tag {
    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;

    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: ${({ theme }) => theme.color[2]};
    background: #5142fc;
    border-radius: 10px;
    padding: 0.2em 0.5em;
  }


  .card-body {
    padding: 0 25px 1rem;
    position: relative;
    margin-top: -60px;

    .card-title {
      margin-bottom: 0;
      font-weight: bold;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      color:white;
    }
    .product-creator__avatar-wrapper {
      width: 82px;
      height: 82px;
      margin: auto;
      .product-creator__avatar {
        width: 100%;
        height: 100%;
        border: 5px solid #fff;
        object-fit: cover;
      }
    }
    .card-text {
      font-size: 14px;
    }
  }
`;

const ProductCard = ({
  className = "",
  productObj,
  disabled,
  isRecent = false,
  dontShowSold = false,
}: PropsType) => {
  let history = useHistory();
  const creator = useProfileForWallet(productObj?.initialCreatorAddress);

  return (
    <Card
      className={className}
      onClick={() => {
        const detailUrl = `${getCreatorPrefixByContract(productObj?.contractAddress)}/product/${getNftChainByContract(productObj?.contractAddress)}/${productObj?.tokenID}`;
        !disabled && history.push(detailUrl);
      }}
    >
      {dontShowSold ? (
        ""
      ) : !productObj?.listed ? (
        <div className="soldWord">
          <div className="tag">SOLD</div>{" "}
        </div>
      ) : (
        ""
      )}

      <CardsMedia
        controls
        assetType={productObj?.assetType}
        assetUrl={productObj?.assetUrl}
        bannerImage={productObj?.bannerImage}
      />
      {/* <Card.Img variant="top" src={productObj?.image} /> */}
      <Card.Body>
        <div className="product-creator__avatar-wrapper">
          {isRecent || !creator?.profile?.userAvatarUrl ? (
            <div></div>
          ) : (
            <Image
              className="product-creator__avatar"
              src={
                creator?.profile?.userAvatarUrl || "images/default-profile.png"
              }
              roundedCircle
            />
          )}
        </div>
        <Card.Title>{productObj?.name}</Card.Title>
        <Card.Text>
          {productObj?.price ? `${productObj?.price} DEXI` : ""}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default ProductCard;
