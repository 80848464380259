import Button from "components/button";
import LinkButton from "components/button/link";
import Input from "components/form/input";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import { Modal } from "./index.styled";

export default function Auction({ onClose, onAuctionStart }) {
  const [show, setShow] = useState(true);
  const [startTime, setNFTAuctionStartTime] = useState(new Date());
  const [endTime, setNFTAuctionEndTime] = useState(new Date());
  const [minBidPrice, setNFTMinBidPrice] = useState(0);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  const onFormSubmit = async (data) => {
    // console.log(data);

    if (endTime <= new Date() || endTime <= startTime) {
      toast.error(
        "Auction End Time should be later than current time and start time"
      );
      return;
    }

    if (minBidPrice <= 0) {
      toast.error("Minimum bid price should be over than 0");
      return;
    }

    onAuctionStart(minBidPrice, startTime, endTime);
  };

  return (
    <Modal show={show} onHide={handleClose} centered backdrop="static">
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Modal.Body>
          <Modal.Title className="mb-4">Auctions</Modal.Title>

          <Input
            placeholder="Minimum Bid Price"
            type="number"
            step="any"
            register={{
              ...register("minBidPrice", {
                required: {
                  message: "Minimum Bid Price is required ",
                  value: true,
                },
                validate: (price: number) =>
                  price > 0 || "Minimum Bid Price is not valid !",
              }),
            }}
            errors={errors}
          />
          <Row className="justify-content-between">
            <Col>
              <Datetime
                value={startTime}
                onChange={(dateTime) => {
                  if (typeof dateTime !== "string") {
                    setNFTAuctionStartTime(dateTime.toDate());
                  }
                }}
              />
            </Col>
            <Col>
              <Datetime
                value={endTime}
                onChange={(dateTime) => {
                  if (typeof dateTime !== "string") {
                    setNFTAuctionEndTime(dateTime.toDate());
                  }
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button>Save</Button>
          <LinkButton onClick={handleClose}>Close</LinkButton>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
