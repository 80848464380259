import styled from "styled-components";
import { Card as BootstrapCard } from "react-bootstrap";

export const Card = styled(BootstrapCard)`
  border-radius: 40px;
  margin-bottom: 20px;
  overflow: hidden;
  cursor: pointer;
  background-color: #183b56;

  .soldWord {
    position: absolute;
    z-index: 999;
    top: 24px;
    left: 29px;
  }

  .tag {
    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;

    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #ffffff;
    background: #5142fc;
    border-radius: 10px;
    padding: 0.2em 0.5em;
  }

  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px,
    rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;

  &:hover {
    text-decoration: none;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
      rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  }

  &:focus {
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
      rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  }

  &:active {
    box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px,
      rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
  }

  .card-body {
    padding: 0 25px 1rem;
    position: relative;
    margin-top: -60px;

    .card-title {
      margin-bottom: 0;
      font-weight: bold;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: normal;
      color:white
    }
    .product-creator__avatar-wrapper {
      width: 82px;
      height: 82px;
      margin: auto;
      .product-creator__avatar {
        width: 100%;
        height: 100%;
        border: 5px solid #fff;
        object-fit: cover;
      }
    }
    .card-text {
      font-size: 14px;
      color:white;
    }
  }
`;
