import {Collection} from "store/types";
import axios from "axios";
import {baseApiUrl} from "utils";
import config from "../../environment";
import APIFactory from "../../utils/api";

const API_URL = config.REACT_APP_API_MARKETPLACE;

export const getProfileCollections = async (userId: string, token: string): Promise<Collection[]> => {
  try {
    const profileUrl = `${baseApiUrl}/v2/users/profile/${userId}`;

    const [profileResponse] = await Promise.all([
      axios({
        method: "get",
        url: profileUrl,
      }),
    ]);

    const walletAddress = profileResponse.data?.data?.userProfile?.walletAddress;
    const getPath = `/collections/${walletAddress}`

    const profileCollectionsResponse: any = await APIFactory(API_URL).get(getPath, {
      headers: {Authorization: `Bearer ${token}`},
    });

    return profileCollectionsResponse.data;
  } catch (error) {
    return null;
  }
};
