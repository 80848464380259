import Button from "components/button";
import LinkButton from "components/button/link";
import Input from "components/form/input";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Datetime from "react-datetime";
import { useForm } from "react-hook-form";
import { Form, Modal } from "./index.styled";

const CreateAuction = ({ onClose, onSubmit }) => {
  const [show, setShow] = useState(true);
  const [startTime, setNFTAuctionStartTime] = useState(new Date());
  const [endTime, setNFTAuctionEndTime] = useState(new Date());

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  const onFormSubmit = async (data) =>
    onSubmit(data.minBidPrice, Math.round(startTime.getTime() / 1000), Math.round(endTime.getTime() / 1000));

  return (
    <Modal show={show} onHide={handleClose} centered backdrop="static">
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Modal.Body>
          <Modal.Title className="mb-4">Sell</Modal.Title>

          <Input
            placeholder="Minimum bid price"
            type="number"
            step="any"
            register={{
              ...register("minBidPrice", {
                required: { message: "Minimum bid price is required ", value: true },
                validate: (price: number) =>
                  price > 0 || "Minimum bid price is not valid!",
              }),
            }}
            errors={errors}
          />

          <Row>
            <Col>
              <label className="mb-2">Starting Date</label>
              <Datetime
                value={startTime}
                onChange={(dateTime) => {
                  if (typeof dateTime !== "string") {
                    setNFTAuctionStartTime(dateTime.toDate());
                  }
                }}
              />
            </Col>
            <Col>
              <label className="mb-2">End Date</label>
              <Datetime
                value={endTime}
                onChange={(dateTime) => {
                  if (typeof dateTime !== "string") {
                    setNFTAuctionEndTime(dateTime.toDate());
                  }
                }}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button>Create auction</Button>
          <LinkButton onClick={handleClose}>Close</LinkButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default CreateAuction;
