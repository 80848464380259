import { Dropdown as DootstrapDropdown } from "react-bootstrap";
import styled from "styled-components";

export const Dropdown = styled(DootstrapDropdown)`
  &.show {
    .dropdown-toggle {
      color: ${({ theme }) => theme.color[2]};
      border: none;
    }
  }

  .dropdown-menu {
    border: none;
    padding: 0;
    margin-top: 0 !important;
    a &.show {
      display: flex;
      position: relative;
      justify-content: flex-start;
      flex-direction: column;
    }

    .dropdown-item {
      color: ${({ theme }) => theme.color[0]};
      position: relative;
      flex: 1;
      padding: 10px 20px 10px 36px;
      width: auto;

      &:first-child {
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }

      &:last-child {
        border-top-right-radius: 0.25rem;
        border-bottom-right-radius: 0.25rem;
      }

      &:hover {
        background: transparent;
        &:after {
          content: "";
          border-radius: 50%;
          width: 12px;
          height: 12px;
          background: #59dabc;
          position: absolute;
          left: 11px;
          top: calc(50% - 6px);
        }
      }

      &.active,
      &:active {
        color: ${({ theme }) => theme.color[0]};
        background-color: ${({ theme }) => theme.primary[0]};

        &:after {
          content: "";
          border-radius: 50%;
          width: 12px;
          height: 12px;
          background: #59dabc;
          position: absolute;
          left: 11px;
          top: calc(50% - 6px);
        }
      }

      &:before {
        content: "";
        border-radius: 50%;
        width: 22px;
        height: 22px;
        background: ${({ theme }) => theme.background[0]};
        position: absolute;
        left: 6px;
        top: calc(50% - 11px);
      }
    }
  }

  @media (max-width: 576px) {
    .dropdown-menu {
      width: 100%;

      &.show {
        display: inline-block;
      }

      .dropdown-item {
        &.active,
        &:active {
          color: #fff;
          text-decoration: none;
          background-color: ${({ theme }) => theme.primary[1]};
        }
        &:before {
          display: none;
        }
        &:after {
          display: none;
        }
      }
    }
  }
`;

export const ToggleBtn = styled(DootstrapDropdown.Toggle)`
  display: inline-block;
  line-height: 1.5;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  user-select: none;
  transition: 0.25s ease-in-out;
  min-height: 42px;
  min-width: 200px;
  font-size: 18px;
  font-weight: normal;
  font-family: "Segoe UI", sans-serif;
  border-radius: 20px;
  padding: 5px 10px 5px 10px;
  border: none;
  color: ${({ theme }) => theme.color[2]};
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px,
    rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;

  cursor: ${({ disabled = false }) => (disabled ? "default" : "pointer")};
  margin-bottom: 1em !important;

  &:hover {
    color: ${({ theme }) => theme.color[2]};
    background-color: ${({ theme }) => theme.primary[0]};
    border: none;
    text-decoration: none;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
      rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
  }

  &:focus {
    color: ${({ theme }) => theme.color[2]};
    background-color: ${({ theme }) => theme.primary[0]};
    border: none;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
      rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px !important;
  }

  &:active {
    box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px,
      rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
  }

  &:after {
    margin-left: 15px;
    vertical-align: 3px;
  }

  @media (max-width: 576px) {
    width: 100%;
  }
`;
