import styled from "styled-components";
import Navbar from "react-bootstrap/Navbar";

export const StyledNav = styled(Navbar)`
  background-color: #a0a0a0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2rem;
  height: 98px;
  border-bottom: 5px solid #c7833f;
  position: fixed;
  right: 0;
  left: 0;
  top: 0;
  z-index: 999;

  .navbar-brand {
    display: flex;
    align-items: center;
    height: 90px;
    width: 204px;
  }

  .mainnav {
    flex: 1;
    /* display: flex; */
    justify-content: space-between;
  }

  @media (max-width: 700px) {
    .navbar-collapse {
      background: rgb(160, 160, 160);
      margin-left: -3em;
    }

    .navbar-brand {
      width: 240px;
    }
  }

  .mpLogo {
    width: 40px;
    margin-right: 5px;
  }
  .gameHub {
    width: 242px;
    height: 101px;
    color: #ffffff;
    background-color: #00dcd4;
    font-size: 20px;
    border-radius: 0px;
    font-weight: 600;
    position: relative;
    justify-content: center;
    align-items: center;
    transition: 300ms;
    border-bottom: 5px solid #ffffff;
    display: flex;
    align-items: center;
    flex-direction: row;

    &:hover {
      color: #00dcd4;
      background-color: #ffffff;
      border-bottom: 5px solid #00dcd4;
      // &::after {
      //   background-color: #00dcd4 !important;
      // }
    }
    @media (max-width: 700px) {
      & {
        width: 50%;
      }
    }
  }
  .creatorHub {
    width: 242px;
    height: 101px;
    color: #ffffff;
    background-color: #fc036f;
    font-size: 20px;
    display: flex;
    border-radius: 0px;
    font-weight: 600;
    position: relative;
    justify-content: center;
    align-items: center;
    border-bottom: 5px solid #ffffff;

    transition: 300ms;
    display: flex;

    align-items: center;
    flex-direction: row;
    &:hover {
      color: #02c573;
      background-color: #ffffff;
      border-bottom: 5px solid #02c573;
    }

    @media (max-width: 700px) {
      & {
        width: 50%;
      }
    }
  }
`;
export const NavContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .navbar-nav {
    height: 101px;
    background-color: #eaeaea;
    font-size: 20px;
    display: flex;
    border-radius: 0px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: 300ms;
    border-bottom: 5px solid #a0a0a0;
    margin-right: 0 !important;
    a {
      color: #4e4e4e !important;
    }
  }

  .walletConnect {
    color: white;
    padding: 10px;
    border-radius: 21px;
    cursor: pointer;
  }

  @media (max-width: 700px) {
    & {
      width: auto;
      flex-direction: column;
      padding: 1em;
    }
  }
`;
export const Logo = styled.div`
  img {
    max-width: 100%;
    height: auto;
  }
`;
export const NavItem = styled.div`
  text-decoration: none;
  color: #ffffff;
  font-size: 20px;
  padding: 0.2rem 1rem;
  font-weight: 300;
  border-bottom: 5px solid transparent;
  &:hover {
    color: #ffffff;
  }

  a {
    color: white;
  }
`;
export const NavSearch = styled.div`
  input {
    width: 381px;
    height: 52px;
    background-color: #ffffff;
    border-radius: 30px;
    font-size: 20px;
    &::placeholder {
      text-align: center;
    }
  }
`;
export const LoginBtn = styled.div`
  width: 271px;
  height: 101px;
  color: #4e4e4e;
  background-color: #eaeaea;
  font-size: 20px;
  display: flex;
  border-radius: 0px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: 300ms;
  border-bottom: 5px solid #a0a0a0;
  cursor: pointer;
`;
