import styled from "styled-components";

export const WalletElements = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 60px 0px;

  img {
    width: 120px;
  }

  span {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
