import styled from "styled-components";

export const Content = styled.div<{ noHeader: boolean }>`
  min-height: calc(100vh - 80px);
  padding-top: ${({ noHeader }) => noHeader ? '0' : '100px'};
`;

export const Background = styled.img`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh !important;
  z-index: -1;
  object-fit: cover;
`;
