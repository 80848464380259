import styled from "styled-components";
import { Container as C } from "react-bootstrap";

export const Container = styled(C)`
  padding: 4.5em 2em;
  min-height: 87vh;

  @media (max-width: 768px) {
    & > .row {
      flex-direction: column;
      overflow: auto;
    }
    & > .row > div:first-child {
      width: 100% !important;
      padding: 0;
    }
    & > .row > div:nth-child(2) {
      flex: 0 0 auto;
      width: 100% !important;
      padding: 0;
    }
  }
`;

export const Button = styled.button`
  margin-top: 12px;
  background: #00e1cd;
  color: white;
  font-weight: 600;
  font-size: 23px;
  border: none;
  outline: none;
  height: 52px;
  padding: 0 20px;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
