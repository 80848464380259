import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    ProfileState,
    ProfileResponse,
    Profile,
    GameProfile,
    Collection,
} from "store/types";
import type {AppDispatch} from "store";
import getProfile from "./getProfile";
import {getLikedNfts} from "hooks";
import {getProfileCollections} from "./getProfileCollections";

const initialState: ProfileState = {
    isInitialized: false,
    isLoading: true,
    userProfile: null,
    userNfts: {},
    userCollections: null,
};

export const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        profileFetchStart: (state) => {
            state.isLoading = true;
        },
        profileFetchSucceeded: (state, action: PayloadAction<ProfileResponse>) => {
            const profile = action.payload;

            return {
                isInitialized: true,
                isLoading: false,
                userProfile: profile.userProfile,
                userNfts: profile.userNfts,
                userCollections: profile.userCollections,
            };
        },
        profileUpdate: (
            state,
            action: PayloadAction<Profile & { knightsGameProfile?: GameProfile }>
        ) => {
            const profile = action.payload;

            return {
                ...state,
                isInitialized: true,
                isLoading: false,
                userProfile: profile,
            };
        },
        profileFetchFailed: (state) => {
            state.isLoading = false;
            state.isInitialized = true;
        },
        profileClear: () => ({
            ...initialState,
            isLoading: false,
        }),
        profileCollectionsUpdate: (state, action: PayloadAction<Collection[]>) => ({
            ...state,
            userCollections: action.payload,
        }),
    },
});

export const {
    profileFetchStart,
    profileFetchSucceeded,
    profileFetchFailed,
    profileUpdate,
    profileClear,
    profileCollectionsUpdate,
} = profileSlice.actions;

export const fetchProfile = (userId: string) => async (dispatch: AppDispatch) => {
    try {

        dispatch(profileFetchStart());
        const [likedNfts, profileData] = await Promise.all([
            getLikedNfts(userId),
            getProfile(userId),
        ]);

        dispatch(
            profileFetchSucceeded({
                ...profileData
            })
        );
    } catch (error) {
        dispatch(profileFetchFailed());
    }
};

export const fetchProfileCollections = (userId: string, token: string) => async (dispatch: AppDispatch) => {
    try {
        dispatch(profileFetchStart());
        const profileCollectionsData = await getProfileCollections(userId, token);
        dispatch(
            profileCollectionsUpdate(profileCollectionsData)
        );
    } catch (error) {
        dispatch(profileFetchFailed());
    }
};

export default profileSlice.reducer;
