import random from "lodash/random";
import { config } from "../environment";

// Array of available nodes to connect to
export const nodes = [
  config.REACT_APP_NODE_1,
  config.REACT_APP_NODE_2,
  config.REACT_APP_NODE_3,
];
// For Binance

const getNodeUrl = () => {
  const randomIndex = random(0, nodes.length - 1);
  return nodes[randomIndex];
};

export default getNodeUrl;
