import axios from "axios";
import { config } from "../environment";

const ApiRequest = async function (options) {
  const onSuccess = (response) => {
    if (response.status !== 200) {
      //continue without any action if no data found

      // Automatically signout user if accessToken is no longer valid
    } else {
      return response;
    }
  };
  const onError = function (error) {
    return Promise.reject(error.response || error.message);
  };

  return axios({
    baseURL: config.REACT_APP_API_URL,
    ...options,
    headers: {
      "Content-Type": "application/json",
      ...options.headers,
    },
  })
    .then(onSuccess)
    .catch(onError);
};

export default ApiRequest;
