import { NetworkConnector } from "@web3-react/network-connector";
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { config } from "../environment";

import { currentNetwork } from "./index";
import getNodeUrl from "./getRpcUrl";

export const injectedConnector = new InjectedConnector({});

export const networkConnector = new NetworkConnector({
  urls: { [Number(config.REACT_APP_NETWORK_ID)]: getNodeUrl() },
  defaultChainId: parseInt(currentNetwork),
});

export const walletConnector = new WalletConnectConnector({
  rpc: { [Number(config.REACT_APP_NETWORK_ID)]: getNodeUrl() },
  chainId: Number(config.REACT_APP_NETWORK_ID),
});
