import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import profileReducer from "./profile";
import profileListReducer from "./profileList";
import pricesReducer from "./prices";

import main from "./reducers/main";
import auth from "./reducers/auth";

const store = configureStore({
  devTools: process.env.REACT_APP_STAGE !== "live",
  reducer: {
    prices: pricesReducer,
    profile: profileReducer,
    profileList: profileListReducer,
    main: main,
    auth: auth,
  },
});

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
