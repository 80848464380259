import styled from "styled-components";

export const CountdownWrapper = styled.div`
  display: flex;
  margin-bottom: 20px;

  .flip-countdown {
    margin: 0;
  }

  .flip-countdown.theme-light {
    --text-color: #02c573;
    --text-bottom-color: #02c573;
    --background-color: #f7f7f7;
    --background-bottom-color: #ffffff;
  }
`;
