import { Row, Col } from "react-bootstrap";
import Layout from "components/layout";
import { Container, Button } from "./collection.styled";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import {searchByCollectionSlug} from "marketplace/hooks/useApi";
import { Collection as TCollection } from "store/types";
import { H4, H5, P } from "components/typography";
import NewProductCard from "components/card/newProductCard";
import { returnCDNImage } from "utils/cdnHelper";

export const Collection = () => {
	const location = useLocation();
	const history = useHistory();
	const collectionSlug = location.pathname.split("/").reverse()[0];
	const [collection, setCollection] = useState<TCollection>();

	useEffect(() => {
		const fetchCollectionData = async () => {
			const response = await searchByCollectionSlug({collectionSlug});
			setCollection(response)
		};
		fetchCollectionData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<Layout>
			<Container fluid>
				<Row>
					<Col md={2}>
						<Button onClick={() => history.goBack()}>Back</Button>
					</Col>
					<Col md={8}>
						{collection && (
							<>
								<H4>{collection.collectionName}</H4>
								<H5>{collection.description}</H5>
								{collection.NFTs.length ? (
									<Row>
											{collection.NFTs.map((product: any, index: number) => (
													<Col md={12} lg={6} xl={6} xxl={3} key={index}>
															<NewProductCard
																	productObj={{
																		...product,
																		cdnAssetUrl: returnCDNImage(product.assetType, product.assetUrl),
          													cdnBannerImage: returnCDNImage(product.assetType, product.bannerImage),
																	}}
																	key={index}
															/>
													</Col>
											))}
									</Row>
								) : (
									<P>This collection has no NFT yet</P>
								)}
							</>
						)}
					</Col>
				</Row>
			</Container>
		</Layout>
	);
}
