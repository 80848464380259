import auth from "./reducers/auth";
import main from "./reducers/main";

export const UPDATE_AUTH = "UPDATE_AUTH";

/* Repositories */
export const GET_ALL_REPOSITORIES_REQUEST = "GET_ALL_REPOSITORIES_REQUEST";
export const GET_ALL_REPOSITORIES_SUCCESS = "GET_ALL_REPOSITORIES_SUCCESS";
export const GET_ALL_REPOSITORIES_FAILURE = "GET_ALL_REPOSITORIES_FAILURE";
/* Repositories */

/* Contributors */
export const GET_ALL_CONTRIBUTORS_REQUEST = "GET_ALL_CONTRIBUTORS_REQUEST";
export const GET_ALL_CONTRIBUTORS_SUCCESS = "GET_ALL_CONTRIBUTORS_SUCCESS";
export const GET_ALL_CONTRIBUTORS_FAILURE = "GET_ALL_CONTRIBUTORS_FAILURE";
/* Contributors */

export const SET_VERSION = "SET_VERSION";

export interface ReducerState {
  auth: ReturnType<typeof auth>;
  main: ReturnType<typeof main>;
}
