import styled from "styled-components";

interface Props {
  varient?: "primary";
  disabled?: boolean;
}
interface BtnPropsType extends Props {
  className?: string;
  children: any;
  onClick?: () => void;
  varient?: "primary";
  disabled?: boolean;
}

export const Btn = styled.button<Props>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  border-radius: 2em;
  background-color: #fff;
  border-color: #5142fc;
  padding: 0.5em 2em;
  background-color: #5142fc;
  color: #fff;
  max-width: 250px;
  min-height: 42px;
  min-width: 155px;

  &:hover {
    color: #fff;
  }
`;

const Button = ({
  className = "",
  children,
  onClick,
  ...props
}: BtnPropsType) => {
  return (
    <Btn
      className={`btn ${className}`}
      onClick={onClick && onClick}
      type="submit"
      {...props}
    >
      {children}
    </Btn>
  );
};

export default Button;
