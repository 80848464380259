const data = [
  {
    name: 'Help! The NFT I purchased is missing',
    explanation:
      'Please provide the ID of the NFT purchased, the transaction hash from BscScan showing the purchase was completed, and the wallet you are using to store your collectibles.',
  },
  {
    name: 'Emporium Suggestions',
    explanation:
      "We are striving for continuous improvement and value user feedback. Please let us know what would improve our community's experience in the DexiMarketplace.",
  },
  {
    name: 'Other Support',
    explanation: 'Enter your comments or questions here and a member of our support team will review.',
  },
];

export default data;
