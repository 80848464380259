import moment from 'moment'
import {currentNetwork, getContractAddressByChainName, truncateWalletString} from "utils";
import {Row, Col} from "react-bootstrap";
import {faArchive} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { StyledTradingHistory } from './styled';

export default function Index({sorted_nft_events}) {
    const resolveHash = (event) => {
        const hash = event.transactionHash
        const url = `https://${Number(currentNetwork) === 137 ? '' : 'mumbai.'}polygonscan.com/tx/${hash}`;
        return {txnHash: truncateWalletString(hash), txnUrl: url}
    }

    return (
        <StyledTradingHistory>
            <Col xs="12" md="12">
                <div className="titleHeading">
                    <h3>Trading history</h3>
                </div>
                <div className="purchase-container">
                    <Row>
                        <Col xs="3">
                            <label>
                                <p>User</p>
                            </label>
                        </Col>
                        <Col xs="5">
                            <label>
                                <p>Event</p>
                            </label>
                        </Col>
                        <Col xs="2">
                            <label>
                                <p>Date</p>
                            </label>
                        </Col>
                        <Col xs="2">
                            <label>
                                <p>View</p>
                            </label>
                        </Col>
                    </Row>
                    {sorted_nft_events && sorted_nft_events.filter((event)=>event.eventText !== "").map((history, index) => (
                        <Row key={index}>
                            <Col xs="3">
                                <label>{history.eventUser.user_name !== "" ? history.eventUser.user_name : truncateWalletString(history.eventUser.walletAddress) }  </label>
                            </Col>
                            <Col xs="5">
                                <label>{history.eventText}</label>
                            </Col>
                            <Col xs="2">
                                <label>{moment(history.eventDate * 1000).fromNow()}</label>
                            </Col>

                            <Col xs="2">
                                <label><a href={`${resolveHash(history.eventHash).txnUrl}`} target={"_blank"}>
                                    <FontAwesomeIcon icon={faArchive} color={"#000"}/>
                                </a></label>
                            </Col>
                        </Row>
                    ))}
                </div>
            </Col>
        </StyledTradingHistory>
    )
}
