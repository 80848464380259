import {NFTObjectData} from "hooks/useApi";
import {useProfileForWallet} from "../../../store/hooks";
import {getCreatorPrefixByContract, getNftChainByContract, truncateWalletString} from "utils";
import {Image} from "react-bootstrap";

import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import {Card} from "./styled";
import CardsMedia from "../../mediaBox";
import { isMigrationNeeded } from "utils/is-migration-needed";
import {returnCDNImage} from "../../../utils/cdnHelper";


interface PropsType {
    className?: string;
    disabled?: boolean;
    productObj: NFTObjectData;
    isRecent?: boolean;
    isProfile?: boolean;
}

const ForSaleProductCard = ({
    className = "",
    productObj,
    disabled,
    isProfile = false,
}: PropsType) => {
    const location = useLocation();
    const history = useHistory();
    const isCreatorHub = location.pathname.includes("creator") ? true : false;

    const defaultUser = {
        userAvatarUrl: isCreatorHub ? "/logos/CreatorHubLogo.png" : "/images/game.svg"
    }
    let creator = {...productObj?.creator} ?? {}
    if(creator.userAvatarUrl){
        creator = {...creator}
        creator.cdnUserAvatarUrl = returnCDNImage("Image", creator.userAvatarUrl)
    }else{
        creator.cdnUserAvatarUrl = defaultUser.userAvatarUrl
    }

    let owner = {...productObj?.owner} ?? {}
    if(owner.userAvatarUrl){
        owner = {...owner}
        owner.cdnUserAvatarUrl = returnCDNImage("Image", creator.userAvatarUrl)
    }else{
        owner.cdnUserAvatarUrl = defaultUser.userAvatarUrl
    }
    const creatorName =  (creator.displayName > "" ? creator.displayName : truncateWalletString(productObj.initialCreatorAddress))

    return (
        <Card
            className={`col-lg-3 mb-2  ${className} ${
                isCreatorHub ? "creatorHub" : ""
            } ${isProfile ? "profilePage" : ""} `}
            onClick={() => {
                const detailUrl = `${getCreatorPrefixByContract(productObj?.contractAddress)}/product/${getNftChainByContract(productObj?.contractAddress)}/${productObj?.tokenID}`;
                !disabled && history.push(detailUrl);
            }}
        >
            <div className="card" style={{ height: isProfile ? "443px" : "488px" }}>
                {productObj?.listed && (
                    <div className="forSaleWord">
                        <div className="tag">For Sale</div>{" "}
                    </div>
                )}
                {isMigrationNeeded(productObj.contractAddress) && (<h3 className="migrationTitle">Migration Needed</h3>)}
                {productObj?.attributes?.weaponLevel >= 0 && (
                    <div className="weaponLevel">
                        <div className="tag">
                            Weapon level: {productObj.attributes?.weaponLevel}{" "}
                        </div>{" "}
                    </div>
                )}

                <div className={"imageContainer"}>
                    <CardsMedia
                        controls
                        productId={productObj?._id}
                        assetType={productObj?.assetType}
                        assetUrl={productObj?.cdnAssetUrl}
                        bannerImage={productObj?.cdnBannerImage}
                    />
                </div>
                <div>
                    <h5 style={{ textAlign: isProfile ? "center" : "left" }}>
                        {productObj?.name}
                    </h5>
                    {isProfile ? (
                        ""
                    ) : (
                        <div className="d-flex align-items-center justify-content-between pt-3 px-3">
                            <p>Creator:</p>
                            <div className="d-flex align-items-center">
                                <span>{creatorName}</span>
                                <Image
                                    className="userPic"
                                    src={
                                        `${creator?.cdnUserAvatarUrl}?w=80&h=80`
                                    }
                                />
                            </div>
                        </div>
                    )}

                    <div className="d-flex align-items-center justify-content-between pt-3 ps-3">
                        <p>Sale Price:</p>
                        <div className={"dexiBtn"}>{`${parseInt(
                            productObj?.price
                        )} DEXI`}</div>
                    </div>

                    <div className="d-flex align-items-start justify-content-between ">
                        <div className="d-flex align-items-center justify-content-between py-0 px-3">
                            <div className="d-flex align-items-center">
                                <div className="ms-3">
                                    <h4>{creatorName}</h4>
                                    <h5>{productObj.contractAddress}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Card>
    );
};




export default ForSaleProductCard;
