import { Section as S } from "components/section";
import styled from "styled-components";

export const Section = styled(S)`
  .product-details-fluid {
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 20px
  }

  .artist-card {
    label {
      text-decoration: underline;
    }
  }

  .img-wrapper {
    width: 380px;
    height: 430px;
    overflow: hidden;
    border-radius: 30px;
    margin-bottom: 5px;

    img {
      width: 100%;
    }
  }

  h1 {
    font-size: 40px;
  }
  .titleHeading {
    background-color: #0397d5;
    padding-left: 1.5rem;
    height: 88px;
    margin-bottom: 0.5rem;
    h3 {
      line-height: 2.5;
      color: #ffffff;
      font-size: 35px;
      font-weight: 500;
    }
  }

  .purchase-container {
    margin: 30px auto 20px;
    padding: 20px 30px;
    width: fit-content;
    text-align: center;
    // border-radius: 10px;
    box-shadow: rgb(0 0 0 / 10%) 0px 3px 1px -2px,
      rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 3px;
    // background: ${({ theme }) => theme.background[2]};
    background-color: #ffffff;

    label {
      color: #0397d5 !important;
      p {
        margin-bottom: 0px !important;
        font-size: 30px;
        color: #0397d5 !important;
      }
    }
    h3 {
      margin-bottom: 25px !important;
    }
  }
  .flex-box {
    display: flex;
    flex-direction: row;

    p {
      color: #0397d5;
      font-size: 30px;
    }

    img {
      width: 70px;
      height: 50px;
    }

    &.price {
      margin: 0 0 25px;
      // justify-content: center;
    }

    &.actions {
      gap: 25px;
    }
  }

  p {
    font-size: 20px;
  }

  .tranding-row {
    h3 {
      margin-bottom: 15px !important;
    }

    margin-top: 50px;

    .purchase-container {
      margin: 0;
      padding: 20px;
      min-height: 200px;
      text-align: left;
      width: auto;

      label {
        color: #0397d5;
        padding: 5px 0;
        display: block;
        font-size: 23px;
        word-break: break-word;
        font-weight: 500;
      }
    }

    #bidContainer {
      label:nth-child(n + 2) {
        color: #59dabc;
        padding: 5px 0;
      }
    }
  }

  @media (max-width: 576px) {
    padding: 20px 0 50px;

    .img-wrapper {
      width: 100%;
      height: 380px;
      border-radius: 25px;
      margin-bottom: 5px;
    }

    .purchase-container {
      width: 100%;

      .flex-box {
        &.actions {
          gap: 10px;

          button {
            font-size: 18px;
          }
        }
      }
    }

    .tranding-row {
      gap: 40px;
    }
  }
`;
export const ProfileDetails = styled.div`
  // background-color: #ffffff;
  position: relative;
  z-index: 111;
  .borderBox {
    position: absolute;
    width: 281px;
    background-color: #02c573;
    top: -10px;
    right: -10px;
    height: 121px;
    z-index: -1;
  }

  @media(max-width: 768px) {
    .borderBox{
      display:none;
    }
  }

  button.buyBtn {
    background-image: linear-gradient(45deg, #00af66, #02c573);
    color: #ffffff;
    padding: 0.3em;
    line-height: 3;
    margin-bottom: 0;
    border: none;
    outline: none;
    min-width: 120px;
  }
`;
export const NftName = styled.div`
  background-color: #ffffff;
  padding: 10px 10px 120px;
  position: relative;

  @media (max-width: 768px) {
    padding: 10px 10px 80px;
  }

  h1 {
    margin-left: 0.5rem;
    font-size: 56px;
    font-weight: 700;
    color: #0397d5;
    margin-bottom: 0.2em;
  }
  p {
    font-size: 20px;
    margin-left: 0.5rem;
    color: #0397d5;
  }

  .purchase {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: -10px;
    right: 0;
    left: -10px;

    @media (max-width: 768px) {
      left: 0;
    }

    h3 {
      display: flex;
      align-items: center;
      background-image: linear-gradient(45deg, #00af66, #02c573);
      color: #ffffff;
      padding: 1rem 1rem;
      height: 116px;
      margin-bottom: 0;
      flex: 1;
      margin-right: 0.5em;

      @media (max-width: 768px) {
        height: 50px;
        font-size: 16px;
      }
    }

    button {
      background-color: #02c573;
      border: none;
      font-weight: 700;
      font-size: 30px;
      width: 230px;
      height: 116px;
      color: #ffffff;

      @media (max-width: 768px) {
        height: 50px;
        font-size: 16px;
      }
    }
  }

  &.gamehub {
    h1 {
      color: #650074;
    }
    p {
      color: #650074;
    }
    .purchase {
      h3 {
        background-image: linear-gradient(45deg, #35003c, #650074);
      }

      button {
        background-color: #650074;
      }
    }
  }
`;

export const NftMint = styled.div`
  background-color: #ffffff;
  margin: 1.5rem 0 0 0;
  height: 243px;
  position: relative;

  @media (max-width: 768px) {
    height: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .transaction {
    padding-top: 2.5rem;
    padding-left: 1rem;
    margin-bottom: 0;
    font-size: 30px;
    color: #0397d5;

    @media (max-width: 768px) {
      padding-top: 1.5rem;
      font-size: 24px;
    }
  }
  span {
    color: #0397d5;
    font-size: 17px;

    @media (max-width: 768px) {
      padding-top: 1.5rem;
      font-size: 14px;
    }

  }
  .mintBtn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: 0;
    left: -10px;

    @media (max-width: 768px) {
      left: 0;
    }

    .royality {
      background-color: #0397d5;
      padding: 0rem 1.5rem;
      font-size: 27px;
      line-height: 3;
      width: 367px;
      height: 86px;
      color: #ffffff;
      @media (max-width: 768px) {
        height: 50px;
        font-size: 16px;
        align-items: center;
        display: flex;
      }
    }
    .viewBtn {
      background-color: #02c573;
      border: none;
      padding: 0.5rem 1.5rem;
      font-size: 27px;
      width: 490px;
      height: 86px;
      color: #ffffff;
      img {
        margin-left: 4rem;
      }
      @media (max-width: 768px) {
        height: 50px;
        font-size: 16px;
        font-size: 1.4em;
        img {
          display: none;
        }
      }
    }
  }
`;
