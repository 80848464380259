import { Contract } from "@ethersproject/contracts";
import DXGNFTABI from "contracts/DXGNFT.json";
import DXGAUCTIONABI from "contracts/DXGAUCTION.json";
import DXGABI from "contracts/DXG.json";
import DXGOLDABI from "contracts/DXGOLD.json";
import DEXINFTABI from "contracts/DEXINFT.json";
import CREATORHUBMARKETABI from "contracts/CREATORHUBMARKET.json";
import CREATORHUBNFTABI from "contracts/CREATORHUBNFT.json";
import GAMEVAULTMARKETABI from "contracts/GAMEVAULTMARKET.json";
import GAMEVAULTNFTABI from "contracts/GAMEVAULTNFT.json";
import DXGMigration from "contracts/DXGMigration.json";
import { config } from "../environment";
import DEXIABI from "../marketplace/contracts/DEXI.json";

export const Networks = {
  MainNet: 56,
  Testnet: 97,
  Rinkeby: 4,
  Kovan: 42,
  CronosMainNet: 25,
  CronosTestNet: 338,
  PolygonMainNet: 137,
  PolygonTestNet: 80001,
};

export const CONTRACTS_BY_NETWORK = {
  [Networks.MainNet]: {
    DXGOLD: {
      address: "0x2ece964b59a46dd836bd75e541df53af1274e7e5",
      abi: DXGOLDABI,
    },
    DXGMIGRATION: {
      address: "0x3BC542Ee8d33DD1698bDd903Df685Dd48C557a41",
      abi: DXGMigration,
    },
    DXGNFT: {
      address: "0xe55DbF0D9297D294EA979d9D84946E1F348AB0dA",
      abi: DXGNFTABI,
    },
    DEXINFT: {
      address: "0x934d5E0F7E7D3429c579707831b8D8c146Ff6B70",
      abi: DEXINFTABI,
    },
    DXGAUCTION: {
      address: "0x66cdAC7956B8c5fDAb36223c5897Aa93Dd395A81",
      abi: DXGAUCTIONABI,
    },
    DXG: {
      address: "0x8236f1239530971868925e6017d4005763E9087f",
      abi: DXGABI,
    },
  },
  [Networks.Testnet]: {
    DXGOLD: {
      address: "0x4a3448dd1AeaB5070B79f5838DF743830192bf57",
      abi: DXGOLDABI,
    },
    DXGMIGRATION: {
      address: "0x505D1A769ea01cE36C2A58Bee857b1c1C0AAEB0C",
      abi: DXGMigration,
    },
    DXGNFT: {
      address: "0xC74f4945D8A6a1A83Cf20f1eEf267E9E1ce82915",
      abi: DXGNFTABI,
    },
    DEXINFT: {
      address: "0xef077AD788D8E46f1ef0d76F42Bc5ad999734a4c",
      abi: DEXINFTABI,
    },
    DXGAUCTION: {
      address: "0x4a2a73356681C13a52a748477E7482a511438AB9",
      abi: DXGAUCTIONABI,
    },
    DXG: {
      address: "0x0718Cd262e6D405296FEB14e42198E1E9965b2Ad",
      abi: DXGABI,
    },
  },
  [Networks.CronosMainNet]: {
    DEXI: {
      address: "",
      abi: DEXIABI,
    },
    CREATORHUBNFT: {
      address: "",
      abi: CREATORHUBNFTABI,
    },
    CREATORHUBMARKET: {
      address: "",
      abi: CREATORHUBMARKETABI,
    },
    GAMEVAULTNFT: {
      address: "",
      abi: GAMEVAULTNFTABI,
    },
    GAMEVAULTMARKET: {
      address: "",
      abi: GAMEVAULTMARKETABI,
    },
  },
  [Networks.CronosTestNet]: {
    DEXI: {
      address: "0xf0d8519038Dca4b5ac5BF55219b0981d70b79a3a",
      abi: DEXIABI,
    },
    CREATORHUBNFT: {
      address: "0x7b660B9C069A61Dc57B862fA552Cb2Bc6451415d",
      abi: CREATORHUBNFTABI,
    },
    CREATORHUBMARKET: {
      address: "0x72cf00dda0c877c588b7ecdadc6f13fbadb15bdf",
      abi: CREATORHUBMARKETABI,
    },
    GAMEVAULTNFT: {
      address: "0x0022486Ad536Ce71CC832c0CaBBA36189CB5e848",
      abi: GAMEVAULTNFTABI,
    },
    GAMEVAULTMARKET: {
      address: "0x0F4B5c854b2b3d6fd9ce9928624cf395Cf71D6B3",
      abi: GAMEVAULTMARKETABI,
    },
  },
  [Networks.PolygonMainNet]: {
    DEXI: {
      address: "0x65ba64899c2c7dbfdb5130e42e2cc56de281c78b",
      abi: DEXIABI,
    },
    CREATORHUBNFT: {
      address: "0x24F91dda691b983283383A437be072454bf1d93B",
      abi: CREATORHUBNFTABI,
    },
    CREATORHUBMARKET: {
      address: "0xAf63FCE5d26c2CAA685a6ceC238bA80f5DbbE5f1",
      abi: CREATORHUBMARKETABI,
    },
    GAMEVAULTNFT: {
      address: "0x640dC1BdB443727cedBf6e5D19dAee4A45AE9069",
      abi: GAMEVAULTNFTABI,
    },
    GAMEVAULTMARKET: {
      address: "0x89f10D4EB063fBEb74dDD818aa328Ee41F5383b5",
      abi: GAMEVAULTMARKETABI,
    },
  },
  [Networks.PolygonTestNet]: {
    DEXI: {
      address: "0x575A51BBBA54F9cB7DED4146c2A361Bfb569a158",
      abi: DEXIABI,
    },
    CREATORHUBNFT: {
      address: "0xC8851E809c1625B93f849fF5A0F9434B44e40773",
      abi: CREATORHUBNFTABI,
    },
    CREATORHUBMARKET: {
      address: "0xe55DbF0D9297D294EA979d9D84946E1F348AB0dA",
      abi: CREATORHUBMARKETABI,
    },
    GAMEVAULTNFT: {
      address: "0x2E16Aa02613F232767878F3E71D43182F3EC984C",
      abi: GAMEVAULTNFTABI,
    },
    GAMEVAULTMARKET: {
      address: "0xcD50c65ACA4d6e011d6dcF71Eb2b8Ad0b8a4ce94",
      abi: GAMEVAULTMARKETABI,
    },
  },
};

export const currentNetwork = config.REACT_APP_NETWORK_ID;

export const baseApiUrl = config.REACT_APP_API_URL;

export const DEXI_KNIGHTS_GAME_ID = "6238849ffffcdebf2f62e1f6";

export function getContractInfo(name, chainId = null) {
  //if (!chainId) chainId = currentNetwork;

  const contracts = CONTRACTS_BY_NETWORK?.[chainId ? chainId : currentNetwork];
  if (contracts) {
    return contracts?.[name];
  } else {
    return null;
  }
}

export function truncateWalletString(walletAddress) {
  if (!walletAddress) return walletAddress;
  const lengthStr = walletAddress.length;
  const startStr = walletAddress.substring(0, 7);
  const endStr = walletAddress.substring(lengthStr - 7, lengthStr);
  return startStr + "..." + endStr;
}

export function truncateHashString(txhash) {
  if (!txhash) return txhash;
  const lengthStr = txhash.length;
  const startStr = txhash.substring(0, 10);
  const endStr = txhash.substring(lengthStr - 10, lengthStr);
  return startStr + "..." + endStr;
}

export function getContractObj(name, chainId, provider) {
  const info = getContractInfo(name, chainId);
  return !!info && new Contract(info.address, info.abi, provider);
}

export const getNftChainByContract = (contractAddress: string) => {
  const lowercasedContractAddress = contractAddress.toLowerCase();
  switch (lowercasedContractAddress) {
    case CONTRACTS_BY_NETWORK[Networks.PolygonMainNet].CREATORHUBNFT.address.toLowerCase():
    case CONTRACTS_BY_NETWORK[Networks.PolygonTestNet].CREATORHUBNFT.address.toLowerCase():
    case CONTRACTS_BY_NETWORK[Networks.PolygonTestNet].GAMEVAULTNFT.address.toLowerCase():
    case CONTRACTS_BY_NETWORK[Networks.PolygonMainNet].GAMEVAULTNFT.address.toLowerCase():
      return 'polygon';
    case CONTRACTS_BY_NETWORK[Networks.MainNet].DEXINFT.address.toLowerCase():
    case CONTRACTS_BY_NETWORK[Networks.Testnet].DEXINFT.address.toLowerCase():
    case CONTRACTS_BY_NETWORK[Networks.MainNet].DXGNFT.address.toLowerCase():
    case CONTRACTS_BY_NETWORK[Networks.Testnet].DXGNFT.address.toLowerCase():
      return 'bsc';
    default:
      return 'bsc';
  }
};

export const getCreatorPrefixByContract = (contractAddress: string) => {
  const lowercasedContractAddress = contractAddress.toLowerCase();
  switch (lowercasedContractAddress) {
    case CONTRACTS_BY_NETWORK[Networks.PolygonMainNet].CREATORHUBNFT.address.toLowerCase():
    case CONTRACTS_BY_NETWORK[Networks.PolygonTestNet].CREATORHUBNFT.address.toLowerCase():
    case CONTRACTS_BY_NETWORK[Networks.MainNet].DEXINFT.address.toLowerCase():
    case CONTRACTS_BY_NETWORK[Networks.Testnet].DEXINFT.address.toLowerCase():
      return '/creator';
    default:
      return '';
  }
};

export const getContractAddressByChainName = (chainName: string, type: 'CREATORHUBNFT' | 'GAMEVAULTNFT') => {
  switch (chainName) {
    case 'polygon':
      if (type === 'CREATORHUBNFT') return Number(currentNetwork) === 137
        ? CONTRACTS_BY_NETWORK[Networks.PolygonMainNet].CREATORHUBNFT.address.toLowerCase()
        : CONTRACTS_BY_NETWORK[Networks.PolygonTestNet].CREATORHUBNFT.address.toLowerCase()
      else {
        return Number(currentNetwork) === 137
        ? CONTRACTS_BY_NETWORK[Networks.PolygonMainNet].GAMEVAULTNFT.address.toLowerCase()
        : CONTRACTS_BY_NETWORK[Networks.PolygonTestNet].GAMEVAULTNFT.address.toLowerCase()
      }
    case 'bsc':
      if (type === 'CREATORHUBNFT') return Number(currentNetwork) === 137
        ? CONTRACTS_BY_NETWORK[Networks.MainNet].DEXINFT.address.toLowerCase()
        : CONTRACTS_BY_NETWORK[Networks.Testnet].DEXINFT.address.toLowerCase()
      else {
        return Number(currentNetwork) === 137
        ? CONTRACTS_BY_NETWORK[Networks.MainNet].DXGNFT.address.toLowerCase()
        : CONTRACTS_BY_NETWORK[Networks.Testnet].DXGNFT.address.toLowerCase()
      }
  }
};

export const getExplorerUrlByNftId = (chainName: string, tokenId: string | number, type: 'CREATORHUBNFT' | 'GAMEVAULTNFT') => {
  switch (chainName) {
    case 'polygon':
      return `https://${Number(currentNetwork) === 137 ? '' : 'mumbai.'}polygonscan.com/token/${getContractAddressByChainName(chainName, type)}?a=${tokenId}`;
    case 'bsc':
      return `https://${Number(currentNetwork) === 137 ? '' : 'testnet.'}bscscan.com/token/${getContractAddressByChainName(chainName, type)}?a=${tokenId}`;
  }
}

export const shorter = (str) =>
  str?.length > 8 ? str.slice(0, 6) + "..." + str.slice(-4) : str;
