import React from "react";
import { Section } from "./index.styled";
import { Container, Accordion } from "react-bootstrap";
import { H1, H2, P } from "components/typography";
import Layout from "components/layout"

import data from "./data.json";
const faqCreator = (data) => {
  return <Faq key={data.id} header={data.question} body={data.answer} />;
};

function Faq(props) {
  return (
    <div>
      <Accordion defaultActiveKey="0" flush>
        <Accordion.Item eventKey={props.key}>
          <Accordion.Header>{props.header}</Accordion.Header>
          <Accordion.Body>
            <div dangerouslySetInnerHTML={{ __html: props.body }} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default function Index() {
  return (
    <Layout>

    <Section style={{minHeight:"86.6vh"}}>
      <Container >
        <H2 className="text-center mb-5">GameVault FAQ</H2>

        {data.map(faqCreator)}
      </Container>
    </Section>
        </Layout>

  );
}
