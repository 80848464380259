import CardsMedia from "components/mediaBox";
import {NFTObjectData} from "hooks/useApi";
import {Image} from "react-bootstrap";
import {useHistory} from "react-router";
import {useLocation} from "react-router-dom";
import {useProfile} from "store/hooks";
import {toast} from "react-hot-toast";
import {ENFTType, likeDislikeNft} from "hooks/useApi";
import {useState, useEffect} from "react";
import {Card, ProfileName} from "./styled";
import {truncateWalletString} from "utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHeart} from "@fortawesome/free-solid-svg-icons";
import {faHeart as farHeart} from "@fortawesome/free-regular-svg-icons";
import {returnCDNImage} from "../../../utils/cdnHelper";
import {FullscreenNftModal} from "../fullScreenCard";

interface PropsType {
    className?: string;
    disabled?: boolean;
    productObj: any;
    isRecent?: boolean;
    isProfile?: boolean;
    isMarketplace?: boolean;
}

const NewDetailsCard = ({
                            className = "",
                            productObj,
                            disabled,
                            isProfile = false,
                            isMarketplace = false,
                        }: PropsType) => {
    const {profile} = useProfile();
    const location = useLocation();

    const history = useHistory();
    const [likes, setLikes] = useState(productObj?.likes?.length);
    const [isLiked, setIsLiked] = useState(false);
    const [creator, setCreator] = useState<any>({});

    const [showFullScreen, setShowFullScreen] = useState(false);
    const isCreatorHub = location.pathname.includes("creator") ? true : false;
    useEffect(() => {
        if (productObj?.likes?.includes(profile?._id)) setIsLiked(true);
        else setIsLiked(false);
        setLikes(productObj?.likes?.length);

        const defaultUser = {
            userAvatarUrl: isCreatorHub ? "/logos/CreatorHubLogo.png" : "/images/game.svg"
        }

        let creator = {...productObj?.creator} ?? {}
        if(creator.userAvatarUrl){
            creator = {...creator}
            creator.cdnUserAvatarUrl = returnCDNImage("Image", creator.userAvatarUrl)
        }else{
            creator.cdnUserAvatarUrl = defaultUser.userAvatarUrl
        }

        let owner = {...productObj?.owner} ?? {}
        if(owner.userAvatarUrl){
            owner = {...owner}
            owner.cdnUserAvatarUrl = returnCDNImage("Image", creator.userAvatarUrl)
        }else{
            owner.cdnUserAvatarUrl = defaultUser.userAvatarUrl
        }
        setCreator(creator)
    }, [productObj, profile]);

    useEffect(() => {
        if(creator && productObj){


        }
    }, [productObj, creator]);

    return ( productObj &&
        <Card
            className={`col-lg-3 mb-2  ${className} ${
                isCreatorHub ? "creatorHub" : ""
            } ${isProfile ? "profilePage" : ""} `}
        >
      <span
          className="borderBox"
          style={
              isCreatorHub
                  ? {backgroundColor: "#0397D5"}
                  : {backgroundColor: "#da8027"}
          }
      />
            <div className="card" style={{height: isProfile ? "418px" : "610px"}}>
                {
                    productObj?.listed && (
                        <div className="soldWord">
                            <div className="tag">For Sale</div>
                        </div>
                    )
                }

                <div className="fullscreenWord" onClick={()=>setShowFullScreen(true)}>
                    <div className="tag">Full Screen</div>{" "}
                </div>
                <div className={"imageContainer"}>
                    <CardsMedia
                        controls
                        productId={productObj?._id}
                        assetType={productObj?.assetType}
                        assetUrl={productObj?.cdnAssetUrl}
                        bannerImage={productObj?.cdnBannerImage}
                    />
                </div>
                <div>
                    <ProfileName>
                        <h5 style={{textAlign: isProfile ? "center" : "left"}}>
                            {productObj?.name}
                        </h5>
                        <Image
                            className="userImg"
                            src={
                                `${creator?.cdnUserAvatarUrl}?w=80&h=80`
                            }
                        />
                    </ProfileName>
                    {isProfile ? (
                        ""
                    ) : (
                        <div
                            className="d-flex align-items-center  pt-4 px-3"
                            style={{cursor: "pointer"}}
                            onClick={() => {
                                if (creator?.customUrl)
                                    isMarketplace
                                        ? history.push(
                                            `/creator/storefront/${creator?.customUrl}`
                                        )
                                        : history.push(
                                            `/storefront/${creator?.customUrl}`
                                        );
                            }}
                        >
                            <p>Creator:</p>{" "}
                            <div
                                className="nftEmporium"
                                style={
                                    isCreatorHub ? {color: "#0397D5"} : {color: "#7316a2"}
                                }
                            >
                                {creator.displayName > "" ? creator.displayName : truncateWalletString(productObj.initialCreatorAddress)}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <FullscreenNftModal
                show={showFullScreen}
                productObj={productObj}
                onClose={() => setShowFullScreen(false)} />
        </Card>
    );
};

export default NewDetailsCard;
