import styled from "styled-components";

interface PPT {
  varient?: "primary" | "secondary";
}
export const P = styled.p<PPT>`
  color: ${(props) => props.theme.color[1]};
  letter-spacing: 0.02em;
  margin: 0;
  line-height: 44px;
  color: black;
`;

export const H1 = styled.h1`
  color: #0b1b27;
  font-size: 60px;
  font-weight: bold;
  line-height: 44px;
  color: black;
`;

export const H2 = styled.h2`
  color: ${(props) => props.theme.color[0]};
  font-size: 40px;
  font-weight: 700;
  line-height: 44px;
  color: black;
`;

export const H3 = styled.h3`
  color: ${(props) => props.theme.color[0]};
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 40px;
  font-size: 36px;
  line-height: 44px;
  color: black;
`;

export const H4 = styled.h6`
  color: ${(props) => props.theme.color[0]};
  font-size: 25px;
  font-weight: 500;
  line-height: 44px;
  color: black;
`;

export const H5 = styled.h6`
  color: ${(props) => props.theme.color[0]};
  font-size: 20px;
  font-weight: 500;
  line-height: 44px;
  color: black;
`;

export const H6 = styled.h6`
  color: ${(props) => props.theme.color[0]};
  font-size: 18px;
  font-weight: 500;
  line-height: 44px;
  color: black;
`;
