import styled from "styled-components";

const Link = styled.a`
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  line-height: 1.5;
  height: fit-content;
  width: fit-content;

  color: ${({ theme }) => theme.color[1]};

  &:hover {
    text-decoration: none;
    color: ${({ theme }) => theme.primary[0]};
  }
`;

interface PropsType {
  className?: string;
  children: any;
  onClick?: () => void;
}
const LinkButton = ({ className = "", children, onClick }: PropsType) => {
  return (
    <Link className={`${className}`} onClick={onClick && onClick}>
      {children}
    </Link>
  );
};
export default LinkButton;
