import Button from "components/button";
import LinkButton from "components/button/link";
import Input from "components/form/input";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Form, Modal } from "./index.styled";

const FixedPrice = ({ onClose, onSubmit }) => {
  const [show, setShow] = useState(true);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  const onFormSubmit = async (data) => {
    if (data.price <= 0) {
      toast.error("Price should be over than 0");
      return;
    }

    onSubmit(data.price);
  };

  return (
    <Modal show={show} onHide={handleClose} centered backdrop="static">
      <Form onSubmit={handleSubmit(onFormSubmit)}>
        <Modal.Body>
          <Modal.Title className="mb-4">Sell</Modal.Title>

          <Input
            placeholder="Price"
            type="number"
            step="any"
            register={{
              ...register("price", {
                required: { message: "Price is required ", value: true },
                validate: (price: number) =>
                  price > 0 || "Price is not valid !",
              }),
            }}
            errors={errors}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button>Sell</Button>
          <LinkButton onClick={handleClose}>Close</LinkButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default FixedPrice;
