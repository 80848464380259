const ipfsClient = require('ipfs-api');

const projectId = "2DBwZKjMxdVVYbu6EcdxpCm3vMt"
const projectSecret = "a919da7e27cda23722df0c287518c30c"
const auth =
    'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64')

const ipfs = new ipfsClient({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
        authorization: auth
    }
});

export const getIpfsHash = async (data) => {
    const result = await ipfs.files.add(data);
    const hash = result[0].hash;
    const pinResult = await ipfs.pin.add(hash);
    //const s3Result = await loadS3FromHash(hash)
    return hash;
};

export function readFileAsync(file) {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onload = () => {
            resolve(Buffer.from(reader.result));
        };

        reader.onerror = reject;

        reader.readAsArrayBuffer(file);
    })
}
