import "@ethersproject/shims";
import {ethers} from "ethers";
import toast from "react-hot-toast";
import moment from "moment";
import {getContractObj} from ".";
import axios from "axios";

export async function approveToken(chainId, signer, account, _limitAmount) {
    const marketContract = getContractObj("GAMEVAULTMARKET", chainId, signer);
    const DEXIContract = getContractObj("DEXI", chainId, signer);

    const allowanceAmount = await DEXIContract.allowance(
        account,
        marketContract.address
    );
    const DEXIDecimals = await DEXIContract.decimals();

    if (
        allowanceAmount.lt(
            ethers.utils.parseUnits(_limitAmount.toString(), DEXIDecimals)
        )
    ) {
        const load_approve_toast_id = toast.loading(
            `Please wait, DEXI is approving now...`
        );
        try {
            var tx = await DEXIContract.approve(
                marketContract.address,
                ethers.constants.MaxUint256
            );
            await tx.wait(1);
        } catch (e) {
            console.log(e);
        }
        toast.dismiss(load_approve_toast_id);
    }
}

export const mintFixed = async (
    chainId: number,
    signer: any,
    tokenURI: string,
    price: number,
    royalty: number,
    quantity: number,
    account: string
) => {
    const nftContract = getContractObj("GAMEVAULTNFT", chainId, signer);
    const marketContract = getContractObj("GAMEVAULTMARKET", chainId, signer);
    const DEXIContract = getContractObj("DEXI", chainId, signer);
    let tx: any;

    try {
        const DEXIDecimals = await DEXIContract.decimals();
        const DEXIAddress = DEXIContract.address;
        
        if (quantity > 1) {
            tx = await nftContract.bulkMint(account, tokenURI, quantity, royalty);
            await tx.wait(1);

            const totalSupply = await nftContract.totalSupply();

            let nftArray = [];

            for (let i = totalSupply - quantity + 1; i <= totalSupply; i++) {
                nftArray.push(i);
            }

            tx = await marketContract.bulkSell(
                nftArray,
                ethers.utils.parseUnits(price.toString(), DEXIDecimals),
                DEXIAddress
            );
            await tx.wait(1);
        } else {
            tx = await nftContract.mint(account, tokenURI, royalty);
            await tx.wait(1);

            const totalSupply = await nftContract.totalSupply();

            tx = await marketContract.sell(
                totalSupply,
                ethers.utils.parseUnits(price.toString(), DEXIDecimals)
            );
            await tx.wait(1);
        }
        return tx.hash;
    } catch (e) {
        console.log(e);
        return false;
    }
};

export async function mintAuction(
    chainId: number,
    signer: any,
    tokenURI: string,
    minBidPrice: string,
    startTime: Date,
    endTime: Date,
    royalty: string,
    account: string,
    quantity: number
) {
    const nftContract = getContractObj("GAMEVAULTNFT", chainId, signer);
    const marketContract = getContractObj("GAMEVAULTMARKET", chainId, signer);
    const DEXIContract = getContractObj("DEXI", chainId, signer);

    const startUnixTimeStamp = Math.round(startTime.getTime() / 1000);
    const endUnixTimeStamp = Math.round(endTime.getTime() / 1000);

    let tx: any;

    try {
        
        const DEXIDecimals = await DEXIContract.decimals();

        if (quantity > 1) {
            tx = await nftContract.bulkMint(account, tokenURI, quantity, royalty );
            await tx.wait(1);

            const totalSupply = await nftContract.totalSupply();

            let nftArray = [];

            for (let i = totalSupply - quantity + 1; i <= totalSupply; i++) {
                nftArray.push(i);
            }

            tx = await marketContract.bulkCreateAuction(
                nftArray,
                ethers.utils.parseUnits(minBidPrice.toString(), DEXIDecimals),
                startUnixTimeStamp,
                endUnixTimeStamp
            );
            await tx.wait(1);
        } else {
            tx = await nftContract.setMarketContractAddress(marketContract.address);
            tx = await nftContract.mint(account, tokenURI, royalty );

            await tx.wait(1);

            const totalSupply = await nftContract.totalSupply();

            tx = await marketContract.createAuction(
                totalSupply,
                ethers.utils.parseUnits(minBidPrice.toString(), DEXIDecimals),
                startUnixTimeStamp,
                endUnixTimeStamp
            );
            await tx.wait(1);
        }

        return tx.hash;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function mintUnlimitedAuction(
    chainId: number,
    signer: any,
    tokenURI: string,
    royalty: string,
    account: string,
    quantity: number
) {
    const nftContract = getContractObj("GAMEVAULTNFT", chainId, signer);
    const marketContract = getContractObj("GAMEVAULTMARKET", chainId, signer);

    let tx: any;

    try {
        
        if (quantity > 1) {
            tx = await nftContract.bulkMint(account, tokenURI, quantity, royalty );
            await tx.wait(1);

            const totalSupply = await nftContract.totalSupply();

            let nftArray = [];

            for (let i = totalSupply - quantity + 1; i <= totalSupply; i++) {
                nftArray.push(i);
            }

            tx = await marketContract.bulkCreateAuction(
                nftArray,
                0,
                0,
                String(Number.MAX_SAFE_INTEGER)
            );
            await tx.wait(1);
        } else {
            tx = await nftContract.mint(account, tokenURI, royalty );
            await tx.wait(1);

            const totalSupply = await nftContract.totalSupply();

            tx = await marketContract.createAuction(
                totalSupply,
                0,
                0,
                String(Number.MAX_SAFE_INTEGER)
            );
            await tx.wait(1);
        }

        return tx.hash;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function buy(chainId, signer, account, tokenID, price) {
    const marketContract = getContractObj("GAMEVAULTMARKET", chainId, signer);
    try {
        
        await approveToken(chainId, signer, account, price);
        const tx = await marketContract.buy(tokenID);
        await tx.wait(1);

        return tx.hash;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export const sell = async (
    chainId: number,
    signer: any,
    price: string,
    tokenId: number
) => {
    const nftContract = getContractObj("GAMEVAULTNFT", chainId, signer);
    const marketContract = getContractObj("GAMEVAULTMARKET", chainId, signer);
    const DEXIContract = getContractObj("DEXI", chainId, signer);
    let tx: any;

    try {
        

        tx = await nftContract.approve(marketContract.address, tokenId);
        await tx.wait(1);

        const DEXIDecimals = await DEXIContract.decimals();

        tx = await marketContract.sell(
            tokenId,
            ethers.utils.parseUnits(price, DEXIDecimals)
        );

        await tx.wait(1);
        return tx.hash;
    } catch (e) {
        console.log(e);
        return false;
    }
};

export async function bid(chainId, signer, account, tokenID, price) {
    const marketContract = getContractObj("GAMEVAULTMARKET", chainId, signer);
    const DEXIContract = getContractObj("DEXI", chainId, signer);
    try {
        
        await approveToken(chainId, signer, account, price);

        const DEXIDecimals = await DEXIContract.decimals();
        const balanceDEXI = await DEXIContract.balanceOf(account);
        const bidTokenAmount = ethers.utils.parseUnits(
            price.toString(),
            DEXIDecimals
        );

        if (balanceDEXI.lt(bidTokenAmount)) return false;

        const load_bid_toast_id = toast.loading(
            `Please wait until send bid offer...`
        );
        const placeBidToNFT = await marketContract.bid(tokenID, bidTokenAmount );
        await placeBidToNFT.wait(1);
        toast.dismiss(load_bid_toast_id);

        return true;
    } catch (e) {
        toast.dismiss();
        return false;
    }
}

export async function cancelBid(chainId, signer, tokenID) {
    const marketContract = getContractObj("GAMEVAULTMARKET", chainId, signer);
    try {
        
        const tx = await marketContract.cancelBid(tokenID );
        await tx.wait(1);
        return tx.hash;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function updatePrice(chainId, signer, tokenId, price) {
    const marketContract = getContractObj("GAMEVAULTMARKET", chainId, signer);
    const DEXIContract = getContractObj("DEXI", chainId, signer);
    try {
        

        const DEXIDecimals = await DEXIContract.decimals();
        const tx = await marketContract.changePrice(
            tokenId,
            ethers.utils.parseUnits(price.toString(), DEXIDecimals)
        );
        await tx.wait(1);

        return tx.hash;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function cancelSelling(chainId, signer, tokenId) {
    const marketContract = getContractObj("GAMEVAULTMARKET", chainId, signer);
    try {
        

        const tx = await marketContract.cancelSelling(tokenId);
        await tx.wait(1);

        return tx.hash;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function burn(chainId, provider, _tokenID) {
    const nftContract = getContractObj("GAMEVAULTNFT", chainId, provider);
    try {
        const tx = await nftContract.burn(_tokenID);
        await tx.wait(1);

        return tx.hash;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function startAuction(
    chainId: number,
    signer: any,
    tokenId: number,
    minBidPrice: string,
    startTime: Date | string,
    endTime: Date | string
) {
    const nftContract = getContractObj("GAMEVAULTNFT", chainId, signer);
    const marketContract = getContractObj("GAMEVAULTMARKET", chainId, signer);
    const DEXIContract = getContractObj("DEXI", chainId, signer);

    const DEXIDecimals = await DEXIContract.decimals();

    const startUnixTimeStamp = moment(startTime).unix();
    const endUnixTimeStamp = moment(endTime).unix();

    let tx: any;

    try {
        
        tx = await nftContract.approve(marketContract.address, tokenId);
        await tx.wait(1);

        tx = await marketContract.createAuction(
            tokenId,
            ethers.utils.parseUnits(minBidPrice.toString(), DEXIDecimals),
            startUnixTimeStamp,
            endUnixTimeStamp
        );
        await tx.wait(1);

        return tx.hash;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function claimAuction(chainId, signer, tokenID, account: string) {
    const marketContract = getContractObj("GAMEVAULTMARKET", chainId, signer);
    try {
        
        const tx = await marketContract.claimAuction(tokenID, account);
        await tx.wait(1);

        return tx.hash;
    } catch (e) {
        console.log(e);
        return false;
    }
}

export async function getBalanceOfDEXI(chainId, provider, account) {
    const DEXIContract = getContractObj("DEXI", chainId, provider);
    try {
        const DEXIDecimals = await DEXIContract.decimals();
        const balanceDEXI = await DEXIContract.balanceOf(account);
        return parseFloat(ethers.utils.formatUnits(balanceDEXI, DEXIDecimals));
    } catch (e) {
        console.log(e);
        return 0;
    }
}

export async function getBalanceOfBNB(library, account) {
    try {
        const balanceBNB = await library.getBalance(account);
        return parseFloat(ethers.utils.formatEther(balanceBNB));
    } catch (e) {
        console.log(e);
        return 0;
    }
}

export const getSellingData = async (chainId, signer, tokenId) => {
    const marketContract = getContractObj("GAMEVAULTMARKET", chainId, signer);
    try {
        const selling = await marketContract.sellings(tokenId);
        return selling;
    } catch (e) {
        console.log(e);
        return null;
    }
};

export const getMarketOwner = async (chainId, signer) => {
    const marketContract = getContractObj("GAMEVAULTMARKET", chainId, signer);
    try {
        const owner = await marketContract.owner();
        return owner;
    } catch (e) {
        console.log(e);
        return null;
    }
};
