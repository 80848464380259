import { useState, useEffect, useCallback } from "react";
import { toast } from "react-hot-toast";
import { LoginBtn, Logo, NavContainer, StyledNav, NavItem } from "./style";
import { NavLink, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { config } from "../../environment";
import { NavDropdown, Image, Nav, Navbar } from "react-bootstrap";
import { useProfile } from "store/hooks";
import useAuth from "hooks/useAuth";
import { useWeb3React } from "@web3-react/core";
import Connect from "./Connect";
import { getBalanceOfDEXI } from "utils/contracts";
import { setWalletAddress, useMinter } from "hooks";

const Header = () => {
  const location = useLocation();
  const [loginStatus, setLoginStatus] = useState(false);
  const [showWallet, setShowWallet] = useState(false);
  const isMinter = useMinter();

  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    getAccessTokenSilently,
  } = useAuth0();
  const isCreatorHub = location.pathname.includes("creator");

  const { profile } = useProfile();
  const { login, logout: logoutWallet } = useAuth();
  const { library, chainId, account, active } = useWeb3React();
  const [dexiBalance, setDEXIBalance] = useState("0");

  const handleLogoutClick = () => {
    logoutWallet();
    logout({ returnTo: window.location.origin });
  };

  useEffect(() => {
    if (active && account) {
      getBalanceOfDEXI(chainId, library, account).then((balance) => {
        setDEXIBalance(String(balance));
      });
    }

    if (localStorage.getItem("walletconnect") && localStorage.getItem("walletId") === "2" ) {
      login(2);
    }
    if (localStorage.getItem("walletId") === "1" ) {
      login(1);
    }
    // maybe need to add ---  chainId === parseInt(config.REACT_APP_NETWORK_ID, 10)

    setLoginStatus(Boolean(active && account));
  }, [ account, active, login]);

  useEffect(() => {
    if (account && chainId !== parseInt(config.REACT_APP_NETWORK_ID, 10)) {
      toast.error(
        "Unsupported Network. This platform is working on Polygon"
      );
    }
  }, [account, chainId]);

  function onSelectWallet(wallet) {
    if (wallet === "Metamask") {
      login(1);
    } else if (wallet === "WalletConnect") {
      login(2);
    }
  }

  return (
    <StyledNav
      expand={"lg"}
      style={
        isCreatorHub
          ? { backgroundColor: "#005672", borderBottom: "5px solid #ffffff" }
          : { backgroundColor: "#2F0036", borderBottom: "5px solid #DA8027" }
      }
    >
      <Navbar.Brand>
        <Logo>
          <img
            style={{ width: "310px" }}
            src="/images/NFTEmporiumVerbiage.png"
            alt=""
          />
        </Logo>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className={"mainnav"}>
          <div className="d-flex align-items-center">
            <NavLink
              className="creatorHub"
              to="/creator"
              style={
                !isCreatorHub
                  ? {
                      backgroundColor: "#DA8027",
                      borderBottom: "5px solid #DA8027",
                      color: "#ffff",
                    }
                  : {
                      backgroundColor: "#ffffff",
                      borderBottom: "5px solid #02C573",
                      color: "#02C573",
                    }
              }
            >
              <img
                alt=""
                src="/logos/CreatorHubLogo.png"
                style={
                  isCreatorHub
                    ? { display: "inline-block" }
                    : { display: "none" }
                }
                className="mpLogo"
              />
              <p className="mb-0">
                {isCreatorHub ? "CreatorHub" : "Go to CreatorHub"}
              </p>
            </NavLink>
            <NavLink
              className="gameHub"
              to="/game"
              style={
                isCreatorHub
                  ? {
                      backgroundColor: "#0397D5",
                      borderBottom: "5px solid #ffffff",
                      color: "#ffff",
                    }
                  : {
                      backgroundColor: "#ffffff",
                      borderBottom: "5px solid #2F0036",
                      color: "#2F0036",
                    }
              }
            >
              <img
                alt=""
                src="/logos/GameHubLogo.png"
                style={
                  isCreatorHub
                    ? { display: "none" }
                    : { display: "inline-block" }
                }
                className="mpLogo"
              />
              <p className="mb-0">
                {isCreatorHub ? "Go to GameVault" : "GameVault"}
              </p>
            </NavLink>
          </div>

          <NavContainer>
            <NavItem
              style={{
                borderBottom:
                  location.pathname === "/game" ||
                  location.pathname === "/creator"
                    ? isCreatorHub
                      ? "2px solid #02C573"
                      : "2px solid #DA8027"
                    : "2px solid transparent",
              }}
            >
              <NavLink to={isCreatorHub ? "/creator" : "/game"}>Home</NavLink>
            </NavItem>
            <NavItem
              style={{
                borderBottom: location.pathname.includes("explore")
                  ? isCreatorHub
                    ? "2px solid #02C573"
                    : "2px solid #DA8027"
                  : "2px solid transparent",
              }}
            >
              <NavLink to={isCreatorHub ? "/creator/explore" : "/explore"}>
                Explore
              </NavLink>
            </NavItem>
            {(location.pathname.includes("creator") || isMinter) && (
              <NavItem
                style={{
                  borderBottom: location.pathname.includes("mint")
                    ? isCreatorHub
                      ? "2px solid #02C573"
                      : "2px solid #DA8027"
                    : "2px solid transparent",
                }}
              >
                <NavLink to={isCreatorHub ? "/creator/mint" : "/mint"}>Mint</NavLink>
              </NavItem>
            )}
            <NavItem
              style={{
                borderBottom: location.pathname.includes("faq")
                  ? isCreatorHub
                    ? "2px solid #02C573"
                    : "2px solid #DA8027"
                  : "2px solid transparent",
              }}
            >
              <NavLink
                to={
                  location.pathname.includes("creator")
                    ? "/creator/faq"
                    : "/faq"
                }
              >
                FAQ
              </NavLink>{" "}
            </NavItem>
            <NavItem
              style={{
                borderBottom: location.pathname.includes("support")
                  ? isCreatorHub
                    ? "2px solid #02C573"
                    : "2px solid #DA8027"
                  : "2px solid transparent",
              }}
            >
              <NavLink
                to={
                  location.pathname.includes("creator")
                    ? "/creator/support"
                    : "/support"
                }
              >
                Support
              </NavLink>{" "}
            </NavItem>
            {isAuthenticated && (
              <Nav
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                  marginRight: "1em",
                }}
              >
                <Image
                  className="ms-0 ms-lg-4 me-3 me-lg-0"
                  style={{ width: "30px", height: "30px", objectFit: "cover" }}
                  src={profile?.userAvatarUrl || user.picture}
                  roundedCircle
                />
                <NavDropdown align="end" title={user.email}>
                  {loginStatus ? (
                    <NavDropdown.Item as="span" style={{ background: "none" }}>
                      {dexiBalance} DEXI
                    </NavDropdown.Item>
                  ) : (
                    <NavDropdown.Item as="span">
                      <div
                        className="walletConnect"
                        style={{ backgroundColor: isCreatorHub ? "#005672" : "#2F0036" }}
                        onClick={() => setShowWallet(true)}
                      >
                        <Image src="/images/IconWallet.svg" /> Wallet connect
                      </div>{" "}
                    </NavDropdown.Item>
                  )}
                  <NavDropdown.Item as="span">
                    <NavLink
                      to={
                        location.pathname !== "/creator"
                          ? `/profile`
                          : "/creator/profile"
                      }
                    >
                      {/* <FontAwesomeIcon icon={faUser} style={{ marginRight: 10 }} /> */}
                      Profile
                    </NavLink>
                  </NavDropdown.Item>

                  <NavDropdown.Item
                    as="span"
                    style={{ cursor: "pointer" }}
                    onClick={handleLogoutClick}
                  >
                    {/* <FontAwesomeIcon icon={faEdit} style={{ marginRight: 6 }} /> */}
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            )}
            {!isAuthenticated && (
              <LoginBtn
                onClick={() => loginWithRedirect()}
                style={
                  location.pathname !== "/creator"
                    ? { borderBottom: "5px solid #4D4D4D" }
                    : { borderBottom: "5px solid #a0a0a0" }
                }
              >
                Login / Sign Up
              </LoginBtn>
            )}
          </NavContainer>
        </Nav>
      </Navbar.Collapse>

      {showWallet && (
        <Connect
          onClose={() => setShowWallet(false)}
          onWalletSelect={onSelectWallet}
        />
      )}
    </StyledNav>
  );
};

export default Header;
