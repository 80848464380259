import React, { useState, useEffect } from "react";
import SortDropdown from "../ResponsiveFilter/SortDropdown";
import { SideBarMenu } from "../ResponsiveFilter/Sort.styled";
import Filters from "components/ResponsiveFilter/Filters";
import { useUpdateEffect } from "react-use";

const SideBar = ({ searchFilter, isMarketPlace }) => {
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [sortBy, setSortBy] = useState("date|desc");
  const [availability, setAvailability] = useState("-1");
  const [level, setLevel] = useState("");

  useUpdateEffect(() => {
    searchFilter({ minPrice, maxPrice, category, subCategory, level, sortBy, availability,});
  }, [minPrice]);

  useUpdateEffect(() => {
    searchFilter({ minPrice, maxPrice, category, subCategory, level, sortBy, availability,});
  }, [maxPrice]);

  useUpdateEffect(() => {
    searchFilter({ minPrice, maxPrice, category, subCategory, level, sortBy, availability,});
  }, [category]);

  useUpdateEffect(() => {
    searchFilter({ minPrice, maxPrice, category, subCategory, level, sortBy, availability,});
  }, [subCategory]);

  useUpdateEffect(() => {
    searchFilter({ minPrice, maxPrice, category, subCategory, level, sortBy, availability,});
  }, [sortBy]);

  useUpdateEffect(() => {
    searchFilter({ minPrice, maxPrice, category, subCategory, level, sortBy, availability,});
  }, [availability]);

  useUpdateEffect(() => {
    searchFilter({ minPrice, maxPrice, category, subCategory, level, sortBy, availability,});
  }, [level]);

  return (
    <SideBarMenu className={isMarketPlace ? "creatorhub" : "gamehub"}>
      <SortDropdown
        filters={{ sortBy, setSortBy }}
        isMarketPlace={isMarketPlace}
      />
      <Filters
        isMarketPlace={isMarketPlace}
        filters={{
          minPrice,
          setMinPrice,
          maxPrice,
          setMaxPrice,
          category,
          setCategory,
          subCategory,
          setSubCategory,
          level,
          setLevel,
          availability,
          setAvailability,
        }}
      />
    </SideBarMenu>
  );
};

export default SideBar;
