import { CloseButton, Form } from "react-bootstrap";
import { InputWrapper, InputStyled, InputGroupStyled } from "./index.styled";
import {
  DeepMap,
  FieldError,
  FieldValues,
  UseFormRegisterReturn,
} from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

interface PropsType {
  className?: string;
  label?: string;
  preFix?: any;
  postFix?: any;
  helpText?: any;
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  step?: any;
  register?: UseFormRegisterReturn;
  errors?: DeepMap<FieldValues, FieldError>;
  children: any;
  onChange?: (data: any) => void;
  onReset?: () => void;
  defaultValue?: string;
}

const Input = ({
  className = "",
  label,
  preFix,
  postFix,
  helpText,
  placeholder = "",
  register,
  errors,
  disabled,
  onChange,
  children,
  defaultValue,
  onReset,
}: PropsType) => {
  const onChangeHandler = (event) => {
    const value = event.target.value;

    if (onChange) onChange(value);
  };

  const name = register?.name;

  return (
    <InputGroupStyled className={className}>
      {label && (
        <Form.Label>
          {label}
        </Form.Label>
      )}
      <InputWrapper
        className={
          (errors && errors[name] ? "is-invalid " : " ") +
          (disabled ? " disabled" : " ")
        }
      >
        {preFix && <InputGroupStyled.Text>{preFix}</InputGroupStyled.Text>}
        <InputStyled
          className={errors && errors[name] && "is-invalid"}
          placeholder={placeholder}
          defaultValue={defaultValue}
          onChange={onChangeHandler}
          {...register}
          disabled={disabled && disabled}
        >
          {children}
        </InputStyled>
        {postFix && <InputGroupStyled.Text>{postFix}</InputGroupStyled.Text>}
        {register && errors && (
          <ErrorMessage
            errors={errors}
            name={name}
            render={({ message }) => (
              <Form.Control.Feedback className="feedback" type="invalid">
                {message}
              </Form.Control.Feedback>
            )}
          />
        )}
      </InputWrapper>
      {helpText && <Form.Text muted>{helpText}</Form.Text>}
    </InputGroupStyled>
  );
};

export default Input;
