import CardsMedia from "components/mediaBox";
import styled from "styled-components";

export const FileBox = styled.div`
  width: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
  text-align: center;
  border-radius: 15px;
  min-height: 240px;
  height: auto;
  width: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
  text-align: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px,
    rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
`;

export const Overlay = styled.div`
  width: max-content;
  top: 50%;
  left: 50%;
  margin: 0;
  display: flex;
  padding: 10px;
  z-index: 1;
  position: absolute;
  transform: translate(-50%, -50px);
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  p {
    font-size: 14px;
    font-weight: 200;
    color: white;
  }
`;

export const FileButton = styled.label`
  display: inline-block;
  line-height: 1.5;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  user-select: none;
  transition: 0.25s ease-in-out;
  height: fit-content;
  width: fit-content;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 0.5rem;
  border: none;
  margin-top: 7px;
  color: #ffffff;
  background-color: ${({ theme }) => theme.primary[0]};
  transition: 0.25s ease-in-out;
  padding: 10px 22px;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px,
    rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  opacity: 0;

  input {
    display: none !important;
  }

  ${FileBox}:hover & {
    opacity: 1;
    background-color: ${({ theme }) => theme.primary[0]};
  }

  &:active {
    box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px,
      rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
  }
`;

export const MediaBox = styled(CardsMedia)`
  height: unset;
  overflow: unset;
  padding-top: unset;
  position: unset;

  margin: 0 !important;
`;

export const Label = styled.label`
  margin-bottom: 0.5rem;
  font-weight: 600;
  color: ${({ theme }) => theme.color[0]};
`;
