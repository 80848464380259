import { faImage } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { P } from "components/typography";
import { useEffect, useState } from "react";
import { FileBox, FileButton, Overlay, MediaBox, Label } from "./index.styled";

export interface FilePT {
  assetType?: string;
  assetUrl?: string;
  bannerImage?: string;
}

interface PropsType {
  className?: string;
  label?: string;
  info?: string;
  accept?: "Image" | "Video" | "Audio" | "All";
  btnlabel?: string;
  dispalyImage?: boolean;
  defaultImage?: FilePT;
  onChange?: (e, fileObj: FilePT) => void;
}

const FileInput = ({
  className,
  info,
  label,
  btnlabel,
  dispalyImage,
  defaultImage,
  accept = "All",
  onChange,
}: PropsType) => {
  const [fileObj, setFileObj] = useState<FilePT>({
    assetType: "",
    assetUrl: "",
    bannerImage: "",
  });

  useEffect(() => {
    setFileObj(defaultImage);
  }, [defaultImage]);

  const getExtension = (filename) => {
    var parts = filename.split(".");
    return parts[parts.length - 1];
  };

  const getAssetType = (filename) => {
    var ext = getExtension(filename);
    switch (ext.toLowerCase()) {
      case "jpg":
      case "gif":
      case "bmp":
      case "png":
        return "Image";
      case "m4v":
      case "avi":
      case "mpg":
      case "mp4":
        return "Video";
      case "mp3":
        return "Audio";
    }
    return "";
  };

  function getAcceptType(accept) {
    switch (accept) {
      case "Image":
        return "image/*";
      case "Video":
        return "video/*";
      case "Audio":
        return "audio/*";
      case "All":
        return "image/*, audio/*, video/*";
    }
    return "";
  }

  const onChangeFile = (e: any) => {
    if (e.target.files && e.target.files[0]) {
      var obj: FilePT = {
        assetType: getAssetType(e.target.files[0].name),
        assetUrl: URL.createObjectURL(e.target.files[0]),
      };
      onChange && onChange(e, obj);

      setFileObj((prevState) => ({ ...prevState, ...obj }));
    }
  };

  return (
    <>
      {label && <Label>{label}</Label>}
      <FileBox className={className}>
        <Overlay>
          {!fileObj && info && (
            <P style={{ fontSize: "1.2em" }} varient="secondary">
              {info}
            </P>
          )}
          <FileButton className="file-buttton">
            {btnlabel ? btnlabel : "Choose file"}{" "}
            <FontAwesomeIcon icon={faImage} className="ml-2" />
            <input
              type="file"
              accept={getAcceptType(accept)}
              onChange={onChangeFile}
            />
          </FileButton>
        </Overlay>
        {fileObj?.assetUrl && dispalyImage && (
          <MediaBox
            assetType={fileObj.assetType}
            assetUrl={fileObj.assetUrl}
            bannerImage={fileObj.bannerImage}
            controls
          />
        )}
      </FileBox>
    </>
  );
};

export default FileInput;
