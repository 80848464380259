import styled from "styled-components";

export const FootterContent = styled.div`
  display: flex;
  align-items: center;
  padding: 1rem 0;
  height: 80px;
  text-align: center;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;

  p {
    margin-top: 5px;
    margin-bottom: 0;
    color: #ffffff;
    font-size: 22px;
    font-family: "Oxanium";
  }

  @media (max-width: 768px) {
    & {
      height: 110px;
    }

    & > div > div > div:last-child {
      margin-top: 10px;
    }
  }
`;

export const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    margin-right: 1rem;
  }
`;
