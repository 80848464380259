import { Link } from "react-router-dom";

import Layout from "components/layout";
import {
  HomeContent,
  NftItem,
  NftSection,
  GameVault,
  CreatorHub,
  CardSection,
  CreatorHubCard,
  GameVaultCard,
  TopHeader,
  Background,
} from "./style";

const Home = () => (
  <Layout noHeader={true}>
    <HomeContent>
      <Background src="/images/background1.png" />
      <TopHeader>
        <img src="/images/emporium.png" alt="" />
      </TopHeader>
      <div style={{minHeight:"77vh"}}>
      <NftSection>
        <CreatorHub>
          <Link to={"/creator"}>
            <img src="/images/hubs.svg" alt="" />
            <h1>
              Go to the<br /> CreatorHub
            </h1>
          </Link>
        </CreatorHub>
        <NftItem>
          <img src="/images/Group9.svg" alt="" />
        </NftItem>
        <GameVault>
          <Link to={"/game"}>
            <h1>
              Go to the<br /> GameVault
            </h1>
            <img src="/images/game.svg" alt="" />
          </Link>
        </GameVault>
      </NftSection>
      <CardSection>
        <CreatorHubCard>
          <h5>What are CreatorHub NFTs?</h5>
          <p>
            The CreatorHub features NFTs created by artists in the Dexioprotocol community. It is a digital gallery for the modern art lover. The only place in the world where you can buy, sell, and create NFTs using the DEXI token. Found a CreatorHub NFT in DexiHunter? Connect today and list it for resale!
          </p>
          <p>
            Connect to the CreatorHub to find NFTs for sale or tap into your creative side and create digital art or music to sell yourself.
          </p>
        </CreatorHubCard>
        <GameVaultCard>
          <h5>What are GameVault NFTs?</h5>
          <p>
            DexiGameVault NFTs are special gaming assets you can use within the Dexioprotocol gaming ecosystem. These NFTs give you a new way to enhance your gaming experience. Level them up in-game or resell them to other players in the ecosystem.
          </p>
          <p>You don’t use NFTs? That’s okay too! All of our games have stores with non-blockchain in-game assets you can earn or purchase with DexiCash. Just head to any of the games and get started!
          </p>
        </GameVaultCard>
      </CardSection>
      </div>
    </HomeContent>
  </Layout>
);

export default Home;
