import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store";
import { PriceState, ProfileListState, ProfileState, State } from "./types";
import { fetchPrices } from "./prices";
import { fetchProfileList } from "./profileList";

// Profile
export const useProfile = () => {
  const { isInitialized, isLoading, userProfile, userNfts, userCollections }: ProfileState =
    useSelector((state: State) => state.profile);
  return {
    profile: userProfile,
    profileNfts: userNfts,
    profileCollections: userCollections,
    hasProfile: isInitialized,
    isInitialized,
    isLoading,
  };
};

export const useFetchProfileList = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(fetchProfileList());
  }, [dispatch]);
};

export const useProfileList = () => {
  const { isInitialized, isLoading, data }: ProfileListState = useSelector(
    (state: State) => state.profileList
  );
  return {
    profileList: data,
    hasProfile: isInitialized,
    isInitialized,
    isLoading,
  };
};

export const useProfileForWallet = (address: string) => {
  const { isInitialized, isLoading, data }: ProfileListState = useSelector(
    (state: State) => state.profileList
  );
  const profile =
    data && address
      ? data.find(
          (profile) =>
            profile.walletAddress?.toLowerCase() === address.toLowerCase()
        )
      : null;
  return {
    profile: profile,
    hasProfile: isInitialized,
    isInitialized,
    isLoading,
  };
};

// Prices
export const useFetchPriceList = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchPrices());
  }, [dispatch]);
};

export const useGetApiPrices = () => {
  const prices: PriceState["data"] = useSelector(
    (state: State) => state.prices.data
  );
  return prices;
};

export const useGetApiPrice = (address: string) => {
  const prices = useGetApiPrices();

  if (!prices) {
    return null;
  }

  return prices[address.toLowerCase()];
};
