import styled from "styled-components";
import {Card as BootstrapCard} from "react-bootstrap";

export const Card = styled(BootstrapCard)`
  padding: 0;
  background: transparent;
  border-radius: 0px;
  width: 100%;

  .card {
    background: #ffffff;
    border-radius: 0px;
    // max-width: 306px;
    overflow: hidden;
    border: none;

    .migrationTitle {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: 99;
      color: white;
      padding: 5px;
      text-align: center;
      background-color: red;
    }

    svg {
      display: inline-block;
      margin-right: 5px;
    }

    .userPic {
      width: 30px;
      height: 30px;
      border-radius: 50px;
    }

    .imageContainer {
      width: 100%;
      min-height: 313px;
      max-height: 313px;
      position: relative;
      overflow: hidden;

      .counter {
        background: #14141f;
        border-radius: 13px;
        font-family: Urbanist;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        text-transform: uppercase;

        color: #14141f;
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translate(-50%);
        padding: 0.2em 1em;
        width: 100%;
        max-width: 151px;

        img {
          margin-right: 0.5em;
        }
      }

      .heart {
        background: #14141f;
        border-radius: 10px;
        padding: 0.2em 1em;
        font-family: Urbanist;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        text-transform: uppercase;
        color: #ffffff;
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;

        img {
          margin-right: 0.5em;
        }
      }
    }

    h5 {
      font-family: Urbanist;
      font-style: normal;
      font-weight: bold;
      font-size: 19px;
      line-height: 26px;
      margin-top: 0.7rem;
      margin-left: 1rem;
      text-transform: capitalize;
      color: #650074;
      overflow: hidden;
      margin-bottom: 0;
      height: 50px;
      overflow: hidden;
    }
  }

  .tag {
    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: #ffffff;
    background: #5142fc;
    border-radius: 10px;
    padding: 0.2em 0.5em;
  }

  h4 {
    font-family: Urbanist;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    color: #ebebeb;
    margin: 0;
  }

  span {
    margin-right: 0.5rem;
    font-size: 14px;
    color: #7316a2;
    font-weight: 600;
  }

  p {
    font-family: Urbanist;
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    color: #7316a2;
    margin: 0;
  }

  .dexiBtn {
    background-color: #650074;
    color: #ffffff;
    min-width: 137px;
    padding: 0 10px;
    height: 53px;
    display: flex;
    font-size: 17px;
    font-weight: 700;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .soldWord {
    position: absolute;
    z-index: 999;
    top: 18px;
    left: 19px;
  }

  .weaponLevel {
    position: absolute;
    z-index: 999;
    top: 300px;
    right: 8px;
  }
}

&.creatorHub {
  .dexiBtn {
    background-color: #0397d5;
  }

  span,
  p,
  h5 {
    color: #0397d5;
  }
}

&.profilePage {
  .dexiBtn {
    background-color: #003847;
  }

  span,
  p,
  h5 {
    color: #003847;
  }
}
`;
