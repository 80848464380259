import styled from "styled-components";

export const Section = styled("div")`
  padding: 75px 0px 260px;
  font-family:"Titillium Web", sans-serif !important;
  .container {
    position: relative;
  }

  .accordion-button {
    background-color: rgb(0, 86, 114);
    color: white;
    font-weight: bold;
    font-size: 24px;
    border-radius: 0 !important;
    padding: 0.6em 1.6em;
    font-family:"Titillium Web", sans-serif !important;
  }
  .accordion-button:focus{
    box-shadow:none !important;
  }
  .accordion-body {
    font-family:"Titillium Web", sans-serif !important;
    background-color: rgb(0, 86, 114) !important;
    color: white !important;
    border-radius: 0;
    font-size:1.4em;
    a{
      color:white !important;
    }
  }
  .accordion-item {
    background-color: transparent;
  }
  h2 {
    color: rgb(0, 86, 114) !important;
  }
`;
