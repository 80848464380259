import styled from "styled-components";
import { Card as BootstrapCard } from "react-bootstrap";

export const Card = styled(BootstrapCard)`
  padding: 0;
  background: transparent;
  width: 100%;
  height: 610px !important;
  border-radius: 0px;
  position: relative;

  @media (max-width: 768px) {
    width: 100% !important;
    margin:auto;
  }

  .borderBox {
    background-color: #da8027;
    display: inline-block;
    position: absolute;
    width: 118px;
    height: 96px;
    bottom: -10px;
    left: -10px;
  }

  @media (max-width: 768px) {
    .borderBox {
      display: none;
    }
  }
  .card {
    background: #ffffff;
    border-radius: 0px;
    // max-width: 306px;
    overflow: hidden;
    border: none;

    @media (max-width: 768px) {
      width: 100% !important;
      height: 610px !important;
    }

    svg {
      display: inline-block;
      margin-right: 5px;
    }

    .userPic {
      width: 30px;
      height: 30px;
      border-radius: 0px;
    }
    .imageContainer {
      width: 100%;
      min-height: 488px;
      max-height: 347px;
      position: relative;
      overflow: hidden;
      .counter {
        background: #14141f;
        border-radius: 13px;
        font-family: Urbanist;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        text-transform: uppercase;

        color: #14141f;
        position: absolute;
        bottom: 5px;
        left: 50%;
        transform: translate(-50%);
        padding: 0.2em 1em;
        width: 100%;
        max-width: 151px;
        img {
          margin-right: 0.5em;
        }
      }
      .heart {
        background: #14141f;
        border-radius: 10px;
        padding: 0.2em 1em;
        font-family: Urbanist;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        text-transform: uppercase;
        color: #ffffff;
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
        img {
          margin-right: 0.5em;
        }
      }
    }
    h5 {
      font-family: Urbanist;
      font-style: normal;
      font-weight: bold;
      font-size: larger!important;
      margin-top: 0.7rem;
      margin-left: 1rem;
      margin-right: 130px;
      text-transform: capitalize;
      color: #650074;
      // height: 49px;
      overflow: hidden;
      margin-bottom: 0;
    }
    .tag {
      font-family: Urbanist;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      color: #ffffff;
      background: #5142fc;
      border-radius: 10px;
      padding: 0.2em 0.5em;
    }
    h4 {
      font-family: Urbanist;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      line-height: 22px;
      color: #ebebeb;
      margin: 0;
    }
    span {
      margin-right: 0.5rem;
      font-size: 14px;
      color: #7316a2;
      font-weight: 600;
    }
    p {
      font-family: Urbanist;
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 20px;
      color: #7316a2;
      margin: 0;
    }
    .dexiBtn {
      background-color: #650074;
      color: #ffffff;
      min-width: 137px;
      padding: 0 10px;
      height: 53px;
      display: flex;
      font-size: 17px;
      font-weight: 700;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .soldWord {
      position: absolute;
      z-index: 999;
      top: 18px;
      left: 19px;
    }

    .fullscreenWord {
      position: absolute;
      z-index: 999;
      top: 18px;
      left: 290px;
    }
    .weaponLevel {
      position: absolute;
      z-index: 999;
      top: 300px;
      right: 8px;
    }
  }

  &.creatorHub {
    .dexiBtn {
      background-color: #0397d5;
    }
    span,
    p,
    h5 {
      color: #0397d5;
    }
  }

  &.profilePage {
    .dexiBtn {
      background-color: #003847;
    }
    span,
    p,
    h5 {
      color: #003847;
    }
  }
  .nftEmporium {
    color: #7316a2;
    margin-left: 2rem;
    font-size: 20px !important;
  }
`;
export const ProfileName = styled.div`
  position: relative;
  .userImg {
    width: 100px;
    height: 100px;
    position: absolute;
    top: -50px;
    border: 10px solid #ffff;
    right: 28px;
    border-radius: 50%;
  }
  h5 {
    font-size: 30px !important;
    margin-top: 1rem !important;
  }
`;
