import axios, { AxiosRequestConfig } from "axios";

axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";
axios.defaults.headers.post["Access-Control-Allow-Credentials"] = true;

class API {
  constructor(API_URL) {
    this.apiUrl = API_URL;
  }
  public get = (path: string, config: AxiosRequestConfig = {}) => {
    return new Promise((resole, reject) => {
      axios
        .get(`${this.apiUrl}${path}`, config)
        .then(function (response) {
          String(response.status).startsWith("2")
            ? resole(response.data)
            : reject(new Error(response.statusText));
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };

  public post = (path: string, data: any, config: AxiosRequestConfig = {}) => {
    return new Promise((resole, reject) => {
      axios
        .post(`${this.apiUrl}${path}`, data, config)
        .then(function (response) {
          String(response.status).startsWith("2")
            ? resole(response.data)
            : reject(new Error(response.statusText));
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };

  public put = (path: string, data: any, config: AxiosRequestConfig = {}) => {
    return new Promise((resole, reject) => {
      axios
        .put(`${this.apiUrl}${path}`, data, config)
        .then(function (response) {
          String(response.status).startsWith("2")
            ? resole(response.data)
            : reject(new Error(response.statusText));
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };

  public apiUrl = "" // config.REACT_APP_API_MARKETPLACE;
}

const APIFactory = (apiUrl) => {
  return new API(apiUrl)
}
export default APIFactory;
