import { Col, Row, Container, Tab, Tabs, Image } from "react-bootstrap";
import { Section } from "./index.styled";
import { H1, H3 } from "components/typography";
import Button from "components/button";
import ProductCard from "components/card/product";
import Input from "components/form/input";
import TextArea from "components/form/textArea";
import { useForm, useWatch } from "react-hook-form";
import FileInput, { FilePT } from "components/form/file";
import { useEffect, useState } from "react";
import filters from "./filters.json";
import { Filter, FilterOption } from "components/filter";
import { useWeb3React } from "@web3-react/core";
import toast from "react-hot-toast";
import { getIpfsHash, readFileAsync } from "utils/ipfs";
import { mintAuction, mintFixed, mintUnlimitedAuction } from "utils/contracts";
import Layout from "components/layout";
import { useHistory } from "react-router";
import { NFTObjectData } from "hooks/useApi";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
import moment from "moment";
import { useProfile } from "store/hooks";

const ProductCreateScreen = () => {
  const {
    register,
    unregister,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  const [nftType, setNFTType] = useState<FilterOption>();
  const [nftAsset, setNFTAsset] = useState(null);
  const [nftAssetType, setNFTAssetType] = useState("");
  const [nftAssetBannerImage, setNFTAssetBannerImage] = useState(null);
  const [startTime, setNFTAuctionStartTime] = useState(new Date());
  const [endTime, setNFTAuctionEndTime] = useState(new Date());
  const [selectedNFTOption, setSelectedNFTOption] = useState("fixed_price");
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);
  const [categories, setCategories] = useState([]);
  const { library, chainId, account } = useWeb3React();
  const { profile } = useProfile();

  const [product, setProduct] = useState<NFTObjectData>();

  const updateProduct = (items) => {
    setProduct((prevState) => ({ ...prevState, ...items }));
  };

  useEffect(() => {
    const values = filters.filter((filter) =>
      filter.forSection.includes("create")
    );

    setCategories(values);
    if (values.length > 0) {
      setNFTType(values[0]);
    }
  }, []);

  useEffect(() => {
    updateProduct({ initialCreatorAddress: profile?.walletAddress });
  }, [profile]);

  useEffect(() => {
    unregister("minBidPrice");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeFile = (e: any, fileObject: FilePT) => {
    const file = e.target.files[0];
    updateProduct(fileObject);
    setNFTAsset(file);
    setNFTAssetType(fileObject.assetType);
  };

  const onChangeAudioBannerFile = (e: any, fileObject: FilePT) => {
    const file = e.target.files[0];
    updateProduct({ bannerImage: fileObject.assetUrl });
    setNFTAssetBannerImage(file);
  };

  const name = useWatch({ control, name: "name" });
  useEffect(() => {
    updateProduct({ name });
  }, [name]);

  const onSubmit = async (data) => {
    if (!account || !library) {
      toast.error("Please connect your wallet correctly!");
      return;
    }

    if (!nftAsset) {
      toast.error("Please select assets!");
      return;
    }

    if (selectedNFTOption === "fixed_price") {
      if (data.price <= 0) {
        return;
      }
    } else if (selectedNFTOption === "timed_auction") {
      if (data.minBidPrice <= 0) {
        return;
      }
      if (moment(startTime).valueOf() >= moment(endTime).valueOf()) {
        toast.error("End time should be late than start time!");
        return;
      }
    }

    const load_toast_id = toast.loading("Please wait...");
    setIsSaving(true);

    try {
      let metaData: any = {};
      let buffer = await readFileAsync(nftAsset);
      let hash = await getIpfsHash(buffer);
      let bannerImage = "";
      let txhash = null;

      const assetUrl = `https://ipfs.io/ipfs/${hash}`;

      if (nftAssetType === "Audio" && nftAssetBannerImage) {
        buffer = await readFileAsync(nftAssetBannerImage);
        hash = await getIpfsHash(buffer);
        bannerImage = `https://ipfs.io/ipfs/${hash}`;
      }

      if (nftAssetType === "Image") {
        metaData = {
          name: data.name,
          description: data.description,
          assetType: nftAssetType,
          image: assetUrl,
          attributes: data.attributes ? JSON.parse(data.attributes) : [],
          category: [nftType?.value, "sensitive"],
          collection: data.collection ? data.collection : "",
        };
      } else if (nftAssetType === "Video") {
        metaData = {
          name: data.name,
          description: data.description,
          assetType: nftAssetType,
          image: "",
          animation_url: assetUrl,
          attributes: data.attributes ? JSON.parse(data.attributes) : [],
          category: [nftType?.value, "sensitive"],
          collection: data.collection ? data.collection : "",
        };
      } else if (nftAssetType === "Audio") {
        metaData = {
          name: data.name,
          description: data.description,
          assetType: nftAssetType,
          image: bannerImage,
          animation_url: assetUrl,
          attributes: data.attributes ? JSON.parse(data.attributes) : [],
          category: [nftType?.value, "sensitive"],
          collection: data.collection ? data.collection : "",
        };
      }

      //nftType
      const metaDataHash = await getIpfsHash(
        Buffer.from(JSON.stringify(metaData), "utf-8")
      );

      const tokenURI = `https://ipfs.io/ipfs/${metaDataHash}`;

      if (selectedNFTOption === "fixed_price") {
        txhash = await mintFixed(
          chainId,
          library.getSigner(),
          tokenURI,
          data.price,
          10,
          data.quantity,
          account
        );
      } else if (selectedNFTOption === "timed_auction") {
        txhash = await mintAuction(
          chainId,
          library.getSigner(),
          tokenURI,
          data.minBidPrice,
          startTime,
          endTime,
          "10",
          account,
          data.quantity
        );
      } else if (selectedNFTOption === "unlimited_auction") {
        txhash = await mintUnlimitedAuction(
          chainId,
          library.getSigner(),
          tokenURI,
          "10",
          account,
          data.quantity
        );
      }

      if (txhash !== false) {
        toast.success("NFT Product is created successfully!");
        setTimeout(async () => {
          history.push("/explore");
        }, 3000);
      } else {
        toast.error("NFT Artist Create Failed!");
      }
    } catch (error) {
      toast.error("NFT Artist Create Failed!");
    } finally {
      toast.dismiss(load_toast_id);
    }
    setIsSaving(false);
  };

  function registerUnregisterMarketOptionFields(eventKey) {
    setSelectedNFTOption(eventKey);

    if (eventKey === "fixed_price") {
      register("price");
      unregister("minBidPrice");
    } else if (eventKey === "timed_auction") {
      register("minBidPrice");
      unregister("price");
    } else {
      unregister("price");
      unregister("minBidPrice");
    }
  }

  return (
    <Layout>
      <Section id="product-details">
        <Container>
          <Row className="filter-container">
            <Col xs={12} md={8}>
              <H1 style={{ color: "#FFFFFF" }}>Create </H1>
              <form onSubmit={handleSubmit(onSubmit)} className="mt-5">
                <Filter
                  className="mb-5 mb-md-2"
                  filterOptions={categories}
                  onSelect={setNFTType}
                />
                <FileInput
                  className="mb-5"
                  info="Select an image, audio or video file (up to 50MB)"
                  label="Upload file"
                  dispalyImage
                  onChange={onChangeFile}
                />
                {product?.assetType === "Audio" && (
                  <FileInput
                    className="mb-5"
                    info="JPG, GIF, BMP, PNG"
                    label="Banner image for audio"
                    accept="Image"
                    dispalyImage
                    onChange={onChangeAudioBannerFile}
                  />
                )}

                <Tabs
                  defaultActiveKey="fixed_price"
                  onSelect={(eventKey) =>
                    registerUnregisterMarketOptionFields(eventKey)
                  }
                  className="mb-3 createTabs"
                >
                  <Tab
                    eventKey="fixed_price"
                    title={
                      <div className="tab-btn">
                        <Image src="/images/tag.svg" /> Fixed Price
                      </div>
                    }
                  ></Tab>
                  <Tab
                    eventKey="timed_auction"
                    title={
                      <div className="tab-btn">
                        <Image src="/images/time.svg" /> Timed Auction
                      </div>
                    }
                  ></Tab>
                  <Tab
                    eventKey="unlimited_auction"
                    title={
                      <div className="tab-btn">
                        <Image src="/images/infinite.svg" /> Unlimited Auction
                      </div>
                    }
                  ></Tab>
                </Tabs>

                <Input
                  label="Name"
                  register={{
                    ...register("name", {
                      required: { message: "Name is required ", value: true },
                    }),
                  }}
                  errors={errors}
                />
                <TextArea
                  label="Description"
                  register={{
                    ...register("description", {
                      required: {
                        message: "Description is required ",
                        value: true,
                      },
                    }),
                  }}
                  errors={errors}
                />

                {selectedNFTOption === "fixed_price" && (
                  <>
                    <Input
                      label="Price"
                      type="number"
                      step="any"
                      register={{
                        ...register("price", {
                          required: {
                            message: "Price is required ",
                            value: true,
                          },
                          validate: (price: number) =>
                            price > 0 || "NFT Price is not valid !",
                        }),
                      }}
                      errors={errors}
                    />
                  </>
                )}

                <Input
                  label="Quantity"
                  type="number"
                  step="any"
                  register={{
                    ...register("quantity", {
                      required: {
                        message: "Quantity is required ",
                        value: true,
                      },
                      validate: (price: number) =>
                        price > 0 || "NFT Quantity is not valid !",
                    }),
                  }}
                  errors={errors}
                />

                {selectedNFTOption === "timed_auction" && (
                  <>
                    <Input
                      label="Minimum bid"
                      type="number"
                      step="any"
                      register={{
                        ...register("minBidPrice", {
                          required: {
                            message: "minBidPrice is required ",
                            value: true,
                          },
                          validate: (minBidPrice: number) =>
                            minBidPrice > 0 ||
                            "NFT Minimum Bid Price is not valid !",
                        }),
                      }}
                      errors={errors}
                      helpText="Bids below this amount won’t be allowed."
                    />
                    <Row>
                      <Col xs={12} md={6} className="mb-5">
                        <label className="mb-2">Starting Date</label>
                        <Datetime
                          value={startTime}
                          onChange={(dateTime) => {
                            if (typeof dateTime !== "string") {
                              setNFTAuctionStartTime(dateTime.toDate());
                            }
                          }}
                        />
                      </Col>
                      <Col xs={12} md={6} className="mb-5">
                        <label className="mb-2">End Date</label>
                        <Datetime
                          value={endTime}
                          onChange={(dateTime) => {
                            if (typeof dateTime !== "string") {
                              setNFTAuctionEndTime(dateTime.toDate());
                            }
                          }}
                        />
                      </Col>
                    </Row>
                  </>
                )}
                <TextArea
                  label="Attributes"
                  register={{
                    ...register("attributes", {
                      validate: (attributes: string) => {
                        try {
                          JSON.parse(attributes);
                        } catch (e) {
                          return "Validation error";
                        }
                        return true;
                      },
                    }),
                  }}
                  errors={errors}
                />
                <Button disabled={isSaving}>Create</Button>
              </form>
            </Col>
            <Col xs={12} md={{ span: 4 }}>
              <H3 className="mt-5" style={{ color: "#FFFFFF" }}>Preview</H3>
              <ProductCard disabled productObj={product} dontShowSold={true} />
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default ProductCreateScreen;
