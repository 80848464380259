import { NFTObjectData } from "hooks/useApi";
import ForSaleProductCard from "../forSaleProductCard";
import AuctionProductCard from "../auctionProductCard";
import SoldProductCard from "../soldProductCard";

interface NewProductCardProps {
  className?: string;
  disabled?: boolean;
  productObj: NFTObjectData;
  isRecent?: boolean;
  isProfile?: boolean;
  ref?:any
}

const NewProductCard = ({
  className = "",
  productObj,
  disabled,
  isProfile = false,
}: NewProductCardProps) => {
  switch (productObj.nftType) {
    case 0:
      if (productObj.listed) {
        return (
          <ForSaleProductCard
            productObj={productObj}
            className={className}
            disabled={disabled}
            isProfile={isProfile}
          />
        );
      }

      return (
        <SoldProductCard
          productObj={productObj}
          className={className}
          disabled={disabled}
          isProfile={isProfile}
        />
      );
    case 1:
      return (
        <AuctionProductCard
          productObj={productObj}
          className={className}
          disabled={disabled}
          isProfile={isProfile}
        />
      );
    case 3:
      if (productObj.listed) {
        return (
            <ForSaleProductCard
                productObj={productObj}
                className={className}
                disabled={disabled}
                isProfile={isProfile}
            />
        );
      }

      return (
          <SoldProductCard
              productObj={productObj}
              className={className}
              disabled={disabled}
              isProfile={isProfile}
          />
      );
    default:
      if (productObj.listed) {
        return (
          <ForSaleProductCard
            productObj={productObj}
            className={className}
            disabled={disabled}
            isProfile={isProfile}
          />
        );
      }

      return (
        <SoldProductCard
          productObj={productObj}
          className={className}
          disabled={disabled}
          isProfile={isProfile}
        />
      );   
  }
};
export default NewProductCard;
