import { useEffect, useState } from "react";
import environment from "../../environment";
import axios from "axios";
import { returnCDNImage } from "../../utils/cdnHelper";
import apiRequest from "../../utils/apiRequest";
import { getContractAddressByChainName } from "utils";
const baseApiUrl = environment.REACT_APP_API_MARKETPLACE;

export enum ENFTType {
  FIXED_PRICE = 0,
  AUCTION = 1,
  UNLIMITED_AUCTION = 2,
}

export interface NFTList {
  nftList: NFTObjectData[];
  totalCount: number;
}

export interface NFTObjectData {
  tokenID?: number;
  contractAddressAndTokenId?: string;
  contractAddress?: string;

  name?: string;
  description?: string;
  assetUrl?: string;
  assetType?: string;
  bannerImage?: string;
  cdnAssetUrl?: string;
  cdnBannerImage?: string;

  nftType?: ENFTType;
  price?: string;
  minBidPrice?: string;
  startTime?: string;
  endTime?: string;

  mintTransactionHash?: string;
  updatedAt?: number;
  createdAt?: number;
  initialCreatorAddress?: string;
  ownerAddress?: string;

  voteCount?: number;
  royalty?: number;
  listed?: boolean;
  attributes?: [];
  category?: [];

  approved?: boolean;
  verified?: boolean;
  featured?: boolean;
  visible?: boolean;
  collectionName?: string;


  creator?: any;
  owner?: any;
}

export interface NFTUser {
  walletAddress?: string;

  email: string;
  password: string;

  displayName?: string;
  customUrl?: string;
  userBio?: string;
  userAvatarUrl?: string;
  userBackgroupUrl?: string;

  socialPortfolioUrl?: string;
  socialTwitterUrl?: string;
  // socialVoyrUrl?: string;
  socialInstagramUrl?: string;
  socialYoutubeUrl?: string;

  accountCreatedAt?: Date;
  updatedAt?: Date;
  banned?: boolean;
  _id?: string;
  verified?: boolean;
}

export interface NFTEvent {
  doneOn?: number;
  eventType?: number;
  nftIDSold?: number;
  transactionHash?: string;

  minter?: string;
  nftType?: string;
  royalty?: string;
  price?: string;
  minBidPrice?: string;
  startTime?: string;
  endTime?: string;

  seller?: string;
  buyer?: string;
  nftSoldAtPrice?: number;

  priceUpdater?: string;
  newNftPrice?: number;
  oldNftPrice?: number;

  auctionStarter?: string;

  statusUpdater?: string;
  isListed?: boolean;

  bidder?: string;
  bidPrice?: number;
}

export interface NFTBid {
  doneOn?: number;
  tokenID?: number;
  transactionHash?: string;
  bidder?: string;
  price?: number;
  user?: NFTUser;
}

export interface NFTDetail {
  nft?: NFTObjectData;
  creator?: NFTUser;
  owner?: NFTUser;
  historyEvents?: NFTEvent[];
  resolvedTradingHistory?: any[];

  bids?: NFTBid[];
}

export interface NFTUserFullDetail {
  userProfile?: NFTUser;
  userNfts?: {
    createdNfts?: NFTObjectData[];
    currentNfts?: NFTObjectData[];
    boughtNfts?: NFTObjectData[];
    soldNfts?: NFTObjectData[];
    likedNfts?: NFTObjectData[];
  };
}

export interface NFTTopArtist {
  user?: NFTUser;
  soldAmount?: number;
  createdNFTs?: NFTObjectData[];
}

export interface NFTCollection {
  name?: string;
  description?: string;
  user?: NFTUser;
  _id?: string;
}

export interface Likes {
  name?: string;
  description?: string;
  user?: NFTUser;
  _id?: string;
}

export const useGetFeaturedListing = ({ count }) => {
  const [data, setData] = useState<any>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let strUrl;
        strUrl = `${baseApiUrl}/getFeaturedListing?count=${count}`;

        const response = await fetch(strUrl);
        const responseData = await response.json();
        if (responseData.status === "success") {
          const nftObjectList: any = responseData;
          setData(nftObjectList);
        }
      } catch (error) {
        console.error("Unable to fetch data:", error);
      }
    };

    fetchData();
  }, [setData, count]);

  return data;
};

export const useGetNFTObjectList = ({
  start,
  count,
  category = "",
  sortField = "",
  sortOrder = "",
  nftType = "",
  SearchIs = "",
  showCollections = false,
}) => {
  const [data, setData] = useState<any>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let strUrl;
        if (showCollections)
          strUrl = `${baseApiUrl}/getCollectionList?sortField=${sortField}&sortOrder=${sortOrder}&search=${SearchIs}`;
        else
          strUrl = `${baseApiUrl}/getNFTObjectList?start=${start}&count=${count}&category=${category}&sortField=${sortField}&sortOrder=${sortOrder}&nftType=${nftType}&search=${SearchIs}`;

        const response = await fetch(strUrl);
        const responseData = await response.json();
        if (responseData.status === "success") {
          const nftObjectList: any = responseData;

          setData(nftObjectList);
        }
      } catch (error) {
        console.error("Unable to fetch data:", error);
      }
    };

    fetchData();
  }, [
    setData,
    start,
    count,
    category,
    sortField,
    sortOrder,
    nftType,
    SearchIs,
    showCollections,
  ]);

  return data;
};

export const getMPNftUserDetailsByCustomUrl = async (customUrl) => {
  const strUrl = `${baseApiUrl}/custom_url?isearchusers={ "custom_url": "${customUrl}"}`;
  let response = await apiRequest({
    method: "GET",
    url: strUrl,
  });

  if (response) {
    return response.data.data.search;
  }
};
export const useGetNFTObjectDetail = (tokenID, chainName) => {
  const [data, setData] = useState<NFTDetail>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const strUrl = `${baseApiUrl}/nfts/${getContractAddressByChainName(chainName, 'CREATORHUBNFT')}/${tokenID}`;
        const response = await fetch(strUrl);
        const responseData = await response.json();
        if (responseData.status === "success") {
          const nftDetail: NFTDetail = responseData.nftDetail;
          nftDetail.nft.cdnAssetUrl = returnCDNImage(
            nftDetail.nft.assetType,
            nftDetail.nft.assetUrl
          );
          nftDetail.nft.cdnBannerImage = returnCDNImage(
            nftDetail.nft.assetType,
            nftDetail.nft.bannerImage
          );
          nftDetail.nft.price = nftDetail.nft.price
            ? parseInt(nftDetail.nft.price).toString()
            : "0";

          setData(nftDetail);
        }
      } catch (error) {
        console.error("Unable to fetch data:", error);
      }
    };

    fetchData();
  }, [chainName, setData, tokenID]);

  return data;
};

export const useGetNFTUserList = () => {
  const [data, setData] = useState<NFTUser[]>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const strUrl = `${baseApiUrl}/getNFTUserList`;
        const response = await fetch(strUrl);
        const responseData = await response.json();
        if (responseData.status === "success") {
          const userList: NFTUser[] = responseData.nftUserList;
          setData(userList);
        }
      } catch (error) {
        console.error("Unable to fetch data:", error);
      }
    };

    fetchData();
  }, [setData]);

  return data;
};

export const useGetNFTTopUserList = () => {
  const [data, setData] = useState<NFTTopArtist[]>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const strUrl = `${baseApiUrl}/getNFTTopUserList`;
        const response = await fetch(strUrl);
        const responseData = await response.json();
        if (responseData.status === "success") {
          const nftTopArtists: NFTTopArtist[] = responseData.data;
          setData(nftTopArtists);
        }
      } catch (error) {
        console.error("Unable to fetch data:", error);
      }
    };

    fetchData();
  }, [setData]);

  return data;
};

export const searchByWord = async ({ query, collectionSearch = true }) => {
  try {
    const response = await axios({
      url: `${baseApiUrl}/searchv2`,
      params: {
        isearchprofiles: `{"searchString": "${query}"}`,
        isearchobjects: `{"searchString": "${query}"}`,
        isearchcollections: `{"${collectionSearch ? 'searchString' : 'collectionName'}" : "${query}"}`
      },
      method: "GET",
    });

    const responseData = response.data.data;
    if (responseData) {
      return responseData.search;
    }
  } catch (error) {
    console.error("Unable to create a collection:", error);

    return false;
  }
};

export const searchNfts = async (
  { minPrice, maxPrice, sortBy, availability },
  pageNum
) => {
  try {
    let baseString = `${baseApiUrl}/filter?`;

    if (minPrice || maxPrice) {
      baseString =
        baseString +
        `isearchprice={"minPrice": ${minPrice ? minPrice : 0},"maxPrice":${
          maxPrice ? maxPrice : 1000000000000
        }}&`;
    } else baseString = baseString + `isearchprice={}&`;

    if (availability !== "-1") {
      baseString =
        baseString + `isearchavailability={"listed": ${availability}}&`;
    } else baseString = baseString + `isearchavailability={}&`;

    if (sortBy) {
      baseString = baseString + `sortBy=${sortBy.split("|")[0]}&`;
      if (sortBy.includes("desc")) baseString = baseString + `&desc=true&`;
    }

    baseString =
      baseString + `&pageNum=${pageNum > 0 ? pageNum / 16 : 0}&pageSize=16`;

    let response = await axios({
      url: baseString,
      method: "GET",
    });

    const responseData = response.data.data;
    if (responseData) {
      return {
        results: responseData.objects,
        searchCount: responseData.searchCount,
      };
    }
  } catch (error) {
    console.error("Unable to create a collection:", error);

    return false;
  }
};

export const useGetNFTUserFullDetail = (walletAddress) => {
  const [data, setData] = useState<NFTUserFullDetail>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const strUrl = `${baseApiUrl}/getNFTUserFullDetail/${walletAddress}`;
        const response = await fetch(strUrl);
        const responseData = await response.json();
        if (responseData.status === "success") {
          const nftUserFullDetail: NFTUserFullDetail = responseData.data;
          setData(nftUserFullDetail);
        }
      } catch (error) {
        console.error("Unable to fetch data:", error);
      }
    };

    fetchData();
  }, [setData, walletAddress]);

  return data;
};

export const getNFTUserFullDetail = async (walletAddress) => {
  const strUrl = `${baseApiUrl}/getNFTUserFullDetail/${walletAddress}`;
  let response = await apiRequest({
    method: "GET",
    url: strUrl,
  });

  if (response) {
    return response.data.data;
  }
};

export const useGetNFTUserProfile = (walletAddress) => {
  const [data, setData] = useState<NFTUser>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const strUrl = `${baseApiUrl}/getNFTUserProfile/${walletAddress}`;
        const response = await fetch(strUrl);
        const responseData = await response.json();
        if (responseData.status === "success") {
          const nftUser: NFTUser = responseData.nftUserProfile;
          setData(nftUser);
        }
      } catch (error) {
        console.error("Unable to fetch data:", error);
      }
    };

    fetchData();
  }, [setData, walletAddress]);

  return data;
};

export const searchByCollectionSlug = async ({ collectionSlug }) => {
  try {
    const response = await axios({
      url: `${baseApiUrl}/collections/slug/${collectionSlug}`,
      method: "GET",
    });

    const responseData = response.data.data[0];
    return responseData;
  } catch (error) {
    console.error("Unable to find a collection:", error);

    return false;
  }
};

export const useGetCollections = (tokenID) => {
  const [data, setData] = useState<NFTCollection[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const strUrl = `${baseApiUrl}/collections/${tokenID}`;
        const response = await fetch(strUrl);
        const responseData = await response.json();

        if (responseData.status === "success") {
          const nftDetail: NFTCollection[] = responseData.collections;
          setData(nftDetail);
        }
      } catch (error) {
        console.error("Unable to fetch data:", error);
      }
    };

    fetchData();
  }, [setData, tokenID]);

  return data;
};

export const createCollection = async (data) => {
  try {
    const strUrl = `${baseApiUrl}/collections`;
    const response = await fetch(strUrl, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    const responseData = await response.json();
    if (responseData.status === "success") {
      return true;
    }
  } catch (error) {
    console.error("Unable to create a collection:", error);

    return false;
  }
};

export const likeDislikeCreator = async (userFollowed, followedBy) => {
  try {
    const strUrl = `${baseApiUrl}/follows`;
    const response = await fetch(strUrl, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      headers: {
        "Content-Type": "application/json",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify({ userFollowed, followedBy }),
    });
    const responseData = await response.json();
    if (responseData.status === "success") {
      return responseData.data;
    }
  } catch (error) {
    console.error("Unable to like a user:", error);
    return false;
  }
};

export const ReadFollows = (userId, fetchData) => {
  const [data, setData] = useState<Likes[]>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userId) {
          const strUrl = `${baseApiUrl}/follows/${userId}`;
          const response = await fetch(strUrl);
          const responseData = await response.json();

          if (responseData.status === "success") {
            const nftDetail: Likes[] = responseData.data;
            setData(nftDetail);
          }
        }
      } catch (error) {
        console.error("Unable to fetch data:", error);
      }
    };

    fetchData();
  }, [setData, fetchData, userId]);

  return data;
};

export const attachUserToWallet = async (
  dexiId: string,
  walletAddress: string
) => {
  try {
    const response = await fetch(`${baseApiUrl}/users/attach-user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ dexiId, walletAddress }),
    });
    const responseData = await response.json();
    if (responseData.status === "success") {
      return responseData.data;
    }
  } catch (error) {
    console.error("Unable to attach a user:", error);
    return false;
  }
};

export const detachUserFromWallet = async (
  dexiId: string,
  walletAddress: string
) => {
  try {
    const response = await fetch(`${baseApiUrl}/users/detach-user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ dexiId, walletAddress }),
    });
    const responseData = await response.json();
    if (responseData.status === "success") {
      return responseData.data;
    }
  } catch (error) {
    console.error("Unable to attach a user:", error);
    return false;
  }
};
