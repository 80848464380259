import {ProfileResponse} from "store/types";
import axios from "axios";
import {baseApiUrl} from "utils";
import environment from "environment";

const getWalletContents = async (userId, walletAddress) => {
    const defaultReturn = {userNfts: {ownedNfts: [], boughtNfts: [], createdNfts: [], currentNfts: [], soldNfts: [], transferredNfts: []}, userCollections: [{collectionName: ''}]}

    if (!walletAddress) {
        return defaultReturn
    }

    try {
        const [profileCHNftsResponse, profileGVNftsResponse, profileCollectionsResponse] = await Promise.all([
            axios({
                method: "get",
                url: `${environment.REACT_APP_API_MARKETPLACE}/getNFTUserFullDetail/${walletAddress}`,
            }),
            axios({
                method: "get",
                url: `${environment.REACT_APP_API_URL}/getNFTUserFullDetail/${walletAddress}`,
            }),
            axios({
                method: "get",
                url: `${environment.REACT_APP_API_MARKETPLACE}/collections/${walletAddress}`,
            })
        ]);
        const ch = JSON.parse(JSON.stringify(profileCHNftsResponse.data?.data?.userNfts))
        const gv = JSON.parse(JSON.stringify(profileGVNftsResponse.data?.data?.userNfts))

        let userNfts = {
            ownedNfts: [].concat(ch.ownedNfts, gv.ownedNfts),
            boughtNfts: [].concat(ch.boughtNfts, gv.boughtNfts),
            createdNfts: [].concat(ch.createdNfts, gv.createdNfts),
            currentNfts: [].concat(ch.currentNfts, gv.currentNfts),
            soldNfts: [].concat(ch.soldNfts, gv.soldNfts),
            transferredNfts: [].concat(ch.transferredNfts, gv.transferredNfts),
        }
        return ({userNfts, userCollections: profileCollectionsResponse.data?.data})
    } catch(error) {
        console.log(error)
        return defaultReturn;
    }
}

const getProfile = async (userId: string): Promise<ProfileResponse> => {
    try {
        const profileUrl = `${baseApiUrl}/v2/users/profile/${userId}`;

        const [profileResponse] = await Promise.all([
            axios({
                method: "get",
                url: profileUrl,
            }),
        ]);

        const walletAddress = profileResponse.data?.data?.userProfile?.walletAddress;

        const {
            userNfts,
            userCollections,
        } = await getWalletContents(userId, walletAddress)

        if (profileResponse.status === 200) {
            const dataUserProfile: ProfileResponse = profileResponse.data?.data;
            const knightsGameProfile = {
                profile: {
                    Username: '',
                }
            };

            return {
                userProfile: {
                    ...dataUserProfile.userProfile,
                    knightsGameProfile,
                },
                userNfts: userNfts,
                userCollections: userCollections,
            };
        }

        return null;
    } catch (error) {
        return null;
    }
};

export default getProfile;
