import { useState, useCallback } from "react";
import { withThumbnail } from "utils/with-thumbnail";
import { Audio, CardMediaPreview, Media, NOMedia, Video } from "./styled";
import { useInView } from "react-intersection-observer";
import { useMountedState, useUpdateEffect } from "react-use";
import { LoadingOutlined } from "@ant-design/icons";

interface PropsType {
  productId?: string;
  className?: string;
  assetType: string;
  assetUrl: string;
  bannerImage?: string;
  controls?: boolean;
  autoPlay?: boolean;
  muted?: boolean;
  loop?: boolean;
  onClick?: () => void;
  showFullScreen?:boolean
}

const {
  REACT_APP_GENERATE_IMAGES: GENERATE_IMAGES
} = process.env;
const CardsMedia = ({
  productId,
  className = "",
  assetType,
  assetUrl,
  bannerImage,
  controls = false,
  autoPlay = true,
  muted = true,
  loop = true,
  onClick, showFullScreen = false
}: PropsType) => {
  const isMounted = useMountedState();
  const { ref, inView } = useInView();
  const [isLoaded, setLoaded] = useState(false);
  const [isError, setError] = useState(false);
  const [detected, setDetected] = useState(false);

  const handleLoad = useCallback(() => {
    if (isMounted()) setLoaded(true);
  }, [isMounted]);

  const handleError = useCallback(
    (e) => {
      if (isMounted()) setError(true);
    },
    [isMounted]
  );

  useUpdateEffect(() => {
    let image: HTMLImageElement;

    if (detected && assetType === "Image") {
      image = new Image();
      image.addEventListener("load", handleLoad);
      image.addEventListener("error", handleError);
      image.src = assetUrl;
    }

    return () => {
      if (image) {
        image.removeEventListener("load", handleLoad);
        image.removeEventListener("error", handleError);
      }
      setLoaded(false);
      setError(false);
    };
  }, [assetUrl, detected]);

  useUpdateEffect(() => {
    if (inView) {
      setDetected(true);
    }
  }, [inView]);

  assetUrl = assetUrl?.replace(
    "https://ipfs.io",
    "https://nfthub.infura-ipfs.io"
  );
  if ((!isLoaded || isError) && assetType === "Image") {

    return (
      <p
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        ref={ref}
      >
        <LoadingOutlined />
        Loading
      </p>
    );
  }

  return (
    <CardMediaPreview
      className={className}
      onClick={() => onClick && onClick()}
    >
      {assetType === "Image" ? (
        <Media src={`${assetUrl}`}  useOriginal={showFullScreen}/>
      ) : assetType === "Video" ? (
        <Video autoPlay={autoPlay} muted loop={loop}>
          <source src={assetUrl} type="video/webm" />
        </Video>
      ) : assetType === "Audio" ? (
        <div className="audio-wrapper">
          {bannerImage && <Media src={`${bannerImage}`}   useOriginal={showFullScreen} />}
          <Audio
            src={assetUrl}
            controls={controls}
            autoPlay={autoPlay}
            muted={muted}
            loop={loop}
          />
        </div>
      ) : (
        <NOMedia>No preview</NOMedia>
      )}
    </CardMediaPreview>
  );
};

export default CardsMedia;
