import styled from "styled-components";

export const Filter = styled.div`
  &.creatorhub {
    background-color: #0397d515 !important;
  }

  &.gamehub {
    background-color: #8b00b615 !important;
  }
  margin-top: 1rem;
  padding: 1rem 0rem 0.5rem 0rem;
  color: #ffffff;
  padding-bottom: 1rem;
  h5 {
    color: #4e4e4e;
    font-weight: 700;
    text-align: center;
    font-size: 1.2em;
  }
`;

export const FilterCard = styled.div`
  margin: 0.8rem;
  border: 1px solid transparent !important;
  margin-left: 0;

  .ant-form-item-label > label {
    color: white !important;
  }

  .accordionBtn {
    button {
      color: #ffffff !important;
      height: 40px;
      font-size: 18px;
      font-weight: 600;
      position: relative;
      box-shadow: none;
      span {
        position: absolute;
        right: 20px;
        transform: rotate(0deg);
        transition: 200ms;
      }
      &:focus {
        color: #ffffff;
        border: none !important;
        box-shadow: none !important;
      }
      &::after {
        display: none !important;
      }
      &.collapsed {
        span {
          transform: rotate(-90deg) !important;
        }
      }
    }
  }

  &.creatorhub {
    .accordionBtn {
      button {
        background-color: #02c573 !important;
      }
    }
  }

  &.gamehub {
    .accordionBtn {
      button {
        background-color: #650074 !important;
      }
    }
  }

  .accordionBody {
    background-color: #65007415 !important;
    text-align: left;

    div {
      .checkedBox {
        color: #ffffff !important;
        border-bottom: 1px solid #d9d9d9;
      }
      .priceInput {
        background-color: rgba(255, 255, 255, 0.45) !important;
        color: #ffffff !important;
        height: 25px;
        width: 100%;
        font-size: 13px;
        border: 2px solid #65007415 !important;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
`;

export const Types = styled.div`
  .typesDropdown {
    background-color: #65007415 !important;
    border: 1px solid transparent !important;
    border-radius: 30px !important;
  }
  .typesBtn {
    button {
      background-color: #65007415 !important;
      border: none !important;
      border-radius: 0 !important;
      position: relative;
      font-size: 15px;
      height: 30px;
      text-align: left !important;
      font-weight: 400;
      padding: 0 1.5rem 0 0 !important;
      color: #ffffff;
      span {
        position: absolute;
        right: 0px;
        transform: rotate(0deg);
        transition: 200ms;
      }
      &:focus {
        background-color: #171731 !important;
        border: none !important;
        border: none !important;
        box-shadow: none !important;
      }
      &::after {
        display: none !important;
      }
      &.collapsed {
        span {
          transform: rotate(-90deg) !important;
        }
      }
    }
  }
  .typesBody {
    border-radius: 0 !important;
    padding: 0.2rem 0 !important;
  }
`;

export const Clear = styled.button`
  background-color: #17173175;
  border: 1px solid #3c3777;
  border-radius: 30px;
  padding: 0 1rem;
  font-size: 16px;
  width: 130px;
  letter-spacing: 1px;
  height: 40px;
  color: #ffffff;
`;
export const Done = styled.button`
  background-color: #3c377775;
  border: 1px solid #3c377775;
  border-radius: 30px;
  padding: 0 2rem;
  font-size: 16px;
  width: 130px;
  letter-spacing: 1px;
  height: 40px;
  color: #ffffff;
`;

export const Menu = styled.div`
  position: absolute;
  background-color: transparent;
  top: 40px;
  transition: 300ms;
  width: 100%;
  // height: 100%;
  .menuContent {
    background-color: transparent;
    border-radius: 30px !important;
    margin: 0 1rem;
  }
`;
export const SaveBtn = styled.div`
  display: flex;
  background-color: #8b00b615;
  justify-content: space-between;
  padding: 1rem 2rem;
  // border-radius: 0 0 30px 30px !important;
  // position: absolute;
  // right: 0;
  // left: 0;
  // bottom: 40px;
  align-items: center;
`;
