import { useEffect, useState } from "react";
import { Modal, Row, Col, Button } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import toast from "react-hot-toast";
import { useWeb3React } from "@web3-react/core";
import {useDispatch} from "react-redux";

import LinkButton from "components/button/link";
import APIFactory from "utils/api";
import { fetchProfileCollections } from "store/profile";
import { Collection } from "store/types";
import { returnCDNImage } from "utils/cdnHelper";
import { Product } from "./styled";
import config from "environment";

type AddNftsModalProps = {
  onClose: () => void;
  show: boolean;
  selectedCollection: Collection;
};
const API_URL = config.REACT_APP_API_MARKETPLACE;
const putPath="/collections"

export const AddNftsModal = ({ onClose, show, selectedCollection }: AddNftsModalProps) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [nfts, setNfts] = useState([]);
  const [activeNfts, setActiveNfts] = useState([]);
  const { account } = useWeb3React();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onSubmit = async () => {
    setLoading(true);
    const token = await getAccessTokenSilently();
    const userId = user["https://dexioprotocol.com/userId"];
    APIFactory(API_URL).put(putPath, {
      userId,
      walletAddress: account,
      collectionName: selectedCollection.collectionName,
      newCollectionName: selectedCollection.collectionName,
      description: selectedCollection.description,
      nfts: [...selectedCollection.NFTs, ...activeNfts]
    }, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        toast.success("Collection updated!");
        setActiveNfts([]);
        onClose();
        dispatch(fetchProfileCollections(userId, token));
      })
      .catch(() => {
        toast.error("Error was occured. Please, try again later!");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleNftClick = (id: string) => {
    setActiveNfts(activeNfts.includes(id) ? activeNfts.filter(nftId => nftId !== id) : [...activeNfts, id]);
  }

  useEffect(() => {
    let collectionNfts = [];
    const getNFTs = async () => {
      const token = await getAccessTokenSilently();
      const userId = user["https://dexioprotocol.com/userId"];
      APIFactory(API_URL).get(`/collections/nfts/${userId}/${account}`, {
        headers: { Authorization: `Bearer ${token}` },
      }).then((data: any) => {
        data.data?.forEach(nft => {
          if (!selectedCollection?.NFTs?.includes(nft.contractAddressAndTokenId)) {
            collectionNfts.push({
              ...nft,
              cdnAssetUrl: returnCDNImage(nft.assetType, nft.assetUrl),
              cdnBannerImage: returnCDNImage(nft.assetType, nft.bannerImage),
            });
          }
        })
        setNfts(collectionNfts);
      })
    }
    getNFTs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCollection]);

  return (
    <Modal size="xl" show={show} onHide={onClose} scrollable centered backdrop="static">
      <Modal.Body>
        <Modal.Title className="mb-4">Add NFTs to "{selectedCollection?.collectionName}"</Modal.Title>
        <Row>
          {nfts.map((product: any, index: any) => (
            <Col xl={6} xxl={4} key={index} onClick={() => handleNftClick(product.contractAddressAndTokenId)}>
              <Product
                active={activeNfts.includes(product.contractAddressAndTokenId)}
                disabled
                productObj={product}
                isProfile={true}
              />
            </Col>
          ))}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={loading} onClick={onSubmit}>Update collection</Button>
        <LinkButton onClick={onClose}>Close</LinkButton>
      </Modal.Footer>
    </Modal>
  );
};
