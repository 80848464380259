import { Input } from "antd";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import Layout from "components/layout";
import Swiper from "components/swiper";
import { searchNfts } from "marketplace/hooks/useApi";
import { useHistory } from "react-router-dom";
import { Carousel, CreatorHub, Heading, Pera } from "./index.styled";
import { NFTObjectData } from "../../hooks";
import { returnCDNImage } from "../../utils/cdnHelper";

const PAGE_NUM = 0;

const Creator = () => {
  const [nfts, setNfts] = useState([]);
  const history = useHistory();
  const [searchVal, setSearchVal] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      const response = await searchNfts(
        {
          minPrice: null,
          maxPrice: null,
          sortBy: "date|desc",
          availability: "-1",
        },
        PAGE_NUM
      );

      if(response){          
        const swiperList = response.results.slice(0, 10)
        swiperList.forEach((nft: NFTObjectData) => {
            nft.cdnAssetUrl = returnCDNImage(nft.assetType, nft.assetUrl);
          });
        setNfts(swiperList);}
      };

    fetchData();
  }, []);

  return (
    <Layout>
      <CreatorHub>
        <Input
          placeholder="Search"
          onChange={(e) => setSearchVal(e.target.value)}
          value={searchVal}
          onKeyPress={(e) => {
            if (e.key === "Enter") history.push({ pathname: `/creator/explore`, search: `q=${searchVal}` })
          }}
        />
        <Container>
          <Pera>
            <b>Welcome to the CreatorHub!</b>
          </Pera>          
          <Pera>
            The CreatorHub is a digital art gallery designed for the modern art lover. Here you will find NFTs created by artists in the Dexioprotocol community. Tap into your creative side and create digital art or music yourself. You don’t want to tap into your creative side just yet? That is okay! Check out all of the amazing artists and start your very own private NFT collection today!
          </Pera>
          <Pera>
            See the latest creations below or head to the Explore tab to find more.
          </Pera>
          <Heading> <b>Latest Creator NFTs</b> </Heading>
        </Container>
        <Carousel>
          <Swiper nfts={nfts} />
        </Carousel>
      </CreatorHub>
    </Layout>
  );
};

export default Creator;
