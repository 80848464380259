const data = [
  {
    name: 'Report NFT',
    explanation:
      'Please provide the Product ID or URL of the NFT you are reporting and a brief explanation or support for the DexiMarketplace violation.',
  },
  {
    name: 'Verification Request',
    explanation: 'Please provide the link to your DexiMarketplace Profile.',
  },
  {
    name: 'Minting Issue(s)',
    explanation:
      'Please provide a detailed explanation of the issues you are experiencing, including size and file type being minted, the error message that is produced, or additional information that will help the team determine root cause.',
  },
  {
    name: 'Help! The NFT I purchased is missing',
    explanation:
      'Please provide the ID of the NFT purchased, the transaction hash from BscScan showing the purchase was completed, and the wallet you are using to store your collectibles.',
  },
  {
    name: 'Marketplace Suggestions',
    explanation:
      "We are striving for continuous improvement and value user feedback. Please let us know what would improve our community's experience in the DexiMarketplace.",
  },
  {
    name: 'Other Support',
    explanation: 'Enter your comments or questions here and a member of our support team will review.',
  },
];

export default data;
