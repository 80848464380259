import { useState } from "react";
import { useUpdateEffect } from "react-use";
import SortDropdown from "./SortDropdown";
import Filters from "./Filters";
import { Col, Row } from "react-bootstrap";
import { BsFilter } from "react-icons/bs";
import { Menu, Clear, Done, SaveBtn } from "./Filter.styled";
import { Button, SideBarMenu } from "./ResponsiveFilter.styled";
import { useToggle } from "react-use";

export const ResponsiveFilter = ({ searchFilter, isMarketPlace }) => {
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [sortBy, setSortBy] = useState("date|desc");
  const [availability, setAvailability] = useState("-1");
  const [showSidebar, setShowSidebar] = useToggle(false);
  const [level, setLevel] = useState("");

  useUpdateEffect(() => {
    searchFilter({ minPrice, maxPrice, category, subCategory, level, sortBy, availability,});
  }, [minPrice]);

  useUpdateEffect(() => {
    searchFilter({ minPrice, maxPrice, category, subCategory, level, sortBy, availability,});
  }, [maxPrice]);

  useUpdateEffect(() => {
    searchFilter({ minPrice, maxPrice, category, subCategory, level, sortBy, availability,});
  }, [category]);

  useUpdateEffect(() => {
    searchFilter({ minPrice, maxPrice, category, subCategory, level, sortBy, availability,});
  }, [subCategory]);

  useUpdateEffect(() => {
    searchFilter({ minPrice, maxPrice, category, subCategory, level, sortBy, availability,});
  }, [sortBy]);

  useUpdateEffect(() => {
    searchFilter({ minPrice, maxPrice, category, subCategory, level, sortBy, availability,});
  }, [availability]);

  useUpdateEffect(() => {
    searchFilter({ minPrice, maxPrice, category, subCategory, level, sortBy, availability,});
  }, [level]);


  const handleClear = () => {
    setMaxPrice("");
    setMinPrice("");
    setCategory("");
    setSubCategory("");
    setSortBy("");
    setAvailability("-1");
    setLevel("");
  };

  return (
    <SideBarMenu
      style={{
        top: "90px",
        zIndex: "99",
      }}
    >
      <Row className="g-0">
        <Col xs={9}>
          <SortDropdown
            isMarketPlace={isMarketPlace}
            filters={{ sortBy, setSortBy }}
          />
        </Col>
        <Col xs={3}>
          <Button onClick={setShowSidebar} style={{ fontSize: 20 }}>
            <span style={{ marginRight: 5 }}>Filters</span>
            <BsFilter />
          </Button>
        </Col>
      </Row>
      <Menu
        style={
          showSidebar
            ? {
                left: "0",
                top: "80px",
              }
            : {
                left: "-100%",
                top: "80px",
              }
        }
      >
        <div className="menuContent">
          <Filters
            filters={{
              minPrice,
              setMinPrice,
              maxPrice,
              setMaxPrice,
              category,
              setCategory,
              subCategory,
              setSubCategory,
              level,
              setLevel,
              availability,
              setAvailability,
            }}
            isMarketPlace={isMarketPlace}
          />
          <SaveBtn>
            <Clear onClick={handleClear}>Clear</Clear>
            <Done onClick={setShowSidebar}>Done</Done>
          </SaveBtn>
        </div>
      </Menu>
    </SideBarMenu>
  );
};
