import React from 'react'
import ForSaleFixedDetailCard from "../ForSaleFixedDetailCard";
import SoldDetailCard from "../SoldDetailCard";
import AuctionDetailCard from "../AuctionDetailCard";
import { ENFTType } from "hooks";

export default function index({
  nftDetails,
  loading,
  updateNFTPrice,
  updateNFTListingStatus,
  buyNowNFT,
  submitClaimAuction,
  auctionStart,
  cancelNFTBid,
  setShowPlaceBid,
  setShowFixedPrice,
  setShowAuction,
  isOwnsProduct,
}) {
  return (
    <>
      {nftDetails?.nft?.nftType === ENFTType.FIXED_PRICE &&
      nftDetails?.nft?.listed ? (
        <ForSaleFixedDetailCard
          isOwnsProduct={isOwnsProduct}
          nftDetails={nftDetails}
          loading={loading}
          updateNFTPrice={updateNFTPrice}
          updateNFTListingStatus={updateNFTListingStatus}
          buyNowNFT={buyNowNFT}
        />
      ) : (nftDetails?.nft?.nftType === ENFTType.AUCTION ||
          nftDetails?.nft?.nftType === ENFTType.UNLIMITED_AUCTION) &&
        nftDetails?.nft?.listed ? (
        <AuctionDetailCard
          isOwnsProduct={isOwnsProduct}
          nftDetails={nftDetails}
          loading={loading}
          updateNFTListingStatus={updateNFTListingStatus}
          submitClaimAuction={submitClaimAuction}
          auctionStart={auctionStart}
          cancelNFTBid={cancelNFTBid}
          setShowPlaceBid={setShowPlaceBid}
          setShowFixedPrice={setShowFixedPrice}
        />
      ) : nftDetails?.nft?.listed === false ? (
        <SoldDetailCard
          isOwnsProduct={isOwnsProduct}
          nftDetails={nftDetails}
          loading={loading}
          setShowAuction={setShowAuction}
          setShowFixedPrice={setShowFixedPrice}
        />
      ) : null}
    </>
  );
}
