import { useState } from "react";
import { ENFTType } from "hooks";
import { useWeb3React } from "@web3-react/core";
import moment from "moment";
import Auction from "components/Auction"
import FlipCountdown from "@rumess/react-flip-countdown";
import {
  NftName,
} from "pages/creatorhub/subpages/detail/index.styled";
import { CountdownWrapper } from "./styled";

export default function Index({
  nftDetails,
  loading,
  updateNFTListingStatus,
  submitClaimAuction,
  auctionStart,
  cancelNFTBid,
  setShowPlaceBid,
  setShowFixedPrice,
  isOwnsProduct,
}) {
  const { account } = useWeb3React();
  const isCreatorHub = window.location.pathname.includes("creator");
  const [showAuction, setShowAuction] = useState(false);

  const isAlreadyBid = () => {
    for (let i = 0; i < nftDetails?.bids?.length; i++) {
      if (account?.toLowerCase() === nftDetails.bids[i].bidder.toLowerCase())
        return true;
    }
    return false;
  };

  const isAuctionEnded = () => {
    const curretTimestamp = +new Date();
    return curretTimestamp / 1000 > Number(nftDetails?.nft.endTime);
  };

  return (
    <NftName className={isCreatorHub ? "" : "gamehub"}>
      <h1>{nftDetails?.nft?.name}</h1>
      <p>{nftDetails?.nft?.description}</p>

      {nftDetails?.nft?.endTime && moment(nftDetails?.nft?.endTime, "X").isAfter(moment()) && (
        <CountdownWrapper>
          <FlipCountdown
            hideYear
            hideMonth
            hideSecond
            size="small"
            theme="light"
            dayTitle="Days"
            hourTitle="Hours"
            minuteTitle="Minutes"
            endAt={moment(nftDetails?.nft?.endTime, "X").format()}
          />
        </CountdownWrapper>
      )}

      {showAuction && (
        <Auction
          onClose={() => setShowAuction(false)}
          onAuctionStart={(minBidPrice, startTime, endTime) => {
            setShowAuction(false);
            auctionStart(minBidPrice, startTime, endTime);
          }}
        />
      )}
      {isOwnsProduct && (
        <div className="flex-box actions">
          <button
            className="buyBtn"
            style={{ marginLeft: "0.7em" }}
            onClick={updateNFTListingStatus}
            disabled={loading}
          >
            Disable for sale
          </button>
          {isCreatorHub ? (
            ""
          ) : (
            <button
              className="buyBtn"
              style={{ marginLeft: "0.7em" }}
              onClick={() => setShowFixedPrice(true)}
              disabled={loading}
            >
              Sell for fixed price
            </button>
          )}

          {nftDetails?.bids?.length > 0 ? (
            nftDetails?.nft?.nftType === ENFTType.UNLIMITED_AUCTION ? (
              <button
                className="buyBtn"
                style={{ marginLeft: "0.7em" }}
                onClick={submitClaimAuction}
                disabled={loading}
              >
                Claim Auction
              </button>
            ) : nftDetails?.nft?.nftType === ENFTType.AUCTION &&
              moment(parseInt(nftDetails?.nft?.endTime) * 1000).isBefore(
                new Date().getTime()
              ) ? (
              <button
                className="buyBtn"
                style={{ marginLeft: "0.7em" }}
                onClick={submitClaimAuction}
                disabled={loading}
              >
                Claim Auction
              </button>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      )}

      {!isOwnsProduct && account && (
        <div className="flex-box actions mb-3">
          {nftDetails?.nft?.nftType !== ENFTType.FIXED_PRICE &&
            !isAlreadyBid() &&
            !isAuctionEnded() && (
              <button
                className="buyBtn"
                style={{ marginLeft: "0.7em" }}
                onClick={() => setShowPlaceBid(true)}
                disabled={loading}
              >
                Place Bid
              </button>
            )}
          {isAlreadyBid() && (
            <button
              className="buyBtn"
              style={{ marginLeft: "0.7em" }}
              onClick={cancelNFTBid}
              disabled={loading}
            >
              Cancel Bid
            </button>
          )}

          {nftDetails?.nft?.nftType === ENFTType.AUCTION &&
            nftDetails?.bids[
              nftDetails?.bids.length - 1
            ]?.bidder?.toLowerCase() === account?.toLowerCase() &&
            moment(parseInt(nftDetails?.nft?.endTime) * 1000).isBefore(
              new Date().getTime()
            ) && (
              <button
                className="buyBtn"
                style={{ marginLeft: "0.7em" }}
                onClick={submitClaimAuction}
                disabled={loading}
              >
                Claim Auction
              </button>
            )}
        </div>
      )}
      <>
        <div className="purchase">
          <h3>
            {" "}
            {nftDetails?.nft?.bids?.length > 0
              ? "Current Bid : "
              : "Minimum Bid : "}{" "}
          </h3>
          <button className="dexiBtn">
            {`${
              nftDetails?.nft?.bids?.length > 0
                ? parseInt(
                    nftDetails?.nft?.bids[
                      nftDetails?.nft?.bids.length - 1
                    ].price.toString()
                  )
                : parseInt(nftDetails?.nft?.minBidPrice)
            } DEXI`}
          </button>
        </div>
      </>
    </NftName>
  );
}
