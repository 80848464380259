import styled from "styled-components";
import { Container as C } from "react-bootstrap";

export const Container = styled(C)`
  padding: 4.5em 2em;
  min-height: 87vh;

  .loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 4.6em;
  }

  @media (max-width: 768px) {
    & > .row {
      flex-direction: column;
      overflow: auto;
    }
    & > .row > div:first-child {
      width: 100% !important;
      padding: 0;
    }
    & > .row > div:nth-child(2) {
      flex: 0 0 auto;
      width: 100% !important;
      padding: 0;
    }
  }

  .product-container {
    background: #ffffff50;
    padding: 1.5em;
    padding-top: 0;
  }
  .product-container > div {
    width: 24.2%;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;

  .dropdown {
    display: none;
  }
  button {
    width: 100%;
  }

  &.typeButtonsContainer {
    margin-bottom: 1em;

    button {
      margin-right: 5px;
      margin-top: 0;
      width: auto;
    }
  }

  @media (max-width: 768px) {
    .dropdown {
      display: block;
    }

    .desktopButtons {
      display: none;
    }

    &.typeButtonsContainer {
      flex-direction: column !important;

      button {
        margin-right: 0;
        margin-top: 10px;
        width: 100%;
      }
    }
  }

  &.gamehubButtons {
    button {
      background: rgb(47, 0, 54);
    }
  }
`;

export const Button = styled.button`
  margin-top: 12px;
  background: #00e1cd;
  color: white;
  font-weight: 600;
  font-size: 23px;
  border: none;
  outline: none;
  height: 52px;

  &.active {
    color: #003847;
    background: white !important;
    font-weight: bold;
  }
  position: relative;
  span {
    position: absolute;
    right: 10px;
    top: 16px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ProfileCard = styled.div`
  background: white;
  padding: 1em 0;
  padding-bottom: 0;
  h3 {
    text-align: center;
    color: #002d3c;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  img:nth-child(2) {
    height: 233.7px;
    width: 100%;
  }

  div:nth-child(3) {
    display: flex;

    div {
      position: relative;

      width: 60%;
      background: #00e1cd;
      display: flex;
      align-items: center;
      height: 58px;
      padding: 1em 2em;

      img {
        width: 63px;
        height: 58px;
        position: absolute;
        right: -27px;
      }
      p {
        font-size: 20px;
        margin-bottom: 0;
        font-weight: bold;
        color: white;
      }
    }

    p {
      color: #003847;
      text-align: center;
      flex: 1;
      font-weight: 300;
      line-height: 10px;
      font-size: 20px;
      margin-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  div:nth-child(4) {
    padding: 1em 1.5em;
    p:first-child {
      font-size: 18px;
    }
    p:nth-child(2) {
      font-size: 12px;
    }
    p:nth-child(3) {
      font-size: 19px;
    }

    p {
      color: #003847;
      font-weight: 300;
    }
  }
`;
