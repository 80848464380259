import { useState } from "react";
import styled from "styled-components";
import { Modal, Spinner } from "react-bootstrap";

const NOMedia = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: calc(100% - 0px);
  height: calc(100% - 0px);
  border-radius: 11px;
  object-fit: cover;
  border-radius: 30px;
  background: ${({ theme }) => theme.background[2]};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 0.7em 0.7em 0 0;
  color: ${({ theme }) => theme.color[2]};
`;

const Media = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 11px;
  object-fit: cover;
  border-radius: 30px;
  width: 100%;
  border-radius: 0.7em 0.7em 0 0;
`;

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: -webkit-fill-available;
  border-radius: 11px;
  object-fit: cover;
  border-radius: 30px;
  width: 100%;
  border-radius: 0.7em 0.7em 0 0;
`;

const Audio = styled.audio`
  position: absolute;
  bottom: 20px;
  left: 0;
  width: calc(100% - 10px);
  margin: 0 5px;
  object-fit: cover;
  border-radius: 30px;
  z-index: 1;
  box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px,
    rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
  width: 100%;
  border-radius: 0.7em 0.7em 0 0;
`;

const CardMediaPreview = styled.div`
  height: 0;
  overflow: hidden;
  padding-top: 65%;
  position: relative;
  cursor: pointer;
  width: 100%;
  border-radius: 0.7em 0.7em 0 0;
  height: 200px;
  .audio-wrapper {
    img {
      position: absolute;
    }
    &:after {
      content: "";
      position: absolute;
      bottom: 0px;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 30px;
      background: #ffffff87;
      z-index: 0;
    }
  }
`;

export const Card = styled(Modal)`
  .media {
    height: 600px;
    width: 600px;
    div {
      height: 100%;
    }
  }
`;
interface PropsType {
  className?: string;
  assetType: string;
  assetUrl: string;
  bannerImage?: string;
  controls?: boolean;
  autoPlay?: boolean;
  muted?: boolean;
  loop?: boolean;
  showFullScreen?: boolean;
  dontShowSpinner?: boolean;
  onClick?: () => void;
}

const CardsMedia = ({
  className = "",
  assetType,
  assetUrl,
  bannerImage,
  controls = false,
  autoPlay = false,
  muted = false,
  loop = false,
  onClick,
  showFullScreen,
  dontShowSpinner,
}: PropsType) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <div
        onClick={(e) => {
          if (showFullScreen) e.stopPropagation();
        }}
      >
        <Card
          className={`artist-card ${className} `}
          show={show}
          onHide={() => setShow(false)}
        >
          <div onClick={(e) => e.stopPropagation()}>
            <div className="media">
              <CardMediaPreview
                className={className}
                onClick={() => onClick && onClick()}
              >
                {/* {assetType === '' && assetUrl === '' && <NOMedia>No preview</NOMedia>} */}

                {assetType === "Image" ? (
                    <Media src={`${assetUrl}?w=401&h=313`} />
                ) : assetType === "Video" ? (
                  <Video
                    controls={controls}
                    autoPlay={autoPlay}
                    muted={muted}
                    loop={loop}
                  >
                    <source src={assetUrl} type="video/mp4" />
                  </Video>
                ) : assetType == "Audio" ? (
                  <div className="audio-wrapper">
                    {bannerImage && <Media src={`${bannerImage}`} />}
                    <Audio
                      src={assetUrl}
                      controls={controls}
                      autoPlay={autoPlay}
                      muted={muted}
                      loop={loop}
                    />
                  </div>
                ) : (
                  <NOMedia>
                    {" "}
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      className="me-3"
                    />
                  </NOMedia>
                )}
              </CardMediaPreview>
            </div>
          </div>
        </Card>
        <CardMediaPreview
          className={className}
          onClick={(e) => {
            if (showFullScreen) {
              e.stopPropagation();
              setShow(true);
            }
          }}
        >
          {assetType === "Image" ? (
            <>
              <NOMedia>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-3"
                />
              </NOMedia>
              <Media src={`${assetUrl}`} />
            </>
          ) : assetType === "Video" ? (
            <Video
              controls={controls}
              autoPlay={autoPlay}
              muted={muted}
              loop={loop}
            >
              <source src={assetUrl} type="video/mp4" />
            </Video>
          ) : assetType == "Audio" ? (
            <div className="audio-wrapper">
              {bannerImage && <Media src={`${bannerImage}`} />}
              <Audio
                src={assetUrl}
                controls={controls}
                autoPlay={autoPlay}
                muted={muted}
                loop={loop}
              />
            </div>
          ) : (
            <NOMedia>
              {dontShowSpinner ? (
                "No preview"
              ) : assetUrl ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-3"
                />
              ) : (
                ""
              )}
            </NOMedia>
          )}
        </CardMediaPreview>
      </div>
    </>
  );
};
export default CardsMedia;
