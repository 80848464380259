import { useCallback, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import {
  injectedConnector,
  networkConnector,
  walletConnector,
} from "../utils/connectors";

let connector = null;
connector = networkConnector;

const useAuth = () => {
  const { activate, deactivate, active } = useWeb3React();

  const login = useCallback(
    async (walletId = 0) => {
      if (walletId === 1) {
        connector = injectedConnector;
        localStorage.removeItem("walletconnect");
      } else if (walletId === 2) {
        connector = walletConnector;
      }
      await activate(connector);
      localStorage.setItem("walletId", walletId);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const logout = useCallback(() => {
    deactivate();
    localStorage.removeItem("walletconnect");
    localStorage.removeItem("walletId");
  }, [deactivate]);

  useEffect(() => {
    injectedConnector.isAuthorized().then(async isAuthorized => {
      try {
        if (isAuthorized) login(1);
      } catch (e) {
        // e
      }
    });

    if (localStorage.getItem("walletconnect")) {
      login(2);
    }
  }, [active, login]);

  return { login, logout };
};

export default useAuth;
