import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import toast from "react-hot-toast";
import { Modal, Row } from "react-bootstrap";
import { request, gql } from "graphql-request";

import { H5, P } from "components/typography";
import BlueButton from "components/button/blueButton";
import BlueButtonOutlined from "components/button/blueButtonOutlined";
import API from "utils/api";
import { NFTObjectData } from "hooks";
import { useProfile } from "store/hooks";
import { config } from "../../../../../environment";

import { Actions } from "./index.styled";
import APIFactory from "utils/api";

type TAttributesMigration = {
  tokenID: string;
  currentAttributes: any;
};

type TAttributes = {
  weaponLevel: number;
  weaponDamage: number;
  EnemiesKilled: number;
};

const GAME_ID = "6238849ffffcdebf2f62e1f6";

export const AttributesMigration = ({
  tokenID,
  currentAttributes,
}: TAttributesMigration) => {
  const { getAccessTokenSilently } = useAuth0();
  const { account } = useWeb3React();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [attributes, setAttributes] = useState<TAttributes>();
  const { profile } = useProfile();

  const handleClose = () => {
    setShow(false);
  };

  const getOwnedNft = useCallback(async () => {
    const response = await request(
      config.REACT_APP_SUBGRAPH_URL,
      gql`
        query getMigrations($account: Bytes!, $newId: BigInt!) {
          exampleEntities(where: { from: $account, newId: $newId }) {
            id
            count
            newId
            oldId
            from
          }
        }
      `,
      {
        account: account.toLowerCase(),
        newId: tokenID,
      }
    );

    const oldId = response.exampleEntities[0]?.oldId;

    if (oldId) {
      // fetch data from the old DB
      const { data } = await axios.get(
        `https://api.dexigas.com/api/getNFTUserFullDetail/${account.toLowerCase()}`
      );

      data?.data?.userNfts?.currentNfts?.forEach(
        (nft: Omit<NFTObjectData, "bids">) => {
          if (Number(nft.tokenID) === Number(oldId)) {
            setAttributes(nft.attributes);
          }
        }
      );

      setShow(Boolean(oldId));
    }
  }, [account, tokenID]);

  const handleMigrateClick = async () => {
    setLoading(true);

    try {
      // mutate attributes in the new DB
      const token = await getAccessTokenSilently();

      const nftAttributes = currentAttributes[0] || currentAttributes;

      await APIFactory(config.REACT_APP_API_URL).post(
        "/v2/inventory/stash ",
        {
          userId: profile._id,
          type: "nft",
          game: GAME_ID,
          item: {
            itemId: tokenID,
            attributes: [
              {
                ...nftAttributes,
                weaponDamage: attributes.weaponDamage,
                itemLevel: attributes.weaponLevel,
                EnemiesKilled: attributes.EnemiesKilled,
              },
            ],
          },
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      toast.success("Attributes migrated successfully!");
      setShow(false);
    } catch (e) {
      toast.error("Error occurred, please try again later");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (account) getOwnedNft();
  }, [account, getOwnedNft]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      centered
      size="xl"
      backdrop="static"
    >
      <Modal.Body style={{ backgroundColor: "#14141f" }}>
        <Modal.Title className="mb-4">Attributes migration</Modal.Title>
        <P>You can migrate attributes of this NFT</P>
        {attributes && (
          <Row className="mt-3">
            <H5>Attributes:</H5>
            {attributes.weaponLevel ? (
              <P>Weapon level: {attributes.weaponLevel}</P>
            ) : null}
            {attributes.weaponDamage ? (
              <P>Weapon damage: {attributes.weaponDamage}</P>
            ) : null}
            {attributes.EnemiesKilled ? (
              <P>Enemies killed: {attributes.EnemiesKilled}</P>
            ) : null}
          </Row>
        )}
        <Actions>
          <BlueButton onClick={handleMigrateClick} disabled={loading}>
            Migrate
          </BlueButton>
          <BlueButtonOutlined onClick={handleClose} disabled={loading}>
            Next time
          </BlueButtonOutlined>
        </Actions>
      </Modal.Body>
    </Modal>
  );
};
