import { Modal as M } from "react-bootstrap";
import styled from "styled-components";

export const Modal = styled(M)`
  select {
    position: relative;
    background: rgba(255, 255, 255, 0.1);
    border: ${({ theme }) => "1px solid transparent"};
    box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px,
      rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
    border-radius: 5px !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.7;
    box-sizing: border-box;
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    outline: none;
    padding: 1rem 1rem;

    &:not(.disabled):hover {
      box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
        rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
    }

    &:not(.disabled):focus-within {
      box-shadow: rgb(0 0 0 / 20%) 0px 2px 4px -1px,
        rgb(0 0 0 / 14%) 0px 4px 5px 0px, rgb(0 0 0 / 12%) 0px 1px 10px 0px;
    }

    &:not(.disabled):active {
      box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px,
        rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
    }
  }
`;

export const Form = styled.form`
  background-color: #14141f;
`;
