import { Col, Row } from "react-bootstrap";
import { FootterContent, SocialLinks } from "./style";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();
  return (
    <FootterContent
      style={
        location.pathname === "/"
          ? { backgroundColor: "#002d3c" }
          : !location.pathname.includes("creator")
          ? { backgroundColor: "#2F0036" }
          : { backgroundColor: "#005672" }
      }
    >
      <Row className="align-items-center mx-0 w-100">
        <Col md={3}></Col>
        <Col md={6}>
          <p>© 2022 Copyright of Dexioprotocol</p>
        </Col>
        <Col md={3}>
          <SocialLinks>
            <a href="http://dexioprotocol.com/" target="__blank">
              <img src="/images/web.svg" alt="" />
            </a>
            <a href="https://t.me/dexiochat" target="__blank">
              <img src="/images/telegram.svg" alt="" />
            </a>
            <a
              href="https://twitter.com/dexioprotocol?t=FA6EchA7_tzCmhstazQcfg&s=09"
              target="__blank"
            >
              <img src="/images/twitter.svg" alt="" />
            </a>
            <a href="https://discord.gg/GtjhZDPxAQ" target="__blank">
              <img src="/images/discord.svg" alt="" />
            </a>
          </SocialLinks>
        </Col>
      </Row>
    </FootterContent>
  );
};

export default Footer;
