import { Col, Row, Container, Tab, Tabs, Image } from "react-bootstrap";
import { DatetimeWrapper, Section } from "./index.styled";
import { H1, H3 } from "marketplace/components/typography";
import Button from "marketplace/components/button";
import ProductCard from "marketplace/components/card/product";
import Input from "components/form/input";
import TextArea from "components/form/textArea";
import { useForm, useWatch } from "react-hook-form";
import FileInput, { FilePT } from "components/form/file";
import { useEffect, useState } from "react";
import filters from "./filters.json";
import { Filter, FilterOption } from "components/filter";
import { useWeb3React } from "@web3-react/core";
import { useProfile } from "store/hooks";
import toast from "react-hot-toast";
import { getIpfsHash, readFileAsync } from "utils/ipfs";
import { createAuctionAfterMinting, mintToken, sellAfterMint } from "marketplace/utils/contracts";
import { useHistory } from "react-router";
import { NFTObjectData, useGetCollections } from "marketplace/hooks/useApi";
import moment from "moment";
import Layout from "components/layout";
import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
// import Select from "components/form/select";

function isFileValid(file) {
  return (
    file &&
    (file["type"].split("/")[0] === "image" ||
      file["type"].split("/")[0] === "video")
  );
}

const ProductCreateScreen = () => {
  const {
    register,
    unregister,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();
  const [mintedNftId, setMintedNftId] = useState()
  const [nftType, setNFTType] = useState<FilterOption>();
  const [nftAsset, setNFTAsset] = useState(null);
  const [nftAssetType, setNFTAssetType] = useState("");
  const [nftAssetBannerImage, setNFTAssetBannerImage] = useState(null);
  const [startTime, setNFTAuctionStartTime] = useState(new Date());
  const [endTime, setNFTAuctionEndTime] = useState(new Date());
  const [selectedNFTOption, setSelectedNFTOption] = useState("fixed_price");
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);
  const [categories, setCategories] = useState([]);
  const { library, chainId, account } = useWeb3React();
  const { profile } = useProfile();
  // const collections = useGetCollections(account);
  const [product, setProduct] = useState<NFTObjectData>();
  const isEndTimeValid = moment(startTime).add(24, 'hours').isSameOrBefore(endTime);

  const updateProduct = (items) => {
    setProduct((prevState) => ({ ...prevState, ...items }));
  };

  useEffect(() => {
    updateProduct({ initialCreatorAddress: profile?.walletAddress });
  }, [profile]);

  useEffect(() => {
    const values = filters.filter((filter) =>
      filter.forSection.includes("create")
    );
    setCategories(values);
    if (values.length > 0) {
      setNFTType(values[0]);
    }
  }, []);

  const onChangeFile = (e: any, fileObject: FilePT) => {
    const file = e.target.files[0];
    if (!isFileValid(file)) {
      toast.error("Please Select a valid image or video.");
      return;
    }

    updateProduct(fileObject);
    setNFTAsset(file);
    setNFTAssetType(fileObject.assetType);
  };

  const onChangeAudioBannerFile = (e: any, fileObject: FilePT) => {
    const file = e.target.files[0];
    updateProduct({ bannerImage: fileObject.assetUrl });
    setNFTAssetBannerImage(file);
  };

  const name = useWatch({ control, name: "name" });
  useEffect(() => {
    updateProduct({ name });
  }, [name]);

  const onSubmit = async (data) => {
    if (selectedNFTOption === "timed_auction" && !isEndTimeValid) return;

    if (!account || !library) {
      toast.error("Please connect your wallet!");
      return;
    }

    if (!profile) {
      toast.error("Please login correctly!");
      return;
    }
  
    if (!data.description) {
      toast.error("Please enter a description");
      return;
    }

    if (data.royalty > 20) {
      toast.error("Maximum royalty is 20");
      return;
    }

    if (data.royalty < 0) {
      toast.error("Minimum royalty is 0");
      return;
    }

    if (!profile?.displayName) {
      toast.error(
        "Please add your name and email in the profile before buying!"
      );
      return;
    }

    if (!nftAsset) {
      toast.error("Please select assets !");
      return;
    }

    if (selectedNFTOption === "fixed_price") {
      if (data.price <= 0) {
        return;
      }
    } else if (selectedNFTOption === "timed_auction") {
      if (data.minBidPrice <= 0) {
        return;
      }
      if (moment(startTime).valueOf() >= moment(endTime).valueOf()) {
        toast.error("End time should be late than start time!");
        return;
      }
    }

    const mintingToast = toast.loading("Minting...");
    setIsSaving(true);
    const IPFS_URL = `https://ipfs.io/ipfs`;
    let tempTokenId = null;

    try {
      if (!mintedNftId) {
        let metaData: any = {};
        let buffer = await readFileAsync(nftAsset);
        let hash = await getIpfsHash(buffer);
        const assetUrl = `${IPFS_URL}/${hash}`;
        let bannerImage = "";

        if (nftAssetType === "Audio" && nftAssetBannerImage) {
          buffer = await readFileAsync(nftAssetBannerImage);
          hash = await getIpfsHash(buffer);
          bannerImage = `${IPFS_URL}/${hash}`;
        }
        if (nftAssetType === "Image") {
          metaData = {
            name: data.name,
            description: data.description,
            assetType: nftAssetType,
            image: assetUrl,
            attributes: [],
            category: [nftType?.value, "sensitive"],
            // collection: data.collection ? data.collection : "",
          };
        } else if (nftAssetType === "Video") {
          metaData = {
            name: data.name,
            description: data.description,
            assetType: nftAssetType,
            image: "",
            animation_url: assetUrl,
            attributes: [],
            category: [nftType?.value, "sensitive"],
            // collection: data.collection ? data.collection : "",
          };
        } else if (nftAssetType === "Audio") {
          metaData = {
            name: data.name,
            description: data.description,
            assetType: nftAssetType,
            image: bannerImage,
            animation_url: assetUrl,
            attributes: [],
            category: [nftType?.value, "sensitive"],
            // collection: data.collection ? data.collection : "",
          };
        }
        const metaDataHash = await getIpfsHash(
          Buffer.from(JSON.stringify(metaData), "utf-8")
        );
        const tokenURI = `${IPFS_URL}/${metaDataHash}`;

        const nftId = await mintToken(
          chainId,
          library.getSigner(),
          tokenURI,
          data.royalty,
          account
        );
        setMintedNftId(nftId);
        tempTokenId = nftId;
        toast.dismiss(mintingToast);
        toast.success("NFT minted successfully!");
      }

      let creatingToast;
      if (selectedNFTOption === "fixed_price") {
        creatingToast = toast.loading("Putting on sale...");
        await sellAfterMint(
          chainId,
          library.getSigner(),
          data.price,
          tempTokenId || mintedNftId
        );
      } else if (selectedNFTOption === "timed_auction") {
        creatingToast = toast.loading("Creating an auction..");
        await createAuctionAfterMinting(
          chainId,
          library.getSigner(),
          data.minBidPrice,
          startTime,
          endTime,
          tempTokenId || mintedNftId,
        );
      } else if (selectedNFTOption === "unlimited_auction") {
        creatingToast = toast.loading("Creating an auction..");
        await createAuctionAfterMinting(
          chainId,
          library.getSigner(),
          data.minBidPrice,
          null,
          null,
          tempTokenId || mintedNftId,
        );
      }

      setIsSaving(false);
      toast.dismiss(creatingToast);

      toast.success("NFT created successfully!");

      setTimeout(async () => {
        history.push("/creator");
      }, 2000);
    } catch (error) {
      toast.dismiss(mintingToast);

      toast.error("Error was occured!");
    } finally {
      setIsSaving(false);
    }
  };

  function registerUnregisterMarketOptionFields(eventKey) {
    setSelectedNFTOption(eventKey);

    if (eventKey === "fixed_price") {
      register("price");
      unregister("minBidPrice");
    } else if (eventKey === "timed_auction") {
      register("minBidPrice");
      unregister("price");
    } else {
      unregister("price");
      unregister("minBidPrice");
    }
  }

  return (
    <Layout>
      <Section id="product-details">
        <Container>
          <Row className="filter-container " style={{ position: "relative" }}>
            <Col xs={12} md={7}>
              <H1 className="mb-0">Mint</H1>
              <form onSubmit={handleSubmit(onSubmit)} className="mt-3">
                <Filter
                  className="mb-0 mt-5 mb-md-2"
                  filterOptions={categories}
                  onSelect={setNFTType}
                />
                <FileInput
                  className="mb-5"
                  info="Select an image, audio or video file (up to 50MB)"
                  label="Upload file"
                  dispalyImage
                  onChange={onChangeFile}
                />
                {product?.assetType === "Audio" && (
                  <FileInput
                    className="mb-5"
                    info="JPG, GIF, BMP, PNG"
                    label="Banner image for audio"
                    accept="Image"
                    dispalyImage
                    onChange={onChangeAudioBannerFile}
                  />
                )}

                <Tabs
                  defaultActiveKey="fixed_price"
                  onSelect={(eventKey) =>
                    registerUnregisterMarketOptionFields(eventKey)
                  }
                  className="mb-3"
                >
                  <Tab
                    eventKey="fixed_price"
                    title={
                      <div className="tab-btn">
                        <Image src="/images/tag.svg" /> Fixed Price
                      </div>
                    }
                  />
                  <Tab
                    eventKey="timed_auction"
                    title={
                      <div className="tab-btn">
                        <Image src="/images/time.svg" /> Timed Auction
                      </div>
                    }
                  />
                  <Tab
                    eventKey="unlimited_auction"
                    title={
                      <div className="tab-btn">
                        <Image src="/images/infinite.svg" /> Unlimited Auction
                      </div>
                    }
                  />
                </Tabs>

                

                <Input
                  label="Name"
                  register={{
                    ...register("name", {
                      required: { message: "Name is required ", value: true },
                    }),
                  }}
                  errors={errors}
                />
                <TextArea
                  label="Description"
                  resize={false}
                  register={{
                    ...register("description", {
                      required: {
                        message: "Description is required ",
                        value: true,
                      },
                    }),
                  }}
                  errors={errors}
                />
                {/* <Select
                  label="Collection"
                  defaultValue={""}
                  register={register("collection")}
                >
                  {[{ _id: "", name: "Empty" }, ...collections]?.map((collection) => (
                    <option key={collection._id} value={collection._id}>
                      {collection.name}
                    </option>
                  ))}
                </Select> */}

                {selectedNFTOption === "fixed_price" && (
                  <>
                    <Input
                      label="Price (in DEXI)"
                      type="number"
                      step="any"
                      register={{
                        ...register("price", {
                          required: {
                            message: "Price is required ",
                            value: true,
                          },
                          min: {
                            value: 0,
                            message: "Min Price is 0",
                          },
                          validate: (price: number) =>
                            price > 0 || "NFT Price is not valid !",
                        }),
                      }}
                      errors={errors}
                    />
                  </>
                )}

                {selectedNFTOption === "timed_auction" && (
                  <>
                    <Input
                      label="Minimum bid "
                      type="number"
                      step="any"
                      register={{
                        ...register("minBidPrice", {
                          required: {
                            message: "minBidPrice is required ",
                            value: true,
                          },
                          validate: (minBidPrice: number) =>
                            minBidPrice > 0 ||
                            "NFT Minimum Bid Price is not valid !",
                        }),
                      }}
                      errors={errors}
                      helpText="Bids below this amount won’t be allowed."
                    />
                    <Row>
                      <Col xs={12} md={6} className="mb-5">
                        <label className="mb-2">Starting Date</label>
                        <Datetime
                          value={startTime}
                          onChange={(dateTime) => {
                            if (typeof dateTime !== "string") {
                              setNFTAuctionStartTime(dateTime.toDate());
                            }
                          }}
                        />
                      </Col>
                      <Col xs={12} md={6} className="mb-5">
                        <label className="mb-2">End Date</label>
                        <DatetimeWrapper>
                          <Datetime
                            value={endTime}
                            onChange={(dateTime) => {
                              if (typeof dateTime !== "string") {
                                setNFTAuctionEndTime(dateTime.toDate());
                              }
                            }}
                          />
                          {!isEndTimeValid && (
                            <p>
                              Must be at least 24 hrs ahead of the start time
                            </p>
                          )}
                        </DatetimeWrapper>
                      </Col>
                    </Row>
                  </>
                )}

                {selectedNFTOption === "unlimited_auction" && (
                  <Input
                    label="Minimum bid"
                    type="number"
                    step="any"
                    register={{
                      ...register("minBidPrice", {
                        required: {
                          message: "minBidPrice is required ",
                          value: true,
                        },
                        validate: (minBidPrice: number) =>
                          minBidPrice > 0 ||
                          "NFT Minimum Bid Price is not valid !",
                      }),
                    }}
                    errors={errors}
                    helpText="Bids below this amount won’t be allowed."
                  />
                )}

                <Input
                  label="Royalty (%)"
                  type="number"
                  register={{
                    ...register("royalty", {
                      required: {
                        message: "Royalty is required ",
                        value: true,
                      },
                      max: {
                        value: 20,
                        message: "Max Royalty can be 20", // JS only: <p>error message</p> TS only support string
                      },
                      min: {
                        value: 0,
                        message: "Min Royalty is 0", // JS only: <p>error message</p> TS only support string
                      },
                    }),
                  }}
                  errors={errors}
                />
                <p className="text-warning">
                  ❗️Take a moment to review the name, description, file
                  uploaded, and price set. Once minted, these cannot be edited.
                </p>
                {/* <div className="mb-5">
                  <Form.Check
                    type={"checkbox"}
                    id={`default-check`}
                    label={`I Understand`}
                    className="mb-4"
                  />
                </div> */}
                <Button disabled={isSaving}>Create</Button>
              </form>
            </Col>
            <Col xs={12} md={{ offset: 1, span: 4 }}>
              {product?.assetUrl ? (
                <>
                  <H3 className="mt-5">Preview</H3>
                  <ProductCard
                    disabled
                    productObj={product}
                    dontShowSold={true}
                  />
                </>
              ) : null}
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default ProductCreateScreen;
