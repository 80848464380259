import styled from "styled-components";

interface PPT {
  varient?: "primary" | "secondary";
}
export const P = styled.p<PPT>`
  color: white;
  letter-spacing: 0.02em;
  margin: 0;
`;

export const H1 = styled.h1`
  color: white;
  font-size: 60px;
  font-weight: bold;
`;

export const H2 = styled.h2`
  color: white;
  font-size: 40px;
  font-weight: 700;
`;

export const H3 = styled.h3`
  font-weight: 600;
  font-size: 1.4em;
  color: white;
`;

export const H6 = styled.h6`
  color: white;
  font-size: 18px;
  font-weight: 500;
`;
