import styled from 'styled-components';

export const Section = styled('div')`
  padding: 75px 0px 260px;

  .container {
    position: relative;
  }

`;
