import axios from "axios";

const {
  REACT_APP_INFURA_URL: INFURA_URL,
  REACT_APP_CDN_URL: CDN_URL,
  REACT_APP_S3_TRIGGER_URL: TRIGGER_URL,
  REACT_APP_S3_TRIGGER_PATH: TRIGGER_PATH,
} = process.env;

export const returnCDNImage = (assetType, baseImageUrl) => {
  if (baseImageUrl === "" || !baseImageUrl) return baseImageUrl;

  baseImageUrl = baseImageUrl.replace(/(:\/\/)|(\/)+/g, "$1$2");

  if (baseImageUrl.includes("https://ipfs.io/ipfs/")) {
    baseImageUrl = baseImageUrl.replace(
      "https://ipfs.io/",
      CDN_URL
    );
  }

  if (baseImageUrl.includes(INFURA_URL)) {
    baseImageUrl = baseImageUrl.replace(
      INFURA_URL, CDN_URL
    );
  }

  switch (assetType) {
    case "Image":
      return `${baseImageUrl.replace("/ipfs/", "/images/")}.webp`;
    case "Video":
      return `${baseImageUrl.replace("/ipfs/", "/videos/")}.webm`;
    default:
      break;
  }
};
