import styled from 'styled-components';
interface SectionProps {
  bg?: string;
  bgMobile?: string;
}

interface BGSectionProps {
  bg?: string;
  bgMobile?: string;
  children?: any;
  className?: string;
}
const Root = styled.section<SectionProps>`
  padding: 50px 0 50px 0;
  background-image: ${({ bg }) => `url(${bg})`};
  // background-size: cover;
  // background-attachment: fixed;
  // background-position: top;
  // background-repeat: no-repeat;

  @media (max-width: 576px) {
    padding: 0px 0 0px 0;
    background-size: cover;
    background-position: bottom center;
    background-image: ${({ bgMobile, bg }) => `url(${bgMobile ? bgMobile : bg})`};
  }
`;
export const BGSection = ({ bg, bgMobile, children, className }: BGSectionProps) => {
  return (
    <Root className={className} bg={bg} bgMobile={bgMobile}>
      {children}
    </Root>
  );
};

export const Section = styled.section`
  padding: 85px 0 100px;
`;
